import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, forwardRef, useEffect, useState } from "react";
import { Button, Card, Form, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidVideos } from "react-icons/bi";
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { RxCountdownTimer } from "react-icons/rx";
import Loader from "react-js-loader";
import { NavLink } from "react-router-dom";
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, ITEMS_PER_PAGE, PROXY, QC_STATUS_FAIL, QC_STATUS_PASS, SCHEDULER_STATUS_DONE, dayDifference, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, pascalCase } from "../../common/constant";
import PaginationComponent from "../../common/PaginationComponent/PaginationComponent";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./HistoryScreen.css";
const HistoryScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [interviewStatus, setInterviewStatus] = useState("All");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [initialData, setInitialData] = useState([]);
    const [interviewList, setInterviewList] = useState([]);
    const [interviewPageNumber, setInterviewPageNumber] = useState(1);
    const [interviewPageSize, seInterviewPageSize] = useState(ITEMS_PER_PAGE);

    useEffect(() => {
        if (!_.isUndefined(interviewList) && !_.isNull(interviewList) && (interviewList.length === 0)) {
            getinterviewList();
        }
    }, []);

    useEffect(() => {
        if (interviewStatus) {
            doSearch()
        }
    }, [interviewStatus])

    const getinterviewList = () => {
        fetch(`${API_URL}api/interviews/${userDetails.id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    const initialData = response.data.filter((item) => item.scheduler_status == SCHEDULER_STATUS_DONE);
                    setInterviewList(initialData);
                    setInitialData(initialData)
                }
            })
            .catch((err) => {
                setInterviewList([]);
            });
    }

    const handleDownload = (fileUrl) => {
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.download = (new URL(fileUrl).pathname.split('/').pop().replaceAll("%20", " "));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading PDF:', error));
    };

    const doSearch = () => {
        const filtered = initialData.filter(item => {
            // Filter based on search text if provided
            const matchesSearchText = interviewStatus == 'All' || Object.values(item).some(value => {
                return String(value).toLowerCase().includes(interviewStatus.toLowerCase())
            });
            // Filter based on date range if dates are selected
            const uploadedDate = new Date(item.scheduled_on);
            // Adjust the date to the user's local time zone
            const adjustedStartDate = fromDate ? new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000) : null;
            const adjustedEndDate = toDate ? new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000) : null;
            // const isWithinDateRange =
            //     (!adjustedStartDate || uploadedDate >= adjustedStartDate) &&
            //     (!adjustedEndDate || uploadedDate <= adjustedEndDate);
            const isWithinDateRange =
                (!adjustedStartDate || uploadedDate >= getStartDate(adjustedStartDate)) &&
                (!adjustedEndDate || uploadedDate <= getEndDate(adjustedEndDate));

            return matchesSearchText && isWithinDateRange;
            // return matchesSearchText 
        });
        setInterviewList(filtered);
    }

    const getStartDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(0, 0, 0, 0);
        return originalDate;
    }

    const getEndDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(23, 59, 59, 999);
        return originalDate;
    }

    const updatePagination = (pageNumber, pageSize) => {
        setInterviewPageNumber(pageNumber)
        seInterviewPageSize(pageSize)
    }

    const handleClear = (name) => {
        if (name == 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control"
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        sortData(interviewList, sortConfig.key, sortConfig.direction);
    }, [sortConfig])

    const sortData = (list, key, direction) => { 
        const sortedData = list ? list.sort((a, b) => {
            var value1 = a[key];
            var value2 = b[key];
            
            if (key === 'name') {
                value1 = a['coordinator']['first_name'] + " " + a['coordinator']['last_name'];
                value2 = b['coordinator']['first_name'] + " " + b['coordinator']['last_name'];
            }

            if (key === 'mobile_number') {
                value1 = a['coordinator']['mobile_number'];
                value2 = b['coordinator']['mobile_number'];
            }

            if (key === "scheduled_on") {
                value1 = new Date(a[key]);
                value2 = new Date(b[key]);
            }

            if (typeof value1 === 'string' && typeof value2 === 'string') {
                if (direction === 'asc') {
                    return value1.localeCompare(value2);
                } else {
                    return value2.localeCompare(value1);
                }
            }

            if (typeof value1 === 'object' && typeof value2 === 'object') {
                if (direction === 'asc') {
                    return value1 - value2;
                } else {
                    return value2 - value1;
                }
            }

            if (value1 < value2) return direction === 'asc' ? -1 : 1;
            if (value1 > value2) return direction === 'asc' ? 1 : -1;

            return 0;
        }) : [];

        setInterviewList(sortedData)
        setInitialData(sortedData)
    }

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer"/>;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const isNotEditable = (interviewData) => {
        if (interviewData.is_feedback_submitted_given_date === null) {
            return false;
        } else {
            const feedbackGivenDate = interviewData.is_feedback_submitted_given_date;
            return dayDifference(feedbackGivenDate) > 1;
        }
    }

    return (
        <Fragment>
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid history-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row pb-2 pageTitle_fixed">
                    <div className="col-12 col-md-12 col-lg-4 col-xl-6 my-auto history-title ">
                        <RxCountdownTimer size={20} className="header-icon ms-4" />HISTORY
                    </div>
                    <div className='col-12 col-md-12 col-lg-8 col-xl-6'>
                        <div className='row justify-content-end mt-3'>
                        <div className="col-12 col-lg-2 my-1 my-md-1 my-lg-auto">
                        <Form.Select aria-label="Choose" id="interviewStatus" name="interviewStatus" placeholder="All" onChange={(e) => setInterviewStatus(e.target.value)}>
                            <option>All</option>
                            <option value={INTERVIEW_FINAL_STATUS_SELECTED}>{getInterviewFinalStatus(INTERVIEW_FINAL_STATUS_SELECTED)}</option>
                            <option value={INTERVIEW_FINAL_STATUS_REJECTED}>{getInterviewFinalStatus(INTERVIEW_FINAL_STATUS_REJECTED)}</option>
                            <option value={PROXY}>Proxy</option>
                            <option value={QC_STATUS_FAIL}>Qc Fail</option>
                        </Form.Select>
                        </div>
                        <div className="col-6 col-lg-4 my-1 my-md-1 my-lg-auto">
                            <DatePicker className="form-select w-100" maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                                setFromDate(date);
                                handleClear('toDate');
                            }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('fromDate')} />} />
                            
                        </div>
                        <div className="col-6 col-lg-4 my-1 my-md-auto">
                        <DatePicker className="form-select w-100" minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => setToDate(date)} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                        </div>
                        <div className="col-12 col-lg-2 my-1 my-md-auto">
                            <Button className="history-search-button w-100" onClick={doSearch} >Search</Button>
                        </div>
                            </div>
                            <small className="last-one-month text-danger my-1">Available for last one month</small>
                    </div>
                    
                </div>
                <div className="row history-feednback-row">
                    <div className="col-12 employer-dashboard-title">
                        <Card>
                            <Card.Body>
                                <Table bordered  striped  hover size="lg" responsive className="history-feednback-completed">
                                    <thead className='sticky-top'>
                                        <tr>
                                            <th onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                            <th onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                            <th onClick={() => onSort('job_title')}>Job Title {getSortIcon('job_title')}</th>
                                            <th onClick={() => onSort('company_name')}>Company Name {getSortIcon('company_name')}</th>
                                            <th onClick={() => onSort('name')}>Coordinator Name {getSortIcon('name')}</th>
                                            <th onClick={() => onSort('mobile_number')}>Coordinator Number {getSortIcon('mobile_number')}</th>
                                            <th onClick={() => onSort('scheduled_on')}>Scheduled Date {getSortIcon('scheduled_on')}</th>
                                            <th onClick={() => onSort('final_status')}>Interview Status {getSortIcon('final_status')}</th>
                                            <th onClick={() => onSort('incomplete_interview')}>Incomplete Interview {getSortIcon('incomplete_interview')}</th>
                                            <th onClick={() => onSort('qc_status')}>QC Status {getSortIcon('qc_status')}</th>
                                            <th>Video</th>
                                            <th>Feedback Sheet</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !_.isUndefined(interviewList) && !_.isNull(interviewList) &&
                                            _.map(interviewList.slice((interviewPageNumber - 1) * interviewPageSize, ((interviewPageNumber - 1) * interviewPageSize) + interviewPageSize),
                                                (interview, index) => {
                                                    return <tr key={"feednback-completed-" + index}>
                                                        <td className="text-start">{interview._id}</td>
                                                        <td className="text-start fw-semibold">{interview.candidate_name}</td>
                                                        <td className="text-start">{interview.job_title}</td>
                                                        <td className="text-start">{interview.company_name}</td>
                                                        <td className="text-start">{interview.coordinator ? (interview.coordinator.first_name + " " + interview.coordinator.last_name) : ""}</td>
                                                        <td className="text-start">{interview.coordinator ? (interview.coordinator.mobile_number) : ""}</td>
                                                        <td className="text-start">{moment(interview.scheduled_on).format('MMMM Do YYYY, h:mm A')}</td>
                                                        <td className="text-start"><span className={interview.final_status}>{getInterviewFinalStatus(interview.final_status)}</span></td>
                                                        <td className="text-start">
                                                            {
                                                                interview.incomplete_interview && pascalCase(interview.incomplete_interview.replaceAll("_", " "))
                                                            }
                                                        </td>
                                                        <td className="text-start">
                                                            <span style={{
                                                                color: interview.qc_status === QC_STATUS_FAIL ? 'red' : (
                                                                    interview.qc_status === QC_STATUS_PASS ? 'green' : ''
                                                                )
                                                            }} className={interview.qc_status}>
                                                                {getQCStatuslabel(interview.qc_status)}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                interview.video && (<BiSolidVideos className='cursor-pointer' size={16} onClick={() => window.open(interview.video, "_blank")} />)
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                interview.is_feedback_given && (
                                                                    <FaFileAlt className='cursor-pointer' onClick={() => {
                                                                        if (interview.feedback_sheet != "") {
                                                                            window.open(interview.feedback_sheet, '_blank');
                                                                        } else {
                                                                            window.open(`/finalInterviewReport/${interview._id}`, '_blank')
                                                                        }
                                                                    }} />
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                !isNotEditable(interview) && (
                                                                    <NavLink to={"/live-interviewer/" + interview._id}><div className='btn btn-outline-primary btn-sm'>Edit</div></NavLink>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                                {
                                    interviewList.filter((item) => item.scheduler_status == SCHEDULER_STATUS_DONE) && interviewList.filter((item) => item.scheduler_status == SCHEDULER_STATUS_DONE).length > 0 && (
                                        <PaginationComponent data={interviewList.filter((item) => item.scheduler_status == SCHEDULER_STATUS_DONE)} updatePagination={updatePagination} />
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default HistoryScreen;