import _ from 'lodash';
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { IoLocationOutline } from "react-icons/io5";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import default_profile_picture from "../../Assets/default_profile_picture.png";
import { API_URL, allowedImageTypes, commonDateFormat, getAuthUserFromSessionStorage, imageFileTypeErrorMessage } from "../../common/constant";
import { SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./AdminProfileScreen.css";

const AdminProfileScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [showFormSuccess, setShowFormSuccess] = useState(false);
    const [showLocationPreferences, setShowLocationPreferences] = useState(false);
    const [locationPreferences, setLocationPreferences] = useState([]);
    const [showWorkExperience, setShowWorkExperience] = useState(false);
    const [workExperience, setWorkExperience] = useState({});
    const [showEducations, setShowEducations] = useState(false);
    const [educations, setEducations] = useState({});
    const [showSkills, setShowSkills] = useState(false);
    const [skills, setSkills] = useState([]);

    const [secondarySkills, setSecondarySkills] = useState([]);
    const [showSecondarySkills, setShowSecondarySkills] = useState(false);

    const [showCertificates, setShowCertificates] = useState(false);
    const [certificates, setCertificates] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [showDocuments, setShowDocuments] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showLanguages, setShowLanguages] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [cities, setCities] = useState([]);
    const [interviewerProfile, setInterviewerProfile] = useState({});
    const [designationOptions, setDesignationOptions] = useState([]);

    const [city, setCity] = useState("");
    const [interviewerState, setState] = useState("");
    const [interviewerCountry, setCountry] = useState("");


    const [workExperienceTitleError, setIsWorkExperienceTitleError] = useState("");
    const [workExperienceEmploymentTypeError, setIsWorkExperienceEmploymentTypeError] = useState("");
    const [workExperienceCompanyNameError, setIsWorkExperienceCompanyNameError] = useState("");
    const [workExperienceLocationError, setIsWorkExperienceLocationError] = useState("");
    const [workExperienceLocationTypeError, setIsWorkExperienceLocationTypeError] = useState("");
    const [workExperienceStartDateMonthError, setIsWorkExperienceStartDateMonthError] = useState("");
    const [workExperienceStartDateYearError, setIsWorkExperienceStartDateYearError] = useState("");
    const [workExperienceEndDateMonthError, setIsWorkExperienceEndDateMonthError] = useState("");
    const [workExperienceEndDateYearError, setIsWorkExperienceEndDateYearError] = useState("");
    const [educationSchoolNameError, setIsEducationsSchoolNameError] = useState("");
    const [educationGradeError, setIsEducationsGradeError] = useState("");
    const [educationLocationError, setIsEducationsLocationError] = useState("");
    const [educationsEducationError, setIsEducationsEducationError] = useState("");
    const [educationStartDateMonthError, setIsEducationsStartDateMonthError] = useState("");
    const [educationStartDateYearError, setIsEducationsStartDateYearError] = useState("");
    const [educationEndDateMonthError, setIsEducationsEndDateMonthError] = useState("");
    const [educationEndDateYearError, setIsEducationsEndDateYearError] = useState("");
    const [certificatesTitleError, setIsCertificatesTitleError] = useState("");
    const [certificatesFileError, setIsCertificatesFileError] = useState("");
    const [documentsTitleError, setIsDocumentsTitleError] = useState("");
    const [documentsFileError, setIsDocumentsFileError] = useState("");
    const [bankUserNameError, setIsBankUserNameError] = useState("");
    const [bankNameError, setIsBankNameError] = useState("");
    const [bankBranchNameError, setIsBankBranchNameError] = useState("");
    const [bankAccountNumberError, setIsBankAccountNumberError] = useState("");
    const [bankIFSCCodeError, setIsBankIFSCCodeError] = useState("");
    const [bankUPIIDError, setIsBankUPIIDError] = useState("");
    const [skillsOptions, setSkillsOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([
        { id: 1, name: "Abkhaz" },
        { id: 2, name: "Afar" },
        { id: 3, name: "Afrikaans" },
        { id: 4, name: "Akan" },
        { id: 5, name: "Albanian" },
        { id: 6, name: "Amharic" },
        { id: 7, name: "Arabic" },
        { id: 8, name: "Aragonese" },
        { id: 9, name: "Armenian" },
        { id: 10, name: "Assamese" },
        { id: 11, name: "Avaric" },
        { id: 12, name: "Avestan" },
        { id: 13, name: "Aymara" },
        { id: 14, name: "Azerbaijani" },
        { id: 15, name: "Bambara" },
        { id: 16, name: "Bashkir" },
        { id: 17, name: "Basque" },
        { id: 18, name: "Belarusian" },
        { id: 19, name: "Bengali" },
        { id: 20, name: "Bihari" },
        { id: 21, name: "Bislama" },
        { id: 22, name: "Bosnian" },
        { id: 23, name: "Breton" },
        { id: 24, name: "Bulgarian" },
        { id: 25, name: "Burmese" },
        { id: 26, name: "Catalan; Valencian" },
        { id: 27, name: "Chamorro" },
        { id: 28, name: "Chechen" },
        { id: 29, name: "Chichewa; Chewa; Nyanja" },
        { id: 30, name: "Chinese" },
        { id: 31, name: "Chuvash" },
        { id: 32, name: "Cornish" },
        { id: 33, name: "Corsican" },
        { id: 34, name: "Cree" },
        { id: 35, name: "Croatian" },
        { id: 36, name: "Czech" },
        { id: 37, name: "Danish" },
        { id: 38, name: "Divehi; Dhivehi; Maldivian;" },
        { id: 39, name: "Dutch" },
        { id: 40, name: "English" },
        { id: 41, name: "Esperanto" },
        { id: 42, name: "Estonian" },
        { id: 43, name: "Ewe" },
        { id: 44, name: "Faroese" },
        { id: 45, name: "Fijian" },
        { id: 46, name: "Finnish" },
        { id: 47, name: "French" },
        { id: 48, name: "Fula; Fulah; Pulaar; Pular" },
        { id: 49, name: "Galician" },
        { id: 50, name: "Georgian" },
        { id: 51, name: "German" },
        { id: 52, name: "Greek, Modern" },
        { id: 53, name: "Guaraní" },
        { id: 54, name: "Gujarati" },
        { id: 55, name: "Haitian; Haitian Creole" },
        { id: 56, name: "Hausa" },
        { id: 57, name: "Hebrew (modern)" },
        { id: 58, name: "Herero" },
        { id: 59, name: "Hindi" },
        { id: 60, name: "Hiri Motu" },
        { id: 61, name: "Hungarian" },
        { id: 62, name: "Interlingua" },
        { id: 63, name: "Indonesian" },
        { id: 64, name: "Interlingue" },
        { id: 65, name: "Irish" },
        { id: 66, name: "Igbo" },
        { id: 67, name: "Inupiaq" },
        { id: 68, name: "Ido" },
        { id: 69, name: "Icelandic" },
        { id: 70, name: "Italian" },
        { id: 71, name: "Inuktitut" },
        { id: 72, name: "Japanese" },
        { id: 73, name: "Javanese" },
        { id: 74, name: "Kalaallisut, Greenlandic" },
        { id: 75, name: "Kannada" },
        { id: 76, name: "Kanuri" },
        { id: 77, name: "Kashmiri" },
        { id: 78, name: "Kazakh" },
        { id: 79, name: "Khmer" },
        { id: 80, name: "Kikuyu, Gikuyu" },
        { id: 81, name: "Kinyarwanda" },
        { id: 82, name: "Kirghiz, Kyrgyz" },
        { id: 83, name: "Komi" },
        { id: 84, name: "Kongo" },
        { id: 85, name: "Korean" },
        { id: 86, name: "Kurdish" },
        { id: 87, name: "Kwanyama, Kuanyama" },
        { id: 88, name: "Latin" },
        { id: 89, name: "Luxembourgish, Letzeburgesch" },
        { id: 90, name: "Luganda" },
        { id: 91, name: "Limburgish, Limburgan, Limburger" },
        { id: 92, name: "Lingala" },
        { id: 93, name: "Lao" },
        { id: 94, name: "Lithuanian" },
        { id: 95, name: "Luba-Katanga" },
        { id: 96, name: "Latvian" },
        { id: 97, name: "Manx" },
        { id: 98, name: "Macedonian" },
        { id: 99, name: "Malagasy" },
        { id: 100, name: "Malay" },
        { id: 101, name: "Malayalam" },
        { id: 102, name: "Maltese" },
        { id: 103, name: "Māori" },
        { id: 104, name: "Marathi (Marāṭhī)" },
        { id: 105, name: "Marshallese" },
        { id: 106, name: "Mongolian" },
        { id: 107, name: "Nauru" },
        { id: 108, name: "Navajo, Navaho" },
        { id: 109, name: "Norwegian Bokmål" },
        { id: 110, name: "North Ndebele" },
        { id: 111, name: "Nepali" },
        { id: 112, name: "Ndonga" },
        { id: 113, name: "Norwegian Nynorsk" },
        { id: 114, name: "Norwegian" },
        { id: 115, name: "Nuosu" },
        { id: 116, name: "South Ndebele" },
        { id: 117, name: "Occitan" },
        { id: 118, name: "Ojibwe, Ojibwa" },
        { id: 119, name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic" },
        { id: 120, name: "Oromo" },
        { id: 121, name: "Oriya" },
        { id: 122, name: "Ossetian, Ossetic" },
        { id: 123, name: "Panjabi, Punjabi" },
        { id: 124, name: "Pāli" },
        { id: 125, name: "Persian" },
        { id: 126, name: "Polish" },
        { id: 127, name: "Pashto, Pushto" },
        { id: 128, name: "Portuguese" },
        { id: 129, name: "Quechua" },
        { id: 130, name: "Romansh" },
        { id: 131, name: "Kirundi" },
        { id: 132, name: "Romanian, Moldavian, Moldovan" },
        { id: 133, name: "Russian" },
        { id: 134, name: "Sanskrit (Saṁskṛta)" },
        { id: 135, name: "Sardinian" },
        { id: 136, name: "Sindhi" },
        { id: 137, name: "Northern Sami" },
        { id: 138, name: "Samoan" },
        { id: 139, name: "Sango" },
        { id: 140, name: "Serbian" },
        { id: 141, name: "Scottish Gaelic; Gaelic" },
        { id: 142, name: "Shona" },
        { id: 143, name: "Sinhala, Sinhalese" },
        { id: 144, name: "Slovak" },
        { id: 145, name: "Slovene" },
        { id: 146, name: "Somali" },
        { id: 147, name: "Southern Sotho" },
        { id: 148, name: "Spanish; Castilian" },
        { id: 149, name: "Sundanese" },
        { id: 150, name: "Swahili" },
        { id: 151, name: "Swati" },
        { id: 152, name: "Swedish" },
        { id: 153, name: "Tamil" },
        { id: 154, name: "Telugu" },
        { id: 155, name: "Tajik" },
        { id: 156, name: "Thai" },
        { id: 157, name: "Tigrinya" },
        { id: 158, name: "Tibetan Standard, Tibetan, Central" },
        { id: 159, name: "Turkmen" },
        { id: 160, name: "Tagalog" },
        { id: 161, name: "Tswana" },
        { id: 162, name: "Tonga (Tonga Islands)" },
        { id: 163, name: "Turkish" },
        { id: 164, name: "Tsonga" },
        { id: 165, name: "Tatar" },
        { id: 166, name: "Twi" },
        { id: 167, name: "Tahitian" },
        { id: 168, name: "Uighur, Uyghur" },
        { id: 169, name: "Ukrainian" },
        { id: 170, name: "Urdu" },
        { id: 171, name: "Uzbek" },
        { id: 172, name: "Venda" },
        { id: 173, name: "Vietnamese" },
        { id: 174, name: "Volapük" },
        { id: 175, name: "Walloon" },
        { id: 176, name: "Welsh" },
        { id: 177, name: "Wolof" },
        { id: 178, name: "Western Frisian" },
        { id: 179, name: "Xhosa" },
        { id: 180, name: "Yiddish" },
        { id: 181, name: "Yoruba" },
        { id: 182, name: "Zhuang, Chuang" }
    ]);

    const toast = useRef(null);


    const [monthNames, setMonthNames] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [currentMonth, setCurrentMonth] = useState('January');
    const [currentYear, setCurrentYear] = useState(new Date());
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 1
        }
    ];

    const handleItemClick = (item) => {
        // Handle the clicked item here
        console.log('Clicked Item:', item);
        // setClickedItem(item);
        setCurrentMonth(item)
    };

    const carouselItems = (month) => {
        return (
            <div style={{
                background: currentMonth == month ? 'black' : 'pink',
                color: currentMonth == month ? 'white' : 'black',
                textAlign: "center",
                borderRadius: "15px",
                marginRight: "14px",
                fontSize: '500',
                cursor: 'pointer'
            }} onClick={() => handleItemClick(month)}>{month}</div>
        );
    };

    const [yearList, setYearList] = useState([]);

    const getYearList = (startYear) => {
        var currentYear = new Date().getFullYear(), years = [];
        startYear = 1980;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        setYearList(years)
    }


    const [interviewState, setInterviewState] = useState([
        {
            scheduled_interview_count: 0,
            selected_interview_count: 0,
            rejected_interview_count: 0,
            no_show_by_interviewer_count: 0,
            no_show_by_candidate_count: 0
        }
    ]);

    const { id } = useParams();

    const changeWorkExperienceHandler = (e) => {
        const { name, value } = e.target;
        setWorkExperience({
            ...workExperience,
            [name]: value,
        });
    };

    const changeEducationHandler = (e) => {
        const { name, value } = e.target;
        setEducations({
            ...educations,
            [name]: value,
        });
    };

    const changeBankDetailsHandler = (e) => {
        const { name, value } = e.target;
        setBankDetails({
            ...bankDetails,
            [name]: value,
        });
    };

    const changeCertificatesHandler = (e) => {
        const { name, value } = e.target;
        setCertificates({
            ...certificates,
            [name]: value,
        });
    };

    const changeDocumentsHandler = (e) => {
        const { name, value } = e.target;
        setDocuments({
            ...documents,
            [name]: value,
        });
    };

    const [profileDetail, setProfileDetail] = useState({});

    useEffect(() => {    
        if (!_.isUndefined(interviewerProfile) && !_.isNull(interviewerProfile) && _.isEmpty(interviewerProfile) && !_.isUndefined(id) && !_.isNull(id) && (id !== '')) {
            getInterviewerProfile();
            getAllCity();
            getYearList();
            getSkills();
            getCountryList();
            getPositions();
        }
    }, [interviewerProfile]);


    useEffect(() => {
        if (currentMonth && currentYear) {
            getInterviewerStates();
        }
    }, [currentMonth, currentYear])

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                const transformedOptions = response.data.map(option => ({
                    value: option.name,
                    label: option.name
                }));
                setDesignationOptions(transformedOptions);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const getInterviewerStates = () => {
        setIsLoading(true)
        const index = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/getInterviewerStates/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                currentMonth: (index + 1),
                currentYear: currentYear.getFullYear()
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setInterviewState([{
                    scheduled_interview_count: response.data.scheduled_interview_count,
                    selected_interview_count: response.data.scheduled_interview_count,
                    rejected_interview_count: response.data.scheduled_interview_count,
                    no_show_by_interviewer_count: response.data.scheduled_interview_count,
                    no_show_by_candidate_count: response.data.scheduled_interview_count
                }])
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var allSkills = [];
                response.data.map((item) => {
                    allSkills.push({
                        name: item.name,
                        value: item.name
                    })
                })
                setSkillsOptions(allSkills)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getAllCity = () => {
        fetch(`${API_URL}api/cities`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    let cities = [];
                    _.map(response.data, (city, index) => {
                        cities.push({ id: city.city_id, name: city.name });
                    })
                    setCities(cities);
                }
            })
            .catch((err) => {
                setCities("");
            });
    }

    const getInterviewerProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/profileByID/${id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    setInterviewerProfile(response.data);
                    if (!_.isUndefined(response.data.city_id) && !_.isNull(response.data.city_id) && (response.data.city_id !== '')) {
                        getCityName(response.data.city_id);
                    }
                    if (!_.isUndefined(response.data.state_id) && !_.isNull(response.data.state_id) && (response.data.state_id !== '')) {
                        getStateName(response.data.state_id);
                    }
                    if (!_.isUndefined(response.data.country_id) && !_.isNull(response.data.country_id) && (response.data.country_id !== '')) {
                        getCountry(response.data.country_id);
                    }

                    setProfileDetail({
                        email: response.data.email,
                        designation: response.data.designation,
                        profile_image: response.data.profile_image,
                        naukari_profile: response.data.naukari_profile,
                        linkedin: response.data.linkedin,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_number: response.data.mobile_number
                    })

                    setBankDetails({
                        bank_user_name : response.data.bank_user_name,
                        bank_name : response.data.bank_name,
                        bank_branch_name : response.data.bank_branch_name,
                        bank_account_number : response.data.bank_account_number,
                        bank_ifsc_code : response.data.bank_ifsc_code,
                        bank_upi_id : response.data.bank_upi_id
                    })
                    var allSelectedPrimarySkills = [];
                    response.data.primary_skill.map((item) => {
                        allSelectedPrimarySkills.push({
                            name: item,
                            value: item
                        })
                    })
                    setSkills(allSelectedPrimarySkills)
                    var allSelectedSecondaySkills = [];
                    response.data.secondary_skill.map((item) => {
                        allSelectedSecondaySkills.push({
                            name: item,
                            value: item
                        })
                    })
                    setSecondarySkills(allSelectedSecondaySkills)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerProfile({});
            });
    }

    const getCityName = (cityBYID) => {
        fetch(`${API_URL}api/cities/cityBYID/${cityBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCity(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const getStateName = (stateBYID) => {
        fetch(`${API_URL}api/states/stateBYID/${stateBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setState(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const [countryOptions, setCountryOptions] = useState([]);

    const getCountryList = () => {
        fetch(`${API_URL}api/countries`)
         .then((response) => response.json())
         .then((response) => {
            if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0))
            {
                const transformedOptions = response.data.map(option => ({
                    id: option.country_id,
                    name: option.name
                }));
                setCountryOptions(transformedOptions);
            }
         })
         .catch((err) => {
            setCountryOptions([]);
         });
    }

    const getCountry = (countryBYID) => {
        fetch(`${API_URL}api/countries/${countryBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCountry(response.data.name);
                }
            })
            .catch((err) => {
                setCountry({});
            });
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };

    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };

    const onRemoveLocationPreferences = (selectedList, removedItem) => {
        setLocationPreferences(selectedList);

    }
    const onSelectLocationPreferences = (selectedList, selectedItem) => {
        setLocationPreferences(selectedList);
    }

    const submitLocationPreferences = () => {
        if (locationPreferences.length > 0) {
            let params = {
                location_preferences: locationPreferences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                    setShowLocationPreferences(false);
                    getInterviewerProfile();
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                    setShowLocationPreferences(false)
                });
        }
    }

    const onRemoveLanguages = (selectedList, removedItem) => {
        setLanguages(selectedList);
    }
    const onSelectLanguages = (selectedList, selectedItem) => {
        setLanguages(selectedList);
    }

    const submitLanguages = () => {
        if (languages.length > 0) {
            let params = {
                languages: languages
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const onRemoveSkills = (selectedList, removedItem) => {
        setSkills(selectedList);
    }
    const onSelectSkills = (selectedList, selectedItem) => {
        setSkills(selectedList);
    }

    const submitSkills = () => {
        if (skills.length > 0) {
            var allPrimarySkills = [];
            skills.map((item) => {
                allPrimarySkills.push(item.value)
            })
            let params = {
                primary_skill: allPrimarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });            
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });            
                    }
                });
        }
    }

    const submitSecondaySkills = () => {
        if (secondarySkills.length > 0) {
            var allSecondarySkills = [];
            secondarySkills.map((item) => {
                allSecondarySkills.push(item.value)
            })
            let params = {
                secondary_skill: allSecondarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSecondarySkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });            
                    }
                });
        }
    }
    const submitWorkExperience = () => {
        validateWorkExperienceForm();
        if (checkedValidateWorkExperienceForm()) {
            let workExperiences = interviewerProfile.work_experience;
            workExperiences.push(workExperience);
            let params = {
                work_experience: workExperiences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });            
                    }
                });
        }
    }
    const checkedValidateWorkExperienceForm = () => {
        if (_.isUndefined(workExperience.title) || _.isNull(workExperience.title) || (workExperience.title === '') || (workExperience.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.employment_type) || _.isNull(workExperience.employment_type) || (workExperience.employment_type === '') || (workExperience.employment_type.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.company_name) || _.isNull(workExperience.company_name) || (workExperience.company_name === '') || (workExperience.company_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.location) || _.isNull(workExperience.location) || (workExperience.location === '') || (workExperience.location.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.location_type) || _.isNull(workExperience.location_type) || (workExperience.location_type === '') || (workExperience.location_type.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.start_date_month) || _.isNull(workExperience.start_date_month) || (workExperience.start_date_month === '') || (workExperience.start_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.start_date_year) || _.isNull(workExperience.start_date_year) || (workExperience.start_date_year === '') || (workExperience.start_date_year.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.end_date_month) || _.isNull(workExperience.end_date_month) || (workExperience.end_date_month === '') || (workExperience.end_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.end_date_year) || _.isNull(workExperience.end_date_year) || (workExperience.end_date_year === '') || (workExperience.end_date_year.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateWorkExperienceForm = () => {
        if (_.isUndefined(workExperience.title) || _.isNull(workExperience.title) || (workExperience.title === '') || (workExperience.title.trim() === '')) {
            setIsWorkExperienceTitleError("Experience title is required!");
        }
        else {
            setIsWorkExperienceTitleError("");
        }
        if (_.isUndefined(workExperience.employment_type) || _.isNull(workExperience.employment_type) || (workExperience.employment_type === '') || (workExperience.employment_type.trim() === '')) {
            setIsWorkExperienceEmploymentTypeError("Employment type is required!");
        }
        else {
            setIsWorkExperienceEmploymentTypeError("");
        }
        if (_.isUndefined(workExperience.company_name) || _.isNull(workExperience.company_name) || (workExperience.company_name === '') || (workExperience.company_name.trim() === '')) {
            setIsWorkExperienceCompanyNameError("Company name is required!");
        }
        else {
            setIsWorkExperienceCompanyNameError("");
        }
        if (_.isUndefined(workExperience.location) || _.isNull(workExperience.location) || (workExperience.location === '') || (workExperience.location.trim() === '')) {
            setIsWorkExperienceLocationError("Location is required!");
        }
        else {
            setIsWorkExperienceLocationError("");
        }
        if (_.isUndefined(workExperience.location_type) || _.isNull(workExperience.location_type) || (workExperience.location_type === '') || (workExperience.location_type.trim() === '')) {
            setIsWorkExperienceLocationTypeError("Location type is required!");
        }
        else {
            setIsWorkExperienceLocationTypeError("");
        }
        if (_.isUndefined(workExperience.start_date_month) || _.isNull(workExperience.start_date_month) || (workExperience.start_date_month === '') || (workExperience.start_date_month.trim() === '')) {
            setIsWorkExperienceStartDateMonthError("Start Date Month is required!");
        }
        else {
            setIsWorkExperienceStartDateMonthError("");
        }
        if (_.isUndefined(workExperience.start_date_year) || _.isNull(workExperience.start_date_year) || (workExperience.start_date_year === '') || (workExperience.start_date_year.trim() === '')) {
            setIsWorkExperienceStartDateYearError("Start Date Year is required!");
        }
        else {
            setIsWorkExperienceStartDateYearError("");
        }
        if (_.isUndefined(workExperience.end_date_month) || _.isNull(workExperience.end_date_month) || (workExperience.end_date_month === '') || (workExperience.end_date_month.trim() === '')) {
            setIsWorkExperienceEndDateMonthError("End Date Month is required!");
        }
        else {
            setIsWorkExperienceEndDateMonthError("");
        }
        if (_.isUndefined(workExperience.end_date_year) || _.isNull(workExperience.end_date_year) || (workExperience.end_date_year === '') || (workExperience.end_date_year.trim() === '')) {
            setIsWorkExperienceEndDateYearError("End Date Year is required!");
        }
        else {
            setIsWorkExperienceEndDateYearError("");
        }
    };

    const submitEducations = () => {
        validateEducationForm();
        if (checkedValidateEducationForm()) {
            let newEducations = interviewerProfile.educations;
            newEducations.push(educations);
            let params = {
                educations: newEducations
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowEducations(false);
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }
    const checkedValidateEducationForm = () => {
        if (_.isUndefined(educations.school_name) || _.isNull(educations.school_name) || (educations.school_name === '') || (educations.school_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.grade) || _.isNull(educations.grade) || (educations.grade === '') || (educations.grade.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.location) || _.isNull(educations.location) || (educations.location === '') || (educations.location.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.start_date_month) || _.isNull(educations.start_date_month) || (educations.start_date_month === '') || (educations.start_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.start_date_year) || _.isNull(educations.start_date_year) || (educations.start_date_year === '') || (educations.start_date_year.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.end_date_month) || _.isNull(educations.end_date_month) || (educations.end_date_month === '') || (educations.end_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.end_date_year) || _.isNull(educations.end_date_year) || (educations.end_date_year === '') || (educations.end_date_year.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateEducationForm = () => {
        if (_.isUndefined(educations.school_name) || _.isNull(educations.school_name) || (educations.school_name === '') || (educations.school_name.trim() === '')) {
            setIsEducationsSchoolNameError("School name is required!");
        }
        else {
            setIsEducationsSchoolNameError("");
        }
        if (_.isUndefined(educations.grade) || _.isNull(educations.grade) || (educations.grade === '') || (educations.grade.trim() === '')) {
            setIsEducationsGradeError("Grade is required!");
        }
        else {
            setIsEducationsGradeError("");
        }
        if (_.isUndefined(educations.location) || _.isNull(educations.location) || (educations.location === '') || (educations.location.trim() === '')) {
            setIsEducationsLocationError("Location is required!");
        }
        else {
            setIsEducationsLocationError("");
        }
        if (_.isUndefined(educations.education) || _.isNull(educations.education) || (educations.education === '') || (educations.education.trim() === '')) {
            setIsEducationsEducationError("Education is required!");
        }
        else {
            setIsEducationsEducationError("");
        }
        if (_.isUndefined(educations.start_date_month) || _.isNull(educations.start_date_month) || (educations.start_date_month === '') || (educations.start_date_month.trim() === '')) {
            setIsEducationsStartDateMonthError("Start Date Month is required!");
        }
        else {
            setIsEducationsStartDateMonthError("");
        }
        if (_.isUndefined(educations.start_date_year) || _.isNull(educations.start_date_year) || (educations.start_date_year === '') || (educations.start_date_year.trim() === '')) {
            setIsEducationsStartDateYearError("Start Date Year is required!");
        }
        else {
            setIsEducationsStartDateYearError("");
        }
        if (_.isUndefined(educations.end_date_month) || _.isNull(educations.end_date_month) || (educations.end_date_month === '') || (educations.end_date_month.trim() === '')) {
            setIsEducationsEndDateMonthError("End Date Month is required!");
        }
        else {
            setIsEducationsEndDateMonthError("");
        }
        if (_.isUndefined(educations.end_date_year) || _.isNull(educations.end_date_year) || (educations.end_date_year === '') || (educations.end_date_year.trim() === '')) {
            setIsEducationsEndDateYearError("End Date Year is required!");
        }
        else {
            setIsEducationsEndDateYearError("");
        }
    };

    const submitCertificates = () => {
        validateCertificatesForm();
        if (checkedValidateCertificatesForm()) {
            let newCertificates = interviewerProfile.certificates;
            newCertificates.push(certificates);
            let params = {
                certificates: newCertificates
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }
    const checkedValidateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            setIsCertificatesTitleError("Title is required!");
        }
        else {
            setIsCertificatesTitleError("");
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            setIsCertificatesFileError("Certificate File is required!");
        }
        else {
            setIsCertificatesFileError("");
        }
    };
    const changeUploadCertificatesHandler = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setCertificates({
                        ...certificates,
                        certificate_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
    }

    const submitDocuments = () => {
        validateDocumentsForm();
        if (checkedValidateDocumentsForm()) {
            let newDocuments = interviewerProfile.documents;
            newDocuments.push(documents);
            let params = {
                documents: newDocuments
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 }); 
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 }); 
                    }
                });
        }
    }
    const checkedValidateDocumentsForm = () => {
        if (_.isUndefined(documents.title) || _.isNull(documents.title) || (documents.title === '') || (documents.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(documents.document_file) || _.isNull(documents.document_file) || (documents.document_file === '') || (documents.document_file.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateDocumentsForm = () => {
        if (_.isUndefined(documents.title) || _.isNull(documents.title) || (documents.title === '') || (documents.title.trim() === '')) {
            setIsDocumentsTitleError("Title is required!");
        }
        else {
            setIsDocumentsTitleError("");
        }
        if (_.isUndefined(documents.document_file) || _.isNull(documents.document_file) || (documents.document_file === '') || (documents.document_file.trim() === '')) {
            setIsDocumentsFileError("Certificate File is required!");
        }
        else {
            setIsDocumentsFileError("");
        }
    };



    const submitBankDetails = () => {
        validateBankDetailsForm();
        if (checkedValidateBankDetailsForm()) {
            let params = {
                bank_user_name: bankDetails.bank_user_name,
                bank_name: bankDetails.bank_name,
                bank_branch_name: bankDetails.bank_branch_name,
                bank_account_number: bankDetails.bank_account_number,
                bank_ifsc_code: bankDetails.bank_ifsc_code,
                bank_upi_id: bankDetails.bank_upi_id,
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });   
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });   
                    }
                });
        }
    }
    const checkedValidateBankDetailsForm = () => {
        if (_.isUndefined(bankDetails.bank_user_name) || _.isNull(bankDetails.bank_user_name) || (bankDetails.bank_user_name === '') || (bankDetails.bank_user_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_name) || _.isNull(bankDetails.bank_name) || (bankDetails.bank_name === '') || (bankDetails.bank_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_branch_name) || _.isNull(bankDetails.bank_branch_name) || (bankDetails.bank_branch_name === '') || (bankDetails.bank_branch_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_account_number) || _.isNull(bankDetails.bank_account_number) || (bankDetails.bank_account_number === '') || (bankDetails.bank_account_number.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_ifsc_code) || _.isNull(bankDetails.bank_ifsc_code) || (bankDetails.bank_ifsc_code === '') || (bankDetails.bank_ifsc_code.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_upi_id) || _.isNull(bankDetails.bank_upi_id) || (bankDetails.bank_upi_id === '') || (bankDetails.bank_upi_id.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateBankDetailsForm = () => {
        if (_.isUndefined(bankDetails.bank_user_name) || _.isNull(bankDetails.bank_user_name) || (bankDetails.bank_user_name === '') || (bankDetails.bank_user_name.trim() === '')) {
            setIsBankUserNameError("Account Holder Name is required!");
        }
        else {
            setIsBankUserNameError("");
        }
        if (_.isUndefined(bankDetails.bank_name) || _.isNull(bankDetails.bank_name) || (bankDetails.bank_name === '') || (bankDetails.bank_name.trim() === '')) {
            setIsBankNameError("Bank Name is required!");
        }
        else {
            setIsBankNameError("");
        }
        if (_.isUndefined(bankDetails.bank_branch_name) || _.isNull(bankDetails.bank_branch_name) || (bankDetails.bank_branch_name === '') || (bankDetails.bank_branch_name.trim() === '')) {
            setIsBankBranchNameError("Bank Branch Name is required!");
        }
        else {
            setIsBankBranchNameError("");
        }
        if (_.isUndefined(bankDetails.bank_account_number) || _.isNull(bankDetails.bank_account_number) || (bankDetails.bank_account_number === '') || (bankDetails.bank_account_number.trim() === '')) {
            setIsBankAccountNumberError("Bank Account Number is required!");
        }
        else {
            setIsBankAccountNumberError("");
        }
        if (_.isUndefined(bankDetails.bank_ifsc_code) || _.isNull(bankDetails.bank_ifsc_code) || (bankDetails.bank_ifsc_code === '') || (bankDetails.bank_ifsc_code.trim() === '')) {
            setIsBankIFSCCodeError("Bank IFSC code is required!");
        }
        else {
            setIsBankIFSCCodeError("");
        }
        if (_.isUndefined(bankDetails.bank_upi_id) || _.isNull(bankDetails.bank_upi_id) || (bankDetails.bank_upi_id === '') || (bankDetails.bank_upi_id.trim() === '')) {
            setIsBankUPIIDError("Bank UPI ID is required!");
        }
        else {
            setIsBankUPIIDError("");
        }
    };
    const changeUploadDocumentsHandler = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setDocuments({
                        ...documents,
                        document_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
    }
    const downloadFile = async (url) => {
        await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));

                const link = document.createElement('a');
                link.href = url;
                link.download = Date.now() + '.pdf';

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });
    };


    const [isDetailEdit, setIsDetailEdit] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "mobile_number") {
            const input = e.target.value;
            const numericInput = input.replace(/\D/g, '');
            const truncatedInput = numericInput.slice(0, 10);
            setProfileDetail({
                ...profileDetail,
                [name]: truncatedInput,
            });
        } else {
            setProfileDetail({
                ...profileDetail,
                [e.target.name]: value,
            });
        }
    }

    const fileUploadRef = useRef(null);
    const [resumeFileName, setResumeFileName] = useState(null);

    const uploadFileHandler = async (event) => {
        if (allowedImageTypes.includes(event.target.files[0].type)) {
            setResumeFileName(event.target.files[0].name)
            setIsLoading(true)
            const file = event.target.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
            .then((response) => response.json())
            .then((response) => {
                profileDetail.profile_image = response.filepath;
                clearFile(fileUploadRef)
                const event = new CustomEvent('profileImageUpdated', {
                    detail: { profileImage: response.filepath  }
                });
                window.dispatchEvent(event);

                updateProfile();
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("error", err);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                clearFile(fileUploadRef)
                setIsLoading(false)
            });
        } else {
            clearFile(fileUploadRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: imageFileTypeErrorMessage, life: 3000 });
        }
    };

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
        setResumeFileName(null)
    } 

    const updateProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/updateProfileByID/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(profileDetail)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                setIsDetailEdit(false);
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
                getInterviewerProfile();
            })
            .catch((error) => {
                setIsLoading(false)
                setIsDetailEdit(false);
            });
    }

    const selectChangeHandler = (e, name) => {
        const { value } = e;
        setProfileDetail({
            ...profileDetail,
            [name]: value,
        });
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row mx-5">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3">
                            <div className="col-12 pageTitle_fixed">
                                <p className="employer-dashboard-jobs-posted">Settings / Interviewer / <span className='text-primary'>{interviewerProfile.first_name + " " + interviewerProfile.last_name}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 mb-2 interviewer-profile-details-left">
                                <Card>
                                    <Card.Body>
                                        <div className="row mb-3">
                                            <div className="col-12 text-center">
                                                <img className="profile-image" src={!_.isUndefined(profileDetail.profile_image) && !_.isNull(profileDetail.profile_image) && (profileDetail.profile_image !== '') ? profileDetail.profile_image : default_profile_picture} alt="Profile" />
                                                {
                                                    userDetails && userDetails.id === interviewerProfile.id && (

                                                        <>
                                                            <input style={{display: 'none'}} type="file" id="upload_profile" name="upload_profile" onChange={uploadFileHandler} placeholder="Profile Photo" multiple ref={fileUploadRef} accept="image/*"/>
                                                            <div className="file-upload-container mt-2" onClick={() => fileUploadRef.current.click()}>
                                                                <div className="custom-file-upload">
                                                                    Choose file
                                                                </div>
                                                                <div className="file-name">{resumeFileName ?? "No file chosen"}</div>
                                                            </div>
                                                        </>

                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row my-6">
                                            <div className="col-12">
                                                <b>PROFILE</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email*</Form.Label>
                                                    {
                                                        isDetailEdit && userDetails.account_type === SUPER_ADMIN ?
                                                            (
                                                                <Form.Control
                                                                    value={profileDetail.email}
                                                                    type="text"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    onChange={changeHandler}
                                                                    maxLength={150}
                                                                    autoComplete="off"
                                                                />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.email}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Registration ID</label>
                                                <p className="interviewer-profile-details-value">{interviewerProfile.id}</p>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobie Number*</Form.Label>
                                                    <p className="interviewer-profile-details-value">
                                                        {   
                                                            (isDetailEdit && userDetails.account_type === SUPER_ADMIN) ?
                                                                (
                                                                    <Form.Control
                                                                        value={profileDetail.mobile_number}
                                                                        type="text"
                                                                        id="mobile_number"
                                                                        name="mobile_number"
                                                                        placeholder="Mobile Number"
                                                                        onChange={changeHandler}
                                                                        maxLength={10}
                                                                        autoComplete="off"
                                                                    />
                                                                ) : (
                                                                    <p className="interviewer-profile-details-value">{profileDetail.mobile_number}</p>
                                                                )
                                                        }
                                                    </p>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Designation*</Form.Label>
                                                    {
                                                        isDetailEdit && userDetails.id === interviewerProfile.id ?
                                                            (
                                                                userDetails.account_type === SUPER_ADMIN ? (
                                                                    <Form.Control
                                                                        value={profileDetail.designation}
                                                                        type="text"
                                                                        id="designation"
                                                                        name="designation"
                                                                        placeholder="Enter Designation"
                                                                        onChange={changeHandler}
                                                                        autoComplete="off"
                                                                        
                                                                    />
                                                                ) : (
                                                                    <Select
                                                                        options={designationOptions}
                                                                        defaultValue={profileDetail ? {label: profileDetail.designation, value: profileDetail.designation} : ""}
                                                                        onChange={(e) => selectChangeHandler(e, 'designation')}
                                                                        id="designation"
                                                                        name="designation"
                                                                        placeholder="Choose Designation"
                                                                        isSearchable={true}
                                                                        className='form-control designtn'
                                                                    />
                                                                )
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.designation}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Sign Up Date</label>
                                                <p className="interviewer-profile-details-value">{commonDateFormat(interviewerProfile.createdAt)}</p>
                                            </div>

                                            <div className="col-12">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                        isDetailEdit ? (
                                                            <a href="JavaScript:;" onClick={() => updateProfile()} className="btn btn-primary">Save</a>
                                                        ) : (
                                                            <a href="JavaScript:;" onClick={() => setIsDetailEdit(true)} className="btn btn-outline-primary">Edit</a>
                                                        )
                                                    ) 
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-9 col-12 interviewer-profile-details-right">
                                <Card>
                                    <Card.Body>
                                        <div className="row px-5">
                                            <div className="col-12">
                                                <b className="interviewer-profile-details-right-name">{interviewerProfile.first_name + " " + interviewerProfile.last_name} <span className="interviewer-profile-details-right-location"><IoLocationOutline size={16} />{city + ", " + interviewerState + ", " + interviewerCountry}</span></b>
                                            </div>
                                            <div className="col-12">
                                                <p className="interviewer-profile-details-right-designation">{interviewerProfile.designation}</p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
           
        </Fragment>
    )
}
export default AdminProfileScreen;