import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { Carousel } from 'primereact/carousel';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { BsFillSendFill } from "react-icons/bs";
import { FaDownload, FaEye } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import default_profile_picture from "../../Assets/default_profile_picture.png";
import { API_URL, QC_STATUS_FAIL, USER_STATUS_APPROVE, allowedImageTypes, commonDateFormat, getAuthUserFromSessionStorage, getInterviewFinalStatus, imageFileTypeErrorMessage } from "../../common/constant";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./CompanyProfileScreen.css";
const CompanyProfileScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [showCompanyDetails, setShowCompanyDetails] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [showFormSuccess, setShowFormSuccess] = useState(false);
    const [showLocationPreferences, setShowLocationPreferences] = useState(false);
    const [locationPreferences, setLocationPreferences] = useState([]);
    const [showWorkExperience, setShowWorkExperience] = useState(false);
    const [workExperience, setWorkExperience] = useState({});
    const [showEducations, setShowEducations] = useState(false);
    const [educations, setEducations] = useState({});
    const [showSkills, setShowSkills] = useState(false);
    const [skills, setSkills] = useState([]);
    const [showCertificates, setShowCertificates] = useState(false);
    const [certificates, setCertificates] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [showDocuments, setShowDocuments] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showLanguages, setShowLanguages] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [cities, setCities] = useState([]);
    const [interviewerProfile, setInterviewerProfile] = useState({});

    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");

    const [workExperienceTitleError, setIsWorkExperienceTitleError] = useState("");
    const [workExperienceEmploymentTypeError, setIsWorkExperienceEmploymentTypeError] = useState("");
    const [workExperienceCompanyNameError, setIsWorkExperienceCompanyNameError] = useState("");
    const [workExperienceLocationError, setIsWorkExperienceLocationError] = useState("");
    const [workExperienceLocationTypeError, setIsWorkExperienceLocationTypeError] = useState("");
    const [workExperienceStartDateMonthError, setIsWorkExperienceStartDateMonthError] = useState("");
    const [workExperienceStartDateYearError, setIsWorkExperienceStartDateYearError] = useState("");
    const [workExperienceEndDateMonthError, setIsWorkExperienceEndDateMonthError] = useState("");
    const [workExperienceEndDateYearError, setIsWorkExperienceEndDateYearError] = useState("");
    const [educationSchoolNameError, setIsEducationsSchoolNameError] = useState("");
    const [educationGradeError, setIsEducationsGradeError] = useState("");
    const [educationLocationError, setIsEducationsLocationError] = useState("");
    const [educationStartDateMonthError, setIsEducationsStartDateMonthError] = useState("");
    const [educationStartDateYearError, setIsEducationsStartDateYearError] = useState("");
    const [educationEndDateMonthError, setIsEducationsEndDateMonthError] = useState("");
    const [educationEndDateYearError, setIsEducationsEndDateYearError] = useState("");
    const [certificatesTitleError, setIsCertificatesTitleError] = useState("");
    const [certificatesFileError, setIsCertificatesFileError] = useState("");
    const [documentsTitleError, setIsDocumentsTitleError] = useState("");
    const [documentsFileError, setIsDocumentsFileError] = useState("");

    const [profileDetail, setProfileDetail] = useState({});
    const [compantUsers, setCompanyUsers] = useState([]);
    const [companyDescription, setCompanyDescription] = useState("");
    const [companyDetails, setCompanyDetails] = useState({});

    const [designationOptions, setDesignationOptions] = useState([]);

    const [firstNameError, setIsFirstNameError] = useState("");
    const [lastNameError, setIsLastNameError] = useState("");
    const [emailError, setIsEmailError] = useState("");
    const [mobileNumberError, setIsMobileNumberError] = useState("");
    const [designationError, setIsDesignationError] = useState("");
    const [roleError, setIsRoleError] = useState("");

    const [skillsOptions, setSkillsOptions] = useState([
        { id: 1, name: "Communication" },
        { id: 2, name: "Emotional intelligence" },
        { id: 3, name: "Leadership" },
        { id: 4, name: "Marketing" },
        { id: 5, name: "Creativity" },
        { id: 6, name: "Management" },
        { id: 7, name: "Project management" },
        { id: 8, name: "Problem solving" },
        { id: 9, name: "Social media" },
        { id: 10, name: "Sales" },
        { id: 11, name: "Computing" },
        { id: 12, name: "Customer relationship management" },
        { id: 13, name: "Business" },
        { id: 14, name: "Data analysis" },
        { id: 15, name: "Active listening" },
        { id: 16, name: "Collaboration" },
        { id: 17, name: "Adaptability" },
        { id: 18, name: "Research" },
        { id: 19, name: "Interpersonal communication" },
        { id: 20, name: "Writing" },
        { id: 21, name: "Customer Service" },
        { id: 22, name: "Teamwork" },
        { id: 23, name: "Artificial intelligence" },
        { id: 24, name: "Time management" },
        { id: 25, name: "React Developer" },
        { id: 26, name: "Flutter" },
        { id: 27, name: "PHP" },
        { id: 28, name: "NodeJS" },
        { id: 29, name: "React Native" },
        { id: 30, name: "IOS" },
    ]);
    const [languageOptions, setLanguageOptions] = useState([
        { id: 1, name: "Abkhaz" },
        { id: 2, name: "Afar" },
        { id: 3, name: "Afrikaans" },
        { id: 4, name: "Akan" },
        { id: 5, name: "Albanian" },
        { id: 6, name: "Amharic" },
        { id: 7, name: "Arabic" },
        { id: 8, name: "Aragonese" },
        { id: 9, name: "Armenian" },
        { id: 10, name: "Assamese" },
        { id: 11, name: "Avaric" },
        { id: 12, name: "Avestan" },
        { id: 13, name: "Aymara" },
        { id: 14, name: "Azerbaijani" },
        { id: 15, name: "Bambara" },
        { id: 16, name: "Bashkir" },
        { id: 17, name: "Basque" },
        { id: 18, name: "Belarusian" },
        { id: 19, name: "Bengali" },
        { id: 20, name: "Bihari" },
        { id: 21, name: "Bislama" },
        { id: 22, name: "Bosnian" },
        { id: 23, name: "Breton" },
        { id: 24, name: "Bulgarian" },
        { id: 25, name: "Burmese" },
        { id: 26, name: "Catalan; Valencian" },
        { id: 27, name: "Chamorro" },
        { id: 28, name: "Chechen" },
        { id: 29, name: "Chichewa; Chewa; Nyanja" },
        { id: 30, name: "Chinese" },
        { id: 31, name: "Chuvash" },
        { id: 32, name: "Cornish" },
        { id: 33, name: "Corsican" },
        { id: 34, name: "Cree" },
        { id: 35, name: "Croatian" },
        { id: 36, name: "Czech" },
        { id: 37, name: "Danish" },
        { id: 38, name: "Divehi; Dhivehi; Maldivian;" },
        { id: 39, name: "Dutch" },
        { id: 40, name: "English" },
        { id: 41, name: "Esperanto" },
        { id: 42, name: "Estonian" },
        { id: 43, name: "Ewe" },
        { id: 44, name: "Faroese" },
        { id: 45, name: "Fijian" },
        { id: 46, name: "Finnish" },
        { id: 47, name: "French" },
        { id: 48, name: "Fula; Fulah; Pulaar; Pular" },
        { id: 49, name: "Galician" },
        { id: 50, name: "Georgian" },
        { id: 51, name: "German" },
        { id: 52, name: "Greek, Modern" },
        { id: 53, name: "Guaraní" },
        { id: 54, name: "Gujarati" },
        { id: 55, name: "Haitian; Haitian Creole" },
        { id: 56, name: "Hausa" },
        { id: 57, name: "Hebrew (modern)" },
        { id: 58, name: "Herero" },
        { id: 59, name: "Hindi" },
        { id: 60, name: "Hiri Motu" },
        { id: 61, name: "Hungarian" },
        { id: 62, name: "Interlingua" },
        { id: 63, name: "Indonesian" },
        { id: 64, name: "Interlingue" },
        { id: 65, name: "Irish" },
        { id: 66, name: "Igbo" },
        { id: 67, name: "Inupiaq" },
        { id: 68, name: "Ido" },
        { id: 69, name: "Icelandic" },
        { id: 70, name: "Italian" },
        { id: 71, name: "Inuktitut" },
        { id: 72, name: "Japanese" },
        { id: 73, name: "Javanese" },
        { id: 74, name: "Kalaallisut, Greenlandic" },
        { id: 75, name: "Kannada" },
        { id: 76, name: "Kanuri" },
        { id: 77, name: "Kashmiri" },
        { id: 78, name: "Kazakh" },
        { id: 79, name: "Khmer" },
        { id: 80, name: "Kikuyu, Gikuyu" },
        { id: 81, name: "Kinyarwanda" },
        { id: 82, name: "Kirghiz, Kyrgyz" },
        { id: 83, name: "Komi" },
        { id: 84, name: "Kongo" },
        { id: 85, name: "Korean" },
        { id: 86, name: "Kurdish" },
        { id: 87, name: "Kwanyama, Kuanyama" },
        { id: 88, name: "Latin" },
        { id: 89, name: "Luxembourgish, Letzeburgesch" },
        { id: 90, name: "Luganda" },
        { id: 91, name: "Limburgish, Limburgan, Limburger" },
        { id: 92, name: "Lingala" },
        { id: 93, name: "Lao" },
        { id: 94, name: "Lithuanian" },
        { id: 95, name: "Luba-Katanga" },
        { id: 96, name: "Latvian" },
        { id: 97, name: "Manx" },
        { id: 98, name: "Macedonian" },
        { id: 99, name: "Malagasy" },
        { id: 100, name: "Malay" },
        { id: 101, name: "Malayalam" },
        { id: 102, name: "Maltese" },
        { id: 103, name: "Māori" },
        { id: 104, name: "Marathi (Marāṭhī)" },
        { id: 105, name: "Marshallese" },
        { id: 106, name: "Mongolian" },
        { id: 107, name: "Nauru" },
        { id: 108, name: "Navajo, Navaho" },
        { id: 109, name: "Norwegian Bokmål" },
        { id: 110, name: "North Ndebele" },
        { id: 111, name: "Nepali" },
        { id: 112, name: "Ndonga" },
        { id: 113, name: "Norwegian Nynorsk" },
        { id: 114, name: "Norwegian" },
        { id: 115, name: "Nuosu" },
        { id: 116, name: "South Ndebele" },
        { id: 117, name: "Occitan" },
        { id: 118, name: "Ojibwe, Ojibwa" },
        { id: 119, name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic" },
        { id: 120, name: "Oromo" },
        { id: 121, name: "Oriya" },
        { id: 122, name: "Ossetian, Ossetic" },
        { id: 123, name: "Panjabi, Punjabi" },
        { id: 124, name: "Pāli" },
        { id: 125, name: "Persian" },
        { id: 126, name: "Polish" },
        { id: 127, name: "Pashto, Pushto" },
        { id: 128, name: "Portuguese" },
        { id: 129, name: "Quechua" },
        { id: 130, name: "Romansh" },
        { id: 131, name: "Kirundi" },
        { id: 132, name: "Romanian, Moldavian, Moldovan" },
        { id: 133, name: "Russian" },
        { id: 134, name: "Sanskrit (Saṁskṛta)" },
        { id: 135, name: "Sardinian" },
        { id: 136, name: "Sindhi" },
        { id: 137, name: "Northern Sami" },
        { id: 138, name: "Samoan" },
        { id: 139, name: "Sango" },
        { id: 140, name: "Serbian" },
        { id: 141, name: "Scottish Gaelic; Gaelic" },
        { id: 142, name: "Shona" },
        { id: 143, name: "Sinhala, Sinhalese" },
        { id: 144, name: "Slovak" },
        { id: 145, name: "Slovene" },
        { id: 146, name: "Somali" },
        { id: 147, name: "Southern Sotho" },
        { id: 148, name: "Spanish; Castilian" },
        { id: 149, name: "Sundanese" },
        { id: 150, name: "Swahili" },
        { id: 151, name: "Swati" },
        { id: 152, name: "Swedish" },
        { id: 153, name: "Tamil" },
        { id: 154, name: "Telugu" },
        { id: 155, name: "Tajik" },
        { id: 156, name: "Thai" },
        { id: 157, name: "Tigrinya" },
        { id: 158, name: "Tibetan Standard, Tibetan, Central" },
        { id: 159, name: "Turkmen" },
        { id: 160, name: "Tagalog" },
        { id: 161, name: "Tswana" },
        { id: 162, name: "Tonga (Tonga Islands)" },
        { id: 163, name: "Turkish" },
        { id: 164, name: "Tsonga" },
        { id: 165, name: "Tatar" },
        { id: 166, name: "Twi" },
        { id: 167, name: "Tahitian" },
        { id: 168, name: "Uighur, Uyghur" },
        { id: 169, name: "Ukrainian" },
        { id: 170, name: "Urdu" },
        { id: 171, name: "Uzbek" },
        { id: 172, name: "Venda" },
        { id: 173, name: "Vietnamese" },
        { id: 174, name: "Volapük" },
        { id: 175, name: "Walloon" },
        { id: 176, name: "Welsh" },
        { id: 177, name: "Wolof" },
        { id: 178, name: "Western Frisian" },
        { id: 179, name: "Xhosa" },
        { id: 180, name: "Yiddish" },
        { id: 181, name: "Yoruba" },
        { id: 182, name: "Zhuang, Chuang" }
    ]);

    const [yearList, setYearList] = useState([]);

    const getYearList = (startYear) => {
        var currentYear = new Date().getFullYear(), years = [];
        startYear = 1980;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        setYearList(years)
    }

    const [monthNames, setMonthNames] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [currentMonth, setCurrentMonth] = useState('January');
    const [currentYear, setCurrentYear] = useState(new Date());
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 1
        }
    ];

    const handleItemClick = (item) => {
        // Handle the clicked item here
        console.log('Clicked Item:', item);
        // setClickedItem(item);
        setCurrentMonth(item)
    };

    const carouselItems = (month) => {
        return (
            <div style={{
                background: currentMonth == month ? 'black' : 'pink',
                color: currentMonth == month ? 'white' : 'black',
                textAlign: "center",
                borderRadius: "6px",
                marginRight: "14px",
                fontSize: '500',
                cursor: 'pointer'
            }} onClick={() => handleItemClick(month)}>{month}</div>
        );
    };

    const { id } = useParams();

    const changeWorkExperienceHandler = (e) => {
        const { name, value } = e.target;
        setWorkExperience({
            ...workExperience,
            [name]: value,
        });
    };

    const changeEducationHandler = (e) => {
        const { name, value } = e.target;
        setEducations({
            ...educations,
            [name]: value,
        });
    };

    const changeCompanyDetailsHandler = (e) => {
        const { name, value } = e.target;
        setCompanyDetails({
            ...companyDetails,
            [name]: value,
        });
    };

    const changeCertificatesHandler = (e) => {
        const { name, value } = e.target;
        setCertificates({
            ...certificates,
            [name]: value,
        });
    };

    const changeDocumentsHandler = (e) => {
        const { name, value } = e.target;
        setDocuments({
            ...documents,
            [name]: value,
        });
    };

    useEffect(() => {
        if (!_.isUndefined(interviewerProfile) && !_.isNull(interviewerProfile) && _.isEmpty(interviewerProfile) && !_.isUndefined(id) && !_.isNull(id) && (id !== '')) {
            getCompanyProfile();
            getCompantUsers();
            getRalaAdminList();
            getPositions();
        }
    }, [interviewerProfile]);

    useEffect(() => {
        if (currentMonth && currentYear) {
            getCompantStates();
        }
    }, [currentMonth, currentYear])

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                const transformedOptions = response.data.map(option => ({
                    value: option.name,
                    label: option.name
                }));
                setDesignationOptions(transformedOptions);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const getCompantStates = () => {
        setIsLoading(true)
        const index = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/getCompantStates/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                currentMonth: (index + 1),
                currentYear: currentYear.getFullYear()
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setCompanyState([{
                    profile_added_count: response.data.profileAdded,
                    scheduled_interviews_count: response.data.scheduledInterviews,
                    selected_count: response.data.selected,
                    rejected_count: response.data.rejected,
                    proxy_count: response.data.proxy
                }])
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const getCompantUsers = () => {
        fetch(`${API_URL}api/getCompanyUser/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    setCompanyUsers(response.data)
                } else {
                    setCompanyUsers([])
                }
            })
            .catch((err) => {
                setCompanyUsers([]);
            });
    }

    const getCompanyProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/profileByID/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    setInterviewerProfile(response.data);
                    if (!_.isUndefined(response.data.city_id) && !_.isNull(response.data.city_id) && (response.data.city_id !== '')) {
                        getCityName(response.data.city_id);
                    }
                    if (!_.isUndefined(response.data.state_id) && !_.isNull(response.data.state_id) && (response.data.state_id !== '')) {
                        getStateName(response.data.state_id);
                    }
                    if (!_.isUndefined(response.data.country_id) && !_.isNull(response.data.country_id) && (response.data.country_id !== '')) {
                        getCountryName(response.data.country_id);
                    }

                    setProfileDetail({
                        email: response.data.email,
                        designation: response.data.designation,
                        mobile_number: response.data.mobile_number
                    })

                    setSelectedRalaAdmin(response.data.assigned_rala_sub_admin);

                    setCompanyDescription(response.data.company_description)

                    setCompanyDetails({
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        company_name: response.data.company_name,
                        gst_number: response.data.gst_number,
                        linkedin: response.data.linkedin
                    })
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerProfile({});
            });
    }

    const getCityName = (cityBYID) => {
        fetch(`${API_URL}api/cities/cityBYID/${cityBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCity(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const getStateName = (stateBYID) => {
        fetch(`${API_URL}api/states/stateBYID/${stateBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setState(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const getCountryName = (countryBYID) => {
        fetch(`${API_URL}api/countries/${countryBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCountry(response.data.name);
                }
            })
            .catch((err) => {
                setCountry({});
            });
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };

    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };

    const onRemoveLocationPreferences = (selectedList, removedItem) => {
        setLocationPreferences(selectedList);

    }
    const onSelectLocationPreferences = (selectedList, selectedItem) => {
        setLocationPreferences(selectedList);
    }

    const submitLocationPreferences = () => {
        if (locationPreferences.length > 0) {
            let params = {
                location_preferences: locationPreferences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });  
                    }
                });
        }
    }

    const onRemoveLanguages = (selectedList, removedItem) => {
        setLanguages(selectedList);
    }
    const onSelectLanguages = (selectedList, selectedItem) => {
        setLanguages(selectedList);
    }

    const submitLanguages = () => {
        if (languages.length > 0) {
            let params = {
                languages: languages
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });  
                    }
                });
        }
    }

    const onRemoveSkills = (selectedList, removedItem) => {
        setSkills(selectedList);
    }
    const onSelectSkills = (selectedList, selectedItem) => {
        setSkills(selectedList);
    }

    const submitSkills = () => {
        if (skills.length > 0) {
            let params = {
                skills: skills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const submitWorkExperience = () => {
        validateWorkExperienceForm();
        if (checkedValidateWorkExperienceForm()) {
            let workExperiences = interviewerProfile.work_experience;
            workExperiences.push(workExperience);
            let params = {
                work_experience: workExperiences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 }); 
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 }); 
                    }
                });
        }
    }
    const checkedValidateWorkExperienceForm = () => {
        if (_.isUndefined(workExperience.title) || _.isNull(workExperience.title) || (workExperience.title === '') || (workExperience.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.employment_type) || _.isNull(workExperience.employment_type) || (workExperience.employment_type === '') || (workExperience.employment_type.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.company_name) || _.isNull(workExperience.company_name) || (workExperience.company_name === '') || (workExperience.company_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.location) || _.isNull(workExperience.location) || (workExperience.location === '') || (workExperience.location.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.location_type) || _.isNull(workExperience.location_type) || (workExperience.location_type === '') || (workExperience.location_type.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.start_date_month) || _.isNull(workExperience.start_date_month) || (workExperience.start_date_month === '') || (workExperience.start_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.start_date_year) || _.isNull(workExperience.start_date_year) || (workExperience.start_date_year === '') || (workExperience.start_date_year.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.end_date_month) || _.isNull(workExperience.end_date_month) || (workExperience.end_date_month === '') || (workExperience.end_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(workExperience.end_date_year) || _.isNull(workExperience.end_date_year) || (workExperience.end_date_year === '') || (workExperience.end_date_year.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateWorkExperienceForm = () => {
        if (_.isUndefined(workExperience.title) || _.isNull(workExperience.title) || (workExperience.title === '') || (workExperience.title.trim() === '')) {
            setIsWorkExperienceTitleError("Experience title is required!");
        }
        else {
            setIsWorkExperienceTitleError("");
        }
        if (_.isUndefined(workExperience.employment_type) || _.isNull(workExperience.employment_type) || (workExperience.employment_type === '') || (workExperience.employment_type.trim() === '')) {
            setIsWorkExperienceEmploymentTypeError("Employment type is required!");
        }
        else {
            setIsWorkExperienceEmploymentTypeError("");
        }
        if (_.isUndefined(workExperience.company_name) || _.isNull(workExperience.company_name) || (workExperience.company_name === '') || (workExperience.company_name.trim() === '')) {
            setIsWorkExperienceCompanyNameError("Company name is required!");
        }
        else {
            setIsWorkExperienceCompanyNameError("");
        }
        if (_.isUndefined(workExperience.location) || _.isNull(workExperience.location) || (workExperience.location === '') || (workExperience.location.trim() === '')) {
            setIsWorkExperienceLocationError("Location is required!");
        }
        else {
            setIsWorkExperienceLocationError("");
        }
        if (_.isUndefined(workExperience.location_type) || _.isNull(workExperience.location_type) || (workExperience.location_type === '') || (workExperience.location_type.trim() === '')) {
            setIsWorkExperienceLocationTypeError("Location type is required!");
        }
        else {
            setIsWorkExperienceLocationTypeError("");
        }
        if (_.isUndefined(workExperience.start_date_month) || _.isNull(workExperience.start_date_month) || (workExperience.start_date_month === '') || (workExperience.start_date_month.trim() === '')) {
            setIsWorkExperienceStartDateMonthError("Start Date Month is required!");
        }
        else {
            setIsWorkExperienceStartDateMonthError("");
        }
        if (_.isUndefined(workExperience.start_date_year) || _.isNull(workExperience.start_date_year) || (workExperience.start_date_year === '') || (workExperience.start_date_year.trim() === '')) {
            setIsWorkExperienceStartDateYearError("Start Date Year is required!");
        }
        else {
            setIsWorkExperienceStartDateYearError("");
        }
        if (_.isUndefined(workExperience.end_date_month) || _.isNull(workExperience.end_date_month) || (workExperience.end_date_month === '') || (workExperience.end_date_month.trim() === '')) {
            setIsWorkExperienceEndDateMonthError("End Date Month is required!");
        }
        else {
            setIsWorkExperienceEndDateMonthError("");
        }
        if (_.isUndefined(workExperience.end_date_year) || _.isNull(workExperience.end_date_year) || (workExperience.end_date_year === '') || (workExperience.end_date_year.trim() === '')) {
            setIsWorkExperienceEndDateYearError("End Date Year is required!");
        }
        else {
            setIsWorkExperienceEndDateYearError("");
        }
    };

    const submitEducations = () => {
        validateEducationForm();
        if (checkedValidateEducationForm()) {
            let newEducations = interviewerProfile.educations;
            newEducations.push(educations);
            let params = {
                educations: newEducations
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 }); 
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 }); 
                    }
                });
        }
    }
    const checkedValidateEducationForm = () => {
        if (_.isUndefined(educations.school_name) || _.isNull(educations.school_name) || (educations.school_name === '') || (educations.school_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.grade) || _.isNull(educations.grade) || (educations.grade === '') || (educations.grade.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.location) || _.isNull(educations.location) || (educations.location === '') || (educations.location.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.start_date_month) || _.isNull(educations.start_date_month) || (educations.start_date_month === '') || (educations.start_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.start_date_year) || _.isNull(educations.start_date_year) || (educations.start_date_year === '') || (educations.start_date_year.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.end_date_month) || _.isNull(educations.end_date_month) || (educations.end_date_month === '') || (educations.end_date_month.trim() === '')) {
            return false;
        }
        if (_.isUndefined(educations.end_date_year) || _.isNull(educations.end_date_year) || (educations.end_date_year === '') || (educations.end_date_year.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateEducationForm = () => {
        if (_.isUndefined(educations.school_name) || _.isNull(educations.school_name) || (educations.school_name === '') || (educations.school_name.trim() === '')) {
            setIsEducationsSchoolNameError("School name is required!");
        }
        else {
            setIsEducationsSchoolNameError("");
        }
        if (_.isUndefined(educations.grade) || _.isNull(educations.grade) || (educations.grade === '') || (educations.grade.trim() === '')) {
            setIsEducationsGradeError("Grade is required!");
        }
        else {
            setIsEducationsGradeError("");
        }
        if (_.isUndefined(educations.location) || _.isNull(educations.location) || (educations.location === '') || (educations.location.trim() === '')) {
            setIsEducationsLocationError("Location is required!");
        }
        else {
            setIsEducationsLocationError("");
        }
        if (_.isUndefined(educations.start_date_month) || _.isNull(educations.start_date_month) || (educations.start_date_month === '') || (educations.start_date_month.trim() === '')) {
            setIsEducationsStartDateMonthError("Start Date Month is required!");
        }
        else {
            setIsEducationsStartDateMonthError("");
        }
        if (_.isUndefined(educations.start_date_year) || _.isNull(educations.start_date_year) || (educations.start_date_year === '') || (educations.start_date_year.trim() === '')) {
            setIsEducationsStartDateYearError("Start Date Year is required!");
        }
        else {
            setIsEducationsStartDateYearError("");
        }
        if (_.isUndefined(educations.end_date_month) || _.isNull(educations.end_date_month) || (educations.end_date_month === '') || (educations.end_date_month.trim() === '')) {
            setIsEducationsEndDateMonthError("End Date Month is required!");
        }
        else {
            setIsEducationsEndDateMonthError("");
        }
        if (_.isUndefined(educations.end_date_year) || _.isNull(educations.end_date_year) || (educations.end_date_year === '') || (educations.end_date_year.trim() === '')) {
            setIsEducationsEndDateYearError("End Date Year is required!");
        }
        else {
            setIsEducationsEndDateYearError("");
        }
    };

    const submitCertificates = () => {
        validateCertificatesForm();
        if (checkedValidateCertificatesForm()) {
            let newCertificates = interviewerProfile.certificates;
            newCertificates.push(certificates);
            let params = {
                certificates: newCertificates
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 }); 
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 }); 
                    }
                });
        }
    }
    const checkedValidateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            setIsCertificatesTitleError("Title is required!");
        }
        else {
            setIsCertificatesTitleError("");
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            setIsCertificatesFileError("Certificate File is required!");
        }
        else {
            setIsCertificatesFileError("");
        }
    };
    const changeUploadCertificatesHandler = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setCertificates({
                        ...certificates,
                        certificate_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 }); 
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
    }

    const submitDocuments = () => {
        validateDocumentsForm();
        if (checkedValidateDocumentsForm()) {
            let newDocuments = interviewerProfile.documents;
            newDocuments.push(documents);
            let params = {
                documents: newDocuments
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });  
                    }
                });
        }
    }
    const checkedValidateDocumentsForm = () => {
        if (_.isUndefined(documents.title) || _.isNull(documents.title) || (documents.title === '') || (documents.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(documents.document_file) || _.isNull(documents.document_file) || (documents.document_file === '') || (documents.document_file.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateDocumentsForm = () => {
        if (_.isUndefined(documents.title) || _.isNull(documents.title) || (documents.title === '') || (documents.title.trim() === '')) {
            setIsDocumentsTitleError("Title is required!");
        }
        else {
            setIsDocumentsTitleError("");
        }
        if (_.isUndefined(documents.document_file) || _.isNull(documents.document_file) || (documents.document_file === '') || (documents.document_file.trim() === '')) {
            setIsDocumentsFileError("Certificate File is required!");
        }
        else {
            setIsDocumentsFileError("");
        }
    };
    const submitCompanyDetails = () => {
        let params = {
            gst_number: companyDetails.gst_number,
            linkedin: companyDetails.linkedin,
            company_name: companyDetails.company_name,
            first_name: companyDetails.first_name,
            last_name: companyDetails.last_name,
            company_city: companyDetails.company_city
        }
        fetch(`${API_URL}api/updateProfileByID/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((response) => {
                setShowCompanyDetails(false);
                getCompanyProfile();
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
            })
            .catch((error) => {

            });
    }
    const checkedValidateBankDetailsForm = () => {
        if (_.isUndefined(bankDetails.bank_user_name) || _.isNull(bankDetails.bank_user_name) || (bankDetails.bank_user_name === '') || (bankDetails.bank_user_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_name) || _.isNull(bankDetails.bank_name) || (bankDetails.bank_name === '') || (bankDetails.bank_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_branch_name) || _.isNull(bankDetails.bank_branch_name) || (bankDetails.bank_branch_name === '') || (bankDetails.bank_branch_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_account_number) || _.isNull(bankDetails.bank_account_number) || (bankDetails.bank_account_number === '') || (bankDetails.bank_account_number.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_ifsc_code) || _.isNull(bankDetails.bank_ifsc_code) || (bankDetails.bank_ifsc_code === '') || (bankDetails.bank_ifsc_code.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_upi_id) || _.isNull(bankDetails.bank_upi_id) || (bankDetails.bank_upi_id === '') || (bankDetails.bank_upi_id.trim() === '')) {
            return false;
        }
        return true;
    };

    const changeUploadDocumentsHandler = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data,
            'Authorization': `Bearer ${userDetails.token}`,
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setDocuments({
                        ...documents,
                        document_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
    }

    const [companyState, setCompanyState] = useState([
        {
            profile_added_count: 0,
            scheduled_interviews_count: 0,
            selected_count: 0,
            rejected_count: 0,
            proxy_count: 0
        }
    ]);


    const [showAssignDialog, setShowAssignDialog] = useState(false);
    const [selectedRalaAdmin, setSelectedRalaAdmin] = useState(null);
    const [ralaAdminList, setRalaAdminList] = useState([]);

    const getRalaAdminList = () => {
        fetch(`${API_URL}api/ralaAdminUsers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    const users = [];
                    response.data.map((item) => {
                        users.push({
                            label: item.first_name + ' ' + item.last_name,
                            value: item.id
                        });
                    });
                    setRalaAdminList(users);
                }
            })
            .catch((err) => {
                setRalaAdminList([]);
            });
    }

    const assignModalfooterContent = (
        <div>
            <Button variant="primary" onClick={() => assignedRalaSubAdminToEmployeeSuperAdmin()} >Save</Button> {" "}{" "}
            <Button variant="outline-primary"  onClick={() => setShowAssignDialog(false)}>Cancel</Button>
        </div>
    );


    const assignedRalaSubAdminToEmployeeSuperAdmin = () => {
        let params = {
            assigned_rala_sub_admin: selectedRalaAdmin
        }
        fetch(`${API_URL}api/updateProfileByID/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Sub-admin successfully assigned', life: 3000 });
                setShowAssignDialog(false)
                getCompanyProfile();
            })
            .catch((error) => {
                setShowAssignDialog(false)
            });
    }

    const [isDetailEdit, setIsDetailEdit] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setProfileDetail({
            ...profileDetail,
            [e.target.name]: value,
        });
    }

    const updateProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/updateProfileByID/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(profileDetail)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                setIsDetailEdit(false);
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
                getCompanyProfile();
            })
            .catch((error) => {
                setIsLoading(false)
                setIsDetailEdit(false);
            });
    }


    const [selectedRole, setSelectedRole] = useState(null);
    const roles = [
        { label: `Admin`, value: EMPLOYER_SUPER_ADMIN },
        { label: `Recruiter`, value: EMPLOYER_SUB_ADMIN }
    ];

    const toast = useRef(null);
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [companyuser, setCompanyuser] = useState({
        company_id: id,
        first_name: "",
        last_name: "",
        email: "",
        account_type: "",
        mobile_number: "",
        designation: "",
        user_status: USER_STATUS_APPROVE
    });
    const changeCompanyUserHandler = (e, name) => {
        if (name == "mobile_number") {
            if ((e.value === "" || e.value === null || e.value.toString().length <= 10)) {
                setCompanyuser({
                    ...companyuser,
                    [name]: e.value
                });
            }
        } else {
            if (name == "designation") {
                const { value } = e;
                setCompanyuser({
                    ...companyuser,
                    [name]: value,
                });
            } else {
                const { value } = e.target;
                setCompanyuser({
                    ...companyuser,
                    [name]: value,
                });
            }
        }
    }

    const validateForm = () => {
        const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const textregex = /[A-Za-z]/;
        const numberregex = /[0-9]/;
        if (_.isUndefined(companyuser.first_name) || _.isNull(companyuser.first_name) || (companyuser.first_name === '') || (companyuser.first_name.trim() === '')) {
            setIsFirstNameError("First Name is required!");
        }
        else if (!_.isUndefined(companyuser.first_name) && !_.isNull(companyuser.first_name) && (companyuser.first_name !== '') && (companyuser.first_name.trim() !== '') && !textregex.test(companyuser.first_name)) {
            setIsFirstNameError("First Name is only allow alphabets!");
        }
        else if (!_.isUndefined(companyuser.first_name) && !_.isNull(companyuser.first_name) && (companyuser.first_name !== '') && (companyuser.first_name.trim() !== '') && ((companyuser.first_name.trim().length < 4) || (companyuser.first_name.trim().length > 50))) {
            setIsFirstNameError("First Name must be between 4 to 11 characters!");
        }
        else {
            setIsFirstNameError("");
        }
        if (_.isUndefined(companyuser.last_name) || _.isNull(companyuser.last_name) || (companyuser.last_name === '') || (companyuser.last_name.trim() === '')) {
            setIsLastNameError("Last Name is required!");
        }
        else if (!_.isUndefined(companyuser.last_name) && !_.isNull(companyuser.last_name) && (companyuser.last_name !== '') && (companyuser.last_name.trim() !== '') && !textregex.test(companyuser.last_name)) {
            setIsLastNameError("Last Name is only allow alphabets!");
        }
        else if (!_.isUndefined(companyuser.last_name) && !_.isNull(companyuser.last_name) && (companyuser.last_name !== '') && (companyuser.last_name.trim() !== '') && ((companyuser.last_name.trim().length < 4) || (companyuser.last_name.trim().length > 50))) {
            setIsLastNameError("Last Name must be between 4 to 11 characters!");
        }
        else {
            setIsLastNameError("");
        }
        if (_.isUndefined(companyuser.email) || _.isNull(companyuser.email) || (companyuser.email === '') || (companyuser.email.trim() === '')) {
            setIsEmailError("Email Address is required!");
        }
        else if (!_.isUndefined(companyuser.email) && !_.isNull(companyuser.email) && (companyuser.email !== '') && (companyuser.email.trim() !== '') && !emailregex.test(companyuser.email)) {
            setIsEmailError("Please enter a valid email address!");
        }
        else if (!_.isUndefined(companyuser.email) && !_.isNull(companyuser.email) && (companyuser.email !== '') && (companyuser.email.trim() !== '') && ((companyuser.email.trim().length < 15) || (companyuser.email.trim().length > 150))) {
            setIsEmailError("Email address must be between 15 to 150 characters!");
        }
        else {
            setIsEmailError("");
        }
        if (_.isUndefined(companyuser.mobile_number) || _.isNull(companyuser.mobile_number) || (companyuser.mobile_number === '') || (companyuser.mobile_number.toString().trim() === '')) {
            setIsMobileNumberError("Mobile Number is required!");
        }
        else if (!_.isUndefined(companyuser.mobile_number) && !_.isNull(companyuser.mobile_number) && (companyuser.mobile_number !== '') && (companyuser.mobile_number.toString().trim() !== '') && !numberregex.test(companyuser.mobile_number)) {
            setIsMobileNumberError("Mobile Number is only allow digit!");
        }
        else if (!_.isUndefined(companyuser.mobile_number) && !_.isNull(companyuser.mobile_number) && (companyuser.mobile_number !== '') && (companyuser.mobile_number.toString().trim() !== '') && ((companyuser.mobile_number.toString().trim().length < 10) || (companyuser.mobile_number.toString().trim().length > 10))) {
            setIsMobileNumberError("Mobile Number must be 10 digit!");
        }
        else {
            setIsMobileNumberError("");
        }
        if (_.isUndefined(companyuser.designation) || _.isNull(companyuser.designation) || (companyuser.designation === '') || (companyuser.designation.trim() === '')) {
            setIsDesignationError("Designation is required!");
        }
        else if (!_.isUndefined(companyuser.designation) && !_.isNull(companyuser.designation) && (companyuser.designation !== '') && (companyuser.designation.trim() !== '') && !textregex.test(companyuser.designation)) {
            setIsDesignationError("Designation is only allow alphabets!");
        }
        else if (!_.isUndefined(companyuser.designation) && !_.isNull(companyuser.designation) && (companyuser.designation !== '') && (companyuser.designation.trim() !== '') && ((companyuser.designation.trim().length < 4) || (companyuser.designation.trim().length > 50))) {
            setIsDesignationError("Designation must be between 4 to 11 characters!");
        }
        else {
            setIsDesignationError("");
        }
        if (_.isUndefined(companyuser.account_type) || _.isNull(companyuser.account_type) || (companyuser.account_type === '') || (companyuser.account_type.trim() === '')) {
            setIsRoleError("Role is required!");
        }
        else {
            setIsRoleError("");
        }
    };

    const checkedValidateForm = () => {
        const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const textregex = /[A-Za-z]/;
        const numberregex = /[0-9]/;
        if (_.isUndefined(companyuser.first_name) || _.isNull(companyuser.first_name) || (companyuser.first_name === '') || (companyuser.first_name.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(companyuser.first_name) && !_.isNull(companyuser.first_name) && (companyuser.first_name !== '') && (companyuser.first_name.trim() !== '') && !textregex.test(companyuser.first_name)) {
            return false;
        }
        else if (!_.isUndefined(companyuser.first_name) && !_.isNull(companyuser.first_name) && (companyuser.first_name !== '') && (companyuser.first_name.trim() !== '') && ((companyuser.first_name.trim().length < 4) || (companyuser.first_name.trim().length > 50))) {
            return false;
        }
        if (_.isUndefined(companyuser.last_name) || _.isNull(companyuser.last_name) || (companyuser.last_name === '') || (companyuser.last_name.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(companyuser.last_name) && !_.isNull(companyuser.last_name) && (companyuser.last_name !== '') && (companyuser.last_name.trim() !== '') && !textregex.test(companyuser.last_name)) {
            return false;
        }
        else if (!_.isUndefined(companyuser.last_name) && !_.isNull(companyuser.last_name) && (companyuser.last_name !== '') && (companyuser.last_name.trim() !== '') && ((companyuser.last_name.trim().length < 4) || (companyuser.last_name.trim().length > 50))) {
            return false;
        }
        if (_.isUndefined(companyuser.email) || _.isNull(companyuser.email) || (companyuser.email === '') || (companyuser.email.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(companyuser.email) && !_.isNull(companyuser.email) && (companyuser.email !== '') && (companyuser.email.trim() !== '') && !emailregex.test(companyuser.email)) {
            return false;
        }
        else if (!_.isUndefined(companyuser.email) && !_.isNull(companyuser.email) && (companyuser.email !== '') && (companyuser.email.trim() !== '') && ((companyuser.email.trim().length < 15) || (companyuser.email.trim().length > 150))) {
            return false;
        }
        if (_.isUndefined(companyuser.mobile_number) || _.isNull(companyuser.mobile_number) || (companyuser.mobile_number === '') || (companyuser.mobile_number.toString().trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(companyuser.mobile_number) && !_.isNull(companyuser.mobile_number) && (companyuser.mobile_number !== '') && (companyuser.mobile_number.toString().trim() !== '') && !numberregex.test(companyuser.mobile_number)) {
            return false;
        }
        else if (!_.isUndefined(companyuser.mobile_number) && !_.isNull(companyuser.mobile_number) && (companyuser.mobile_number !== '') && (companyuser.mobile_number.toString().trim() !== '') && ((companyuser.mobile_number.toString().trim().length < 10) || (companyuser.mobile_number.toString().trim().length > 10))) {
            return false;
        }
        if (_.isUndefined(companyuser.designation) || _.isNull(companyuser.designation) || (companyuser.designation === '') || (companyuser.designation.trim() === '')) {
            return false;
        }
        else if (!_.isUndefined(companyuser.designation) && !_.isNull(companyuser.designation) && (companyuser.designation !== '') && (companyuser.designation.trim() !== '') && !textregex.test(companyuser.designation)) {
            return false;
        }
        else if (!_.isUndefined(companyuser.designation) && !_.isNull(companyuser.designation) && (companyuser.designation !== '') && (companyuser.designation.trim() !== '') && ((companyuser.designation.trim().length < 4) || (companyuser.designation.trim().length > 50))) {
            return false;
        }
        if (_.isUndefined(companyuser.account_type) || _.isNull(companyuser.account_type) || (companyuser.account_type === '') || (companyuser.account_type.trim() === '')) {
            return false;
        }
        return true;
    };

    const addCompanyUser = () => {
        validateForm();
        if (checkedValidateForm()) {
            setIsLoading(true)
            fetch(`${API_URL}api/addCompanyUser`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(companyuser)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status == 200) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowAddUserDialog(false);
                        setCompanyuser({
                            company_id: id,
                            first_name: "",
                            last_name: "",
                            email: "",
                            account_type: "",
                            mobile_number: ""
                        });
                        getCompantUsers();
                        getCompantStates();
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                });
        }
    }

    const resetAddCompanyUserForm = () => {
        setCompanyuser({
            company_id: id,
            first_name: "",
            last_name: "",
            email: "",
            account_type: "",
            mobile_number: ""
        });
        setShowAddUserDialog(false)
    }

    const removeCompanyUser = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/removeCompanyUser/${selectedCompanyUserId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    getCompantUsers();
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
                setRemoveUserConfirmDialogShow(false)
                setIsLoading(false)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setRemoveUserConfirmDialogShow(false)
                setIsLoading(false)
            });
    }

    const updateCompanyUser = (body, userId) => {
        setIsLoading(true)
        fetch(`${API_URL}api/updateCompanyUser/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    getCompantUsers();
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const fileUploadRef = useRef(null);
    const [resumeFileName, setResumeFileName] = useState(null);

    const companyAccountUpdate = async (body) => {
        setIsLoading(true)
        fetch(`${API_URL}api/updatedCompanyAccount/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Profile updated successfully.", life: 3000 });
                getCompanyProfile();
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log("error", err);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const uploadFileHandler = async (event) => {
        if (allowedImageTypes.includes(event.target.files[0].type)) {
            setResumeFileName(event.target.files[0].name)
            setIsLoading(true)
            const file = event.target.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
            .then((response) => response.json())
            .then((response) => {
                profileDetail.profile_image = response.filepath;
                clearFile(fileUploadRef)
                const event = new CustomEvent('profileImageUpdated', {
                    detail: { profileImage: response.filepath  }
                });
                window.dispatchEvent(event);

                updateProfile();
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("error", err);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                clearFile(fileUploadRef)
                setIsLoading(false)
            });
        } else {
            clearFile(fileUploadRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: imageFileTypeErrorMessage, life: 3000 });
        }
    };

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
        setResumeFileName(null)
    }

    const [removeUserConfirmDialogShow, setRemoveUserConfirmDialogShow] = useState(false);
    const [selectedCompanyUserId, setSelectedCompanyUserId] = useState(null);

    const removeConfirmModalfooterContent = (
        <div>
            <Button  variant="primary"  onClick={() => removeCompanyUser()} >Yes</Button> {" "}{" "}
            <Button variant="outline-primary" onClick={() => setRemoveUserConfirmDialogShow(false)}>Cancel</Button>
        </div>
    );

    const [showCompanyReportModal, setShowCompanyReportModal] = useState(false);
    const [csvContent, setCsvContent] = useState('');

    const compantConvertToCsv = (data) => {
        const header = "Candidate Name, Candidate Number, Candidate E-mail, Title, Company Name, Scheduled Date, Schedule By, Rating, Verdict, Location, Amount, Final amount, Interviewer Source";
        const rowData = [];
        data.map((item)  => {
            const datum = {
                "candidate_name": item.candidate_name,
                "candidate_mobile_number": item.candidate_mobile_number,
                "interview_email": item.interview_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "schedule_date": item.scheduled_on ? commonDateFormat(item.scheduled_on) : "",
                "schedule_by": item.coordinator ? item.coordinator.first_name : "" ,
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "location": item.job_location,
                "amount": 250,
                "final_amount": item.qc_status == QC_STATUS_FAIL ? 0 : 250,
                "interviewer_source": "Rala Infotech"
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };

    const downloadCompanyReport = (compaantId, type = 'download') => {
        const monthIndex = monthNames.indexOf(currentMonth);
        setIsLoading(true)
        fetch(`${API_URL}api/getCompanyReport/${compaantId}?currentMonth=${monthIndex + 1}&currentYear=${currentYear.getFullYear()}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (type === 'download') {
                    const interviewReportData = response.data;
                    const csvContent = compantConvertToCsv(interviewReportData);
                    const blob = new Blob([csvContent], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'table_data.csv';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);


                    toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report downloaded successfully", life: 3000 });
                } else if (type === 'view') {
                    const interviewReportData = response.data;
                    setCsvContent(interviewReportData);
                    setShowCompanyReportModal(true);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }
    const sendCompanyReport = (companyId) => {
        setIsLoading(true)
        fetch(`${API_URL}api/sendCompanyReport/${companyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const selectChangeHandler = (e, name) => {
        const { value } = e;
        setProfileDetail({
            ...profileDetail,
            [name]: value,
        });
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row mx-md-5">
                    <div className="col-12 employer-dashboard-title">
                        <div className="row">
                            <div className="col-12 pageTitle_fixed">
                                <p className="employer-dashboard-jobs-posted mb-0">Settings / Company / {interviewerProfile.company_name}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12 interviewer-profile-details-left">
                                <Card>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <img className="profile-image" src={!_.isUndefined(interviewerProfile.profile_image) && !_.isNull(interviewerProfile.profile_image) && (interviewerProfile.profile_image !== '') ? interviewerProfile.profile_image : default_profile_picture} alt="Profile" />
                                                {
                                                    userDetails && userDetails.id === interviewerProfile.id && (

                                                        <>
                                                            <input style={{display: 'none'}} type="file" id="upload_profile" name="upload_profile" onChange={uploadFileHandler} placeholder="Profile Photo" multiple ref={fileUploadRef} />
                                                            <div className="file-upload-container mt-2" onClick={() => fileUploadRef.current.click()}>
                                                                <div className="custom-file-upload">
                                                                    Choose file
                                                                </div>
                                                                <div className="file-name">{resumeFileName ?? "No file chosen"}</div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-12">
                                                <b>PROFILE</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email*</Form.Label>
                                                    {
                                                        isDetailEdit ?
                                                            (
                                                                <Form.Control
                                                                    value={profileDetail.email}
                                                                    type="text"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    onChange={changeHandler}
                                                                    maxLength={150}
                                                                    autoComplete="off"
                                                                />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.email}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Registration ID</label>
                                                <p className="interviewer-profile-details-value">{interviewerProfile.id}</p>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobile Number*</Form.Label>
                                                    {
                                                        isDetailEdit && userDetails.account_type === SUPER_ADMIN ?
                                                            (
                                                                <Form.Control value={profileDetail ? profileDetail.mobile_number : ""} type="text"  id="mobile_number" name="mobile_number" placeholder="Mobile Number" onChange={changeHandler} maxLength={10} autoComplete="off" />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{interviewerProfile.mobile_number}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Designation*</Form.Label>
                                                    {
                                                        isDetailEdit ?
                                                            (
                                                                <Select
                                                                    options={designationOptions}
                                                                    defaultValue={profileDetail ? {label: profileDetail.designation, value: profileDetail.designation} : ""}
                                                                    onChange={(e) => selectChangeHandler(e, 'designation')}
                                                                    id="designation"
                                                                    name="designation"
                                                                    placeholder="Choose Designation"
                                                                    isSearchable={true}
                                                                    className='form-control designtn'
                                                                />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.designation}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Sign Up Date</label>
                                                <p className="interviewer-profile-details-value">{commonDateFormat(interviewerProfile.createdAt)}</p>
                                            </div>

                                            <div className="col-12">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                        isDetailEdit ? (
                                                            <a href="JavaScript:;" onClick={() => updateProfile()} className="btn btn-primary">Save</a>
                                                        ) : (
                                                            <a href="JavaScript:;" onClick={() => setIsDetailEdit(true)} className="btn btn-outline-primary">Edit</a>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-9 col-12 interviewer-profile-details-right">
                                <Tabs defaultActiveKey="InterviewerProfile" className="mb-2">
                                    <Tab eventKey="InterviewerProfile" title="Company Profile">
                                        <Card>
                                            <Card.Body>
                                                <div className="row px-5">
                                                    <div className="col-12 px-0">
                                                        <b className="interviewer-profile-details-right-name">{interviewerProfile.first_name + " " + interviewerProfile.last_name} <span className="interviewer-profile-details-right-location"><IoLocationOutline size={16} />{city + ", " + state + ", " + country}</span></b>
                                                        {
                                                            userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                <a style={{ float: 'right' }} href="JavaScript:;" onClick={() => setShowAssignDialog(true)} className="btn btn-outline-primary">Share</a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row px-5">
                                                    <div className="col-12 col-md-3 px-0">
                                                        <label className="interviewer-profile-details-right-label">GST number</label>
                                                        <p className="interviewer-profile-details-right-value">{interviewerProfile.gst_number}</p>
                                                    </div>
                                                    <div className="col-12 col-md-3 px-0">
                                                        <label className="interviewer-profile-details-right-label">Linkedin</label>
                                                        <p className="interviewer-profile-details-right-value">{interviewerProfile.linkedin}</p>
                                                    </div>
                                                    <div className="col-12 col-md-3 px-0">
                                                        <label className="interviewer-profile-details-right-label">First name</label>
                                                        <p className="interviewer-profile-details-right-value">{interviewerProfile.first_name}</p>
                                                    </div>
                                                    <div className="col-12 col-md-3 px-0">
                                                        <label className="interviewer-profile-details-right-label">Last name</label>
                                                        <p className="interviewer-profile-details-right-value">
                                                            {interviewerProfile.last_name}
                                                            {
                                                                userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                                    <a style={{ float: 'right' }} href="JavaScript:;" onClick={() => setShowCompanyDetails(true)} className="interviewer-profile-details-button">Edit</a>
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    userDetails && userDetails.account_type === EMPLOYER_SUPER_ADMIN && (
                                                        <div className="row">
                                                            <div className="col-12 px-5">
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <div className="row px-5">
                                                    {
                                                        userDetails && userDetails.account_type === EMPLOYER_SUPER_ADMIN && (
                                                            <>
                                                                <label className="interviewer-profile-details-right-label px-0 pb-2">Company Description </label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    className="pb-2"
                                                                    placeholder="Minimum 100 characters"
                                                                    value={companyDescription ?? ""}
                                                                    style={{ height: '100px', padding: '10px' }}
                                                                    onChange={(e) => {
                                                                        setCompanyDescription(e.target.value)
                                                                    }}
                                                                />
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>Update company description</Tooltip>}
                                                                >
                                                                    <Button variant="primary"  className="mt-2" style={{width:'80px'}}
                                                                        onClick={() => {
                                                                            companyAccountUpdate(
                                                                                {
                                                                                    company_description: companyDescription
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </>
                                                        )
                                                    }





                                                </div>
                                                <div className="row">
                                                    <div className="col-12 px-5">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.company_id === "") && (
                                                            <div className="px-5" style={{ textAlign: 'right', marginBottom: '10px' }}>
                                                                <a style={{ float: 'right' }} href="JavaScript:;" className="btn btn-primary" onClick={() => setShowAddUserDialog(true)} > Add </a>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="col-12 px-5">
                                                        {
                                                            userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.company_id === "") && (
                                                                <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                                    <thead className='sticky-top'>
                                                                        <tr>
                                                                            <th className="text-start">First Name</th>
                                                                            <th className="text-start">Last Name</th>
                                                                            <th className="text-start">Email ID</th>
                                                                            <th className="text-start">Mobile number</th>
                                                                            <th className="text-start">Designation/Address</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            !_.isUndefined(compantUsers) && !_.isNull(compantUsers) &&
                                                                            _.map(compantUsers, (companyUser, index) => {
                                                                                return <tr key={"feednback-completed-company-" + index}>
                                                                                    <td className="text-start">{companyUser.first_name}</td>
                                                                                    <td className="text-start">{companyUser.last_name}</td>
                                                                                    <td className="text-start">{companyUser.email}</td>
                                                                                    <td className="text-start">{companyUser.mobile_number}</td>
                                                                                    <td className="text-center">
                                                                                        {/* <ToggleButton
                                                                                            checked={companyUser.account_type == EMPLOYER_SUPER_ADMIN}
                                                                                            onChange={() => {
                                                                                                updateCompanyUser({
                                                                                                    account_type: companyUser.account_type == EMPLOYER_SUPER_ADMIN ? EMPLOYER_SUB_ADMIN : EMPLOYER_SUPER_ADMIN
                                                                                                }, companyUser.id);
                                                                                            }}
                                                                                            onIcon="pi pi-user"
                                                                                            offIcon="pi pi-user-plus"
                                                                                            onLabel="Admin"
                                                                                            offLabel="Recruiter"
                                                                                            style={{ backgroundColor: 'blue', color: 'white' }}
                                                                                        /> */}

                                                                                        <Form.Select style={{width: '100%'}} onChange={(e) => {
                                                                                            updateCompanyUser({
                                                                                                account_type: e.target.value
                                                                                            }, companyUser.id);
                                                                                        }}>
                                                                                            {
                                                                                                roles.map((item) => {
                                                                                                    return <option selected={companyUser.account_type == item.value} value={item.value}>{item.label}</option>
                                                                                                })
                                                                                            }
                                                                                        </Form.Select>
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <Button variant="outline-primary" onClick={() => {
                                                                                            setSelectedCompanyUserId(companyUser.id);
                                                                                            setRemoveUserConfirmDialogShow(true);
                                                                                        }}>
                                                                                            Remove
                                                                                        </Button>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    {
                                        userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                            <Tab eventKey="InterviewerStats" title="Company Stats">
                                                <div className="row">

                                                    <div className="col-12 col-md-6 col-lg-2" style={{ fontSize: '14px' }}>
                                                        View by Month
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="carousel-demo">
                                                            <Carousel value={monthNames} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular={true}
                                                                autoplayInterval={60000} itemTemplate={carouselItems} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-2">
                                                        <DatePicker showYearPicker onChange={setCurrentYear} selected={currentYear} maxDate={new Date()} className="form-control" dateFormat="yyyy" />
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4">
                                                    <div className='d-flex mt-2'>
                                                        <div className='input_grp_buttons' style={{  width: '100%' }}>
                                                            <Container>
                                                                <Row>
                                                                    <Col className="col-2 btn btn-outline-primary" onClick={() => downloadCompanyReport(id, 'view')} md={2} style={{ borderRight: '2px solid white' }}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>See</Tooltip>}
                                                                        >
                                                                            <div ><FaEye className="cursor-pointer"/></div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                    <Col className="col-2 btn btn-outline-primary" onClick={() => downloadCompanyReport(id, 'download')} md={2} style={{ borderRight: '2px solid white' }}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Download</Tooltip>}
                                                                        >
                                                                            <div ><FaDownload className="cursor-pointer"/></div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                    <Col className="col-2 btn btn-outline-primary" onClick={() => sendCompanyReport(id)} md={2} style={{ borderRight: '2px solid white' }}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Send</Tooltip>}
                                                                        >
                                                                            <div ><BsFillSendFill className="cursor-pointer"/></div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                    <Col className="col-6 btn btn-outline-primary" md={6}>
                                                                        <div style={{textWrap:'nowrap'}}>Payment Report</div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </div>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th>Profile Added</th>
                                                            <th>Scheduled Interview</th>
                                                            <th>Selected</th>
                                                            <th>Rejected</th>
                                                            <th>Proxy</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !_.isUndefined(companyState) && !_.isNull(companyState) &&
                                                            _.map(companyState, (item, index) => {
                                                                return <tr key={"interview-list-" + index}>
                                                                    <td className="text-center">{item.profile_added_count}</td>
                                                                    <td className="text-center">{item.scheduled_interviews_count}</td>
                                                                    <td className="text-center">{item.selected_count}</td>
                                                                    <td className="text-center">{item.rejected_count}</td>
                                                                    <td className="text-center">{item.proxy_count}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                </div>
                                                </div>
                                            </Tab>
                                        )
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showLocationPreferences &&
                <Modal show={showLocationPreferences} onHide={() => setShowLocationPreferences(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Location Preferences</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Multiselect
                            options={cities} // Options to display in the dropdown
                            selectedValues={interviewerProfile.location_preferences} // Preselected value to persist in dropdown
                            onSelect={onSelectLocationPreferences} // Function will trigger on select event
                            onRemove={onRemoveLocationPreferences} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowLocationPreferences(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitLocationPreferences()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showLanguages &&
                <Modal show={showLanguages} onHide={() => setShowLanguages(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Language</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Multiselect
                            options={languageOptions} // Options to display in the dropdown
                            selectedValues={interviewerProfile.languages} // Preselected value to persist in dropdown
                            onSelect={onSelectLanguages} // Function will trigger on select event
                            onRemove={onRemoveLanguages} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowLanguages(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitLanguages()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showSkills &&
                <Modal show={showSkills} onHide={() => setShowSkills(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Skill</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Multiselect
                            options={skillsOptions} // Options to display in the dropdown
                            selectedValues={interviewerProfile.skills} // Preselected value to persist in dropdown
                            onSelect={onSelectSkills} // Function will trigger on select event
                            onRemove={onRemoveSkills} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSkills(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitSkills()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showWorkExperience &&
                <Modal show={showWorkExperience} onHide={() => setShowWorkExperience(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add experience</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title" id="title" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceTitleError) && !_.isNull(workExperienceTitleError) && (workExperienceTitleError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Employment type</Form.Label>
                            <Form.Select aria-label="Employment type" name="employment_type" id="employment_type" onChange={changeWorkExperienceHandler}>
                                <option>Please select</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                                <option value="Self-employed">Self-employed</option>
                                <option value="Freelance">Freelance</option>
                                <option value="Internship">Internship</option>
                                <option value="Trainee">Trainee</option>
                            </Form.Select>
                            {
                                !_.isUndefined(workExperienceEmploymentTypeError) && !_.isNull(workExperienceEmploymentTypeError) && (workExperienceEmploymentTypeError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceEmploymentTypeError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Company name</Form.Label>
                            <Form.Control type="text" placeholder="Company name" name="company_name" id="company_name" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceCompanyNameError) && !_.isNull(workExperienceCompanyNameError) && (workExperienceCompanyNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceCompanyNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Location" name="location" id="location" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceLocationError) && !_.isNull(workExperienceLocationError) && (workExperienceLocationError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceLocationError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location type</Form.Label>
                            <Form.Select aria-label="Location type" name="location_type" id="location_type" onChange={changeWorkExperienceHandler}>
                                <option>Please select</option>
                                <option value="On-site">On-site</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="Remote">Remote</option>
                            </Form.Select>
                            {
                                !_.isUndefined(workExperienceLocationTypeError) && !_.isNull(workExperienceLocationTypeError) && (workExperienceLocationTypeError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceLocationTypeError}</Alert>
                            }
                        </Form.Group>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>Start Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Month" name="start_date_month" id="start_date_month" onChange={changeWorkExperienceHandler}>
                                    <option>Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceStartDateMonthError) && !_.isNull(workExperienceStartDateMonthError) && (workExperienceStartDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceStartDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Year" name="start_date_year" id="start_date_year" onChange={changeWorkExperienceHandler}>
                                    <option>Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                    <option value="2013">2013</option>
                                    <option value="2012">2012</option>
                                    <option value="2011">2011</option>
                                    <option value="2010">2010</option>
                                    <option value="2009">2009</option>
                                    <option value="2008">2008</option>
                                    <option value="2007">2007</option>
                                    <option value="2006">2006</option>
                                    <option value="2005">2005</option>
                                    <option value="2004">2004</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                    <option value="1929">1929</option>
                                    <option value="1928">1928</option>
                                    <option value="1927">1927</option>
                                    <option value="1926">1926</option>
                                    <option value="1925">1925</option>
                                    <option value="1924">1924</option>
                                    <option value="1923">1923</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceStartDateYearError) && !_.isNull(workExperienceStartDateYearError) && (workExperienceStartDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceStartDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>End Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Month" name="end_date_month" id="end_date_month" onChange={changeWorkExperienceHandler}>
                                    <option>Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceEndDateMonthError) && !_.isNull(workExperienceEndDateMonthError) && (workExperienceEndDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceEndDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Year" name="end_date_year" id="end_date_year" onChange={changeWorkExperienceHandler}>
                                    <option>Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                    <option value="2013">2013</option>
                                    <option value="2012">2012</option>
                                    <option value="2011">2011</option>
                                    <option value="2010">2010</option>
                                    <option value="2009">2009</option>
                                    <option value="2008">2008</option>
                                    <option value="2007">2007</option>
                                    <option value="2006">2006</option>
                                    <option value="2005">2005</option>
                                    <option value="2004">2004</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                    <option value="1929">1929</option>
                                    <option value="1928">1928</option>
                                    <option value="1927">1927</option>
                                    <option value="1926">1926</option>
                                    <option value="1925">1925</option>
                                    <option value="1924">1924</option>
                                    <option value="1923">1923</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceEndDateYearError) && !_.isNull(workExperienceEndDateYearError) && (workExperienceEndDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceEndDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <Form.Group className="mb-2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} aria-label="Description" name="description" id="description" onChange={changeWorkExperienceHandler} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowWorkExperience(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitWorkExperience()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showEducations &&
                <Modal show={showEducations} onHide={() => setShowEducations(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add education</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>School/College</Form.Label>
                            <Form.Control type="text" placeholder="School" name="school_name" id="school_name" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationSchoolNameError) && !_.isNull(educationSchoolNameError) && (educationSchoolNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationSchoolNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Grade</Form.Label>
                            <Form.Control type="text" placeholder="Grade" name="grade" id="grade" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationGradeError) && !_.isNull(educationGradeError) && (educationGradeError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationGradeError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Location" name="location" id="location" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationLocationError) && !_.isNull(educationLocationError) && (educationLocationError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationLocationError}</Alert>
                            }
                        </Form.Group>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>Start Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Month" name="start_date_month" id="start_date_month" onChange={changeEducationHandler}>
                                    <option>Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(educationStartDateMonthError) && !_.isNull(educationStartDateMonthError) && (educationStartDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationStartDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Year" name="start_date_year" id="start_date_year" onChange={changeEducationHandler}>
                                    <option>Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                    <option value="2013">2013</option>
                                    <option value="2012">2012</option>
                                    <option value="2011">2011</option>
                                    <option value="2010">2010</option>
                                    <option value="2009">2009</option>
                                    <option value="2008">2008</option>
                                    <option value="2007">2007</option>
                                    <option value="2006">2006</option>
                                    <option value="2005">2005</option>
                                    <option value="2004">2004</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                    <option value="1929">1929</option>
                                    <option value="1928">1928</option>
                                    <option value="1927">1927</option>
                                    <option value="1926">1926</option>
                                    <option value="1925">1925</option>
                                    <option value="1924">1924</option>
                                    <option value="1923">1923</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(educationStartDateYearError) && !_.isNull(educationStartDateYearError) && (educationStartDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationStartDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>End Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Month" name="end_date_month" id="end_date_month" onChange={changeEducationHandler}>
                                    <option>Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(educationEndDateMonthError) && !_.isNull(educationEndDateMonthError) && (educationEndDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationEndDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Year" name="end_date_year" id="end_date_year" onChange={changeEducationHandler}>
                                    <option>Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                    <option value="2013">2013</option>
                                    <option value="2012">2012</option>
                                    <option value="2011">2011</option>
                                    <option value="2010">2010</option>
                                    <option value="2009">2009</option>
                                    <option value="2008">2008</option>
                                    <option value="2007">2007</option>
                                    <option value="2006">2006</option>
                                    <option value="2005">2005</option>
                                    <option value="2004">2004</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                    <option value="1929">1929</option>
                                    <option value="1928">1928</option>
                                    <option value="1927">1927</option>
                                    <option value="1926">1926</option>
                                    <option value="1925">1925</option>
                                    <option value="1924">1924</option>
                                    <option value="1923">1923</option>
                                </Form.Select>
                                {
                                    !_.isUndefined(educationEndDateYearError) && !_.isNull(educationEndDateYearError) && (educationEndDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationEndDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <Form.Group className="mb-2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} aria-label="Description" name="description" id="description" onChange={changeEducationHandler} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEducations(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitEducations()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showCertificates &&
                <Modal show={showCertificates} onHide={() => setShowCertificates(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Certificates</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title" id="title" onChange={changeCertificatesHandler} />
                            {
                                !_.isUndefined(certificatesTitleError) && !_.isNull(certificatesTitleError) && (certificatesTitleError !== '') &&
                                <Alert variant="danger" className="mt-2">{certificatesTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>File</Form.Label>
                            <Form.Control type="file" placeholder="Certificate" name="certificate_file" id="certificate_file" onChange={(event) => changeUploadCertificatesHandler(event)} />
                            {
                                !_.isUndefined(certificatesFileError) && !_.isNull(certificatesFileError) && (certificatesFileError !== '') &&
                                <Alert variant="danger" className="mt-2">{certificatesFileError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCertificates(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitCertificates()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showDocuments &&
                <Modal show={showDocuments} onHide={() => setShowDocuments(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title" id="title" onChange={changeDocumentsHandler} />
                            {
                                !_.isUndefined(documentsTitleError) && !_.isNull(documentsTitleError) && (documentsTitleError !== '') &&
                                <Alert variant="danger" className="mt-2">{documentsTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Documents</Form.Label>
                            <Form.Control type="file" placeholder="Documents" name="documents_file" id="documents_file" onChange={(event) => changeUploadDocumentsHandler(event)} />
                            {
                                !_.isUndefined(documentsFileError) && !_.isNull(documentsFileError) && (documentsFileError !== '') &&
                                <Alert variant="danger" className="mt-2">{documentsFileError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDocuments(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitDocuments()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showCompanyDetails &&
                <Modal centered show={showCompanyDetails} onHide={() => setShowCompanyDetails(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Company Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>GST Number</Form.Label>
                            <Form.Control value={companyDetails.gst_number} type="text" placeholder="GST Number" name="gst_number" id="gst_number" onChange={changeCompanyDetailsHandler} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Linkedin</Form.Label>
                            <Form.Control value={companyDetails.linkedin} type="text" placeholder="Linkedin" name="linkedin" id="linkedin" onChange={changeCompanyDetailsHandler} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>First name</Form.Label>
                            <Form.Control value={companyDetails.first_name} type="text" placeholder="First name" name="first_name" id="first_name" onChange={changeCompanyDetailsHandler} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control value={companyDetails.last_name} type="text" placeholder="Last name" name="last_name" id="last_name" onChange={changeCompanyDetailsHandler} />
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control value={companyDetails.company_name} type="text" placeholder="Company name" name="company_name" id="company_name" onChange={changeCompanyDetailsHandler} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowCompanyDetails(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitCompanyDetails()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }

            <Dialog header="Assign" visible={showAssignDialog} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={assignModalfooterContent} onHide={() => setShowAssignDialog(false)} dismissableMask="true">


                <Dropdown value={selectedRalaAdmin} onChange={(e) => setSelectedRalaAdmin(e.value)} options={ralaAdminList}
                    placeholder="Assign" className="w-full" style={{
                        width: '300px'
                    }} />

            </Dialog>


            <Dialog header="Add user" visible={showAddUserDialog} style={{ width: '50vw' }} onHide={() => resetAddCompanyUserForm()} dismissableMask="true">
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="first_name" className="p-sr-only">First Name</label>
                    <InputText id="first_name" name="first_name" placeholder="First name" className="mr-2" style={{ width: '100%' }} onChange={(e) => changeCompanyUserHandler(e, 'first_name')} />
                    {
                        !_.isUndefined(firstNameError) && !_.isNull(firstNameError) && (firstNameError !== '') &&
                        <Alert variant="danger" className="mt-2">{firstNameError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="last_name" className="p-sr-only">Last Name</label>
                    <InputText id="last_name" name="last_name" placeholder="Last name" className="mr-2" style={{ width: '100%' }} onChange={(e) => changeCompanyUserHandler(e, 'last_name')} />
                    {
                        !_.isUndefined(lastNameError) && !_.isNull(lastNameError) && (lastNameError !== '') &&
                        <Alert variant="danger" className="mt-2">{lastNameError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="email" className="p-sr-only">Email</label>
                    <InputText id="email" name="email" placeholder="Email" className="mr-2" style={{ width: '100%' }} onChange={(e) => changeCompanyUserHandler(e, 'email')} />
                    {
                        !_.isUndefined(emailError) && !_.isNull(emailError) && (emailError !== '') &&
                        <Alert variant="danger" className="mt-2">{emailError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="mobile_number" className="p-sr-only">Mobile Number</label>
                    <InputNumber useGrouping={false} maxLength={10} id="mobile_number" name="mobile_number" placeholder="Mobile Number" className="mr-2" style={{ width: '100%' }} onChange={(e) => changeCompanyUserHandler(e, 'mobile_number')} />
                    {
                        !_.isUndefined(mobileNumberError) && !_.isNull(mobileNumberError) && (mobileNumberError !== '') &&
                        <Alert variant="danger" className="mt-2">{mobileNumberError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="mobile_number" className="p-sr-only">Designation</label>
                    <Select
                        options={designationOptions}
                        onChange={(e) => changeCompanyUserHandler(e, 'designation')}
                        placeholder="Choose Designation"
                        isSearchable={true}
                    />
                    {
                        !_.isUndefined(designationError) && !_.isNull(designationError) && (designationError !== '') &&
                        <Alert variant="danger" className="mt-2">{designationError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <label htmlFor="account_type" className="p-sr-only">Roles</label>
                    <Form.Select style={{width: '100%'}} onChange={(e) => changeCompanyUserHandler(e, 'account_type')}>
                        <option value="">Select Role</option>
                        {
                            roles.map((item) => {
                                return <option value={item.value}>{item.label}</option>
                            })
                        }
                    </Form.Select>
                    {
                        !_.isUndefined(roleError) && !_.isNull(roleError) && (roleError !== '') &&
                        <Alert variant="danger" className="mt-2">{roleError}</Alert>
                    }
                </div>
                <div className="flex flex-wrap align-items-center mb-3 gap-2">
                    <Button type="submit" icon="pi pi-check" className="p-button-outlined" onClick={addCompanyUser} >Save</Button>
                </div>
            </Dialog>


            <Dialog header={"Remove this member?"} visible={removeUserConfirmDialogShow} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={removeConfirmModalfooterContent} onHide={() => setRemoveUserConfirmDialogShow(false)} dismissableMask="true"></Dialog>


            <Modal show={showCompanyReportModal} onHide={() => setShowCompanyReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                        <thead className='sticky-top'>
                            <tr>
                                <th>Candidate Name</th>
                                <th>Candidate Number</th>
                                <th>Interviewer E-mail</th>
                                <th>Title</th>
                                <th>Company Name</th>
                                <th>Schedule Date</th>
                                <th>Scheduled By</th>
                                <th>Rating</th>
                                <th>Verdit</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>Final Amount</th>
                                <th>Interviewer Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                csvContent && csvContent.length > 0 && (
                                    csvContent.map((item) => {
                                        return (<tr>
                                            <td className='fw-bold'>{item.candidate_name}</td>
                                            <td>{item.candidate_mobile_number}</td>
                                            <td>{item.interview_email}</td>
                                            <td>{item.job_title}</td>
                                            <td>{item.company_name}</td>
                                            <td>{commonDateFormat(item.scheduled_on)}</td>
                                            <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                            <td>{item.overall_rating}</td>
                                            <td>{getInterviewFinalStatus(item.final_status)}</td>
                                            <td>{item.job_location}</td>
                                            <td>250</td>
                                            <td>{item.qc_status == QC_STATUS_FAIL ? 0 : 250}</td>
                                            <td>Rala Infotech</td>
                                        </tr>);
                                    })
                                )
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCompanyReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}
export default CompanyProfileScreen;