

import _ from 'lodash';
import * as React from "react";
import { useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, commonDateFormat, getAuthUserFromSessionStorage } from "../common/constant";
import FilledImage from './../filledStart.svg';
import UnfilledImage from './../unfilledStart.svg';
import starbpng from "./../Assets/feedbackReport/star_b.png";
import emailpng from "./../Assets/feedbackReport/email.png";
import phonepng from "./../Assets/feedbackReport/phone.png";
import calpng from "./../Assets/feedbackReport/calender.png";
import rejectedpng from "./../Assets/feedbackReport/rejected.png";
import selectedpng from "./../Assets/feedbackReport/selected.png";

const FinalInterviewReport = (props) => {

  const params = useParams();
  const [interviewState, setInterviewState] = useState(null);
  const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    getInterviewDetails(params.interviewId)
  }, [params.interviewId])

  const getInterviewDetails = (id) => {
    setIsSubmit(true)
    fetch(`${API_URL}api/getInterview/${id}`, {
      headers: {
        'Authorization': `Bearer ${userDetails.token}`,
      }
    })
      .then((response) => response.json())
      .then((response) => {
        if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
          var interviewData = response.data;
          var averagePrimarySkillsRating = 0;
          interviewData.primary_skill_feedback.map((item) => {
            averagePrimarySkillsRating += Number(item.rating);
          });
          interviewData.averagePrimarySkillsRating = Math.round(averagePrimarySkillsRating / interviewData.primary_skill_feedback.length);
          var averageSecondarySkillsRating = 0;
          interviewData.secondary_skill_feedback.map((item) => {
            averageSecondarySkillsRating += Number(item.rating);
          });
          interviewData.averageSecondarySkillsRating = Math.round(averageSecondarySkillsRating / interviewData.secondary_skill_feedback.length);

          setInterviewState(interviewData);
          setIsSubmit(false)
        }
      })
      .catch((err) => {
        setInterviewState({});
        setIsSubmit(false)
      });
  }

  const getRatingStatus = (rating) => {
    const ratingNumer = rating ? parseInt(rating) : 0;
    var ratingStatus = "";
    var backColor = ''
    switch (ratingNumer) {
      case 0:
        ratingStatus = "poor";
        backColor = 'red';
        break;
      case 1:
        ratingStatus = "poor";
        backColor = 'red';
        break;
      case 2:
        ratingStatus = "below-average";
        backColor = 'orange';
        break;
      case 3:
        ratingStatus = "average";
        backColor = 'orange';
        break;
      case 4:
        ratingStatus = "good";
        backColor = 'green';
        break;
      case 5:
        ratingStatus = "very good";
        backColor = 'green';
        break;
    }
    return ratingStatus;
  }

  return (
    <>
      {
        isSubmit &&
        <div className="spinner-loader">
          <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
        </div>
      }
      <div style={{ backgroundColor: '#F4F4F4' }}>
        <table
          border="0"
          cellSpacing="0"
          style={{ backgroundColor: '#ffffff', width: '100%', fontFamily: 'Arial, Helvetica, sans-serif' }}
        >
          <tbody>
            <tr>
              <td>
                <table style={{ backgroundColor: '#F7F8FB', width: '100%' }} cellSpacing="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '2rem' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{interviewState ? interviewState.candidate_name : ""}</div>
                        <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center' }}>
                          <img src={starbpng} alt="star" style={{ width: '16px', height: '16px' }} />
                          <span style={{ background: '#9AA7FF', color: '#13249A', padding: '3px 12px', borderRadius: '4px', fontWeight: 'bold' }}>{interviewState ? interviewState.job.primary_skill.join(', ') : ""}</span>
                        </div>
                        <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center' }}>
                          <img src={emailpng} alt="email" />
                          <span>{interviewState ? interviewState.candidate_email : ""}</span>
                        </div>
                       
                          <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center', flex:1 }}>
                            <img src={phonepng} alt="phone" />
                            <span>{interviewState ? interviewState.candidate_mobile_number : ""}</span>
                          </div>
                          
                        
                        
                        <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center' }}>
                          <img src={calpng} alt="calender" />
                          <span>{interviewState ? commonDateFormat(interviewState.scheduled_on) : ""}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center', flex:1, marginTop:'80px' }}>
                            <span>Interviewer:</span>
                            <span style={{ color: 'rgba(0,0,0,1)', fontWeight: 'bold' }}>{interviewState ? interviewState.interviewInfo.first_name : ""} </span>
                          </div>
                          <div style={{ display: 'flex', gap: '12px', padding: '8px 0', alignItems: 'center', flex:1 }}>
                            <span>Coordinator:</span>
                            <span style={{ color: 'rgba(0,0,0,1)' }}>----</span>
                          </div>
                          <div style={{}}>
                            <button style={{background:'#13249A', color:'#FFFFFF', border:'none', padding:'8px 16px', borderRadius:'6px'}}>Download</button>
                          </div>
                      </td>
                      <td style={{ textAlign: 'right' }} >{interviewState ? (interviewState.final_status == INTERVIEW_FINAL_STATUS_SELECTED ? <img src={selectedpng} alt="Selected" /> : <img src={rejectedpng} alt="Rejected" />) : ""}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: '30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="0">
                  <tr>
                    <td style={{ fontFamily: 'Arial, Helvetica, sans-serif', width: '160px' }}>
                      <span style={{ display: 'inline-block', backgroundColor: '#F7F8FB', width: '100%', fontWeight: 'bold', padding: '8px 8px 8px 30px' }}>RATINGS</span>
                    </td>
                    <td>
                      <hr style={{ height: '1px', borderTop: '1px solid #E2E8F0' }} />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '16px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>Mandatory Skills</span>
                    </td>
                  </tr>

                  {
                    (interviewState && interviewState.primary_skill_feedback.length > 0) ?
                      interviewState.primary_skill_feedback.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td style={{ backgroundColor: '#F7F8FB', padding: '16px 20px', borderRadius: '6px' }}>
                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <h6 style={{ fontWeight: 'bold', fontSize: '15px', margin: '0', padding: '0' }}>{interviewState ? interviewState.job.primary_skill[index] : ""}</h6>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                    <span className={getRatingStatus(item.rating)}>
                                      {item.rating ? getRatingStatus(item.rating) : ""}
                                    </span>
                                    <div >
                                      {Array.from({ length: item.rating }).map((_, imageIndex) => (
                                        <img
                                          key={imageIndex}
                                          loading="lazy"
                                          src={FilledImage}
                                          alt={`Rating ${imageIndex + 1}`}
                                          className="img-2"
                                        />
                                      ))}
                                      {Array.from({ length: 5 - item.rating }).map((_, imageIndex) => (
                                        <img
                                          key={imageIndex}
                                          loading="lazy"
                                          src={UnfilledImage}
                                          alt={`Rating ${imageIndex + 1}`}
                                          className="img-6"
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <p style={{ fontSize: '13px', color: '#494D5B', lineHeight: '22px' }}>
                                  <div dangerouslySetInnerHTML={{ __html: item.review }}></div>
                                </p>
                              </td>
                            </tr>
                          </>
                        )
                      })
                      : ""
                  }
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>Secondary Skills</span>
                    </td>
                  </tr>

                  {
                    (interviewState && interviewState.secondary_skill_feedback.length > 0) ?
                      interviewState.secondary_skill_feedback.map((item, index) => {
                        return (
                          <><tr>
                            <td style={{ backgroundColor: '#F7F8FB', padding: '16px 20px', borderRadius: '6px' }}>
                              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h6 style={{ fontWeight: 'bold', fontSize: '15px', margin: '0', padding: '0' }}>{interviewState ? interviewState.job.secondary_skill[index] : ""}</h6>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                  <span className={getRatingStatus(item.rating)}>
                                    {item.rating ? getRatingStatus(item.rating) : ""}
                                  </span>
                                  <div >
                                    {Array.from({ length: item.rating }).map((_, imageIndex) => (
                                      <img
                                        key={imageIndex}
                                        loading="lazy"
                                        src={FilledImage}
                                        alt={`Rating ${imageIndex + 1}`}
                                        className="img-2"
                                      />
                                    ))}
                                    {Array.from({ length: 5 - item.rating }).map((_, imageIndex) => (
                                      <img
                                        key={imageIndex}
                                        loading="lazy"
                                        src={UnfilledImage}
                                        alt={`Rating ${imageIndex + 1}`}
                                        className="img-6"
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <p style={{ fontSize: '13px', color: '#494D5B', lineHeight: '22px' }}>
                                <div dangerouslySetInnerHTML={{ __html: item.review }}></div>
                              </p>
                            </td>
                          </tr>
                          </>
                        )
                      })
                      : ""
                  }
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>Good to have Skills</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#F7F8FB', padding: '16px 20px', borderRadius: '6px' }}>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h6 style={{ fontWeight: 'bold', fontSize: '15px', margin: '0', padding: '0' }}></h6>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                          <span className={interviewState ? getRatingStatus(interviewState.good_to_have_skill_feedback_rating) : ""}>
                            {interviewState ? getRatingStatus(interviewState.good_to_have_skill_feedback_rating) : ""}
                          </span>
                          <div>
                            {Array.from({ length: interviewState ? interviewState.good_to_have_skill_feedback_rating : 0 }).map((_, imageIndex) => (
                              <img
                                key={imageIndex}
                                loading="lazy"
                                src={FilledImage}
                                alt={`Rating ${imageIndex + 1}`}
                                className="img-2"
                              />
                            ))}
                            {Array.from({ length: 5 - (interviewState ? interviewState.good_to_have_skill_feedback_rating : 0) }).map((_, imageIndex) => (
                              <img
                                key={imageIndex}
                                loading="lazy"
                                src={UnfilledImage}
                                alt={`Rating ${imageIndex + 1}`}
                                className="img-6"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <p style={{ fontSize: '13px', color: '#494D5B', lineHeight: '22px' }}>
                        <div dangerouslySetInnerHTML={{ __html: interviewState?.good_to_have_skill_feedback }}></div>
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>Coding Skills</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#F7F8FB', padding: '16px 20px', borderRadius: '6px' }}>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h6 style={{ fontWeight: 'bold', fontSize: '15px', margin: '0', padding: '0' }}></h6>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                          <span className={interviewState ? getRatingStatus(interviewState.coding_skill_feedback_rating) : ""}>
                            {interviewState ? getRatingStatus(interviewState.coding_skill_feedback_rating) : ""}
                          </span>
                          <div>
                            {Array.from({ length: interviewState ? interviewState.coding_skill_feedback_rating : 0 }).map((_, imageIndex) => (
                              <img
                                key={imageIndex}
                                loading="lazy"
                                src={FilledImage}
                                alt={`Rating ${imageIndex + 1}`}
                                className="img-2"
                              />
                            ))}
                            {Array.from({ length: 5 - (interviewState ? interviewState.coding_skill_feedback_rating : 0) }).map((_, imageIndex) => (
                              <img
                                key={imageIndex}
                                loading="lazy"
                                src={UnfilledImage}
                                alt={`Rating ${imageIndex + 1}`}
                                className="img-6"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      {
                        interviewState && interviewState.coding_skill_feedback_rating && (
                          <p style={{ fontSize: '13px', color: '#494D5B', lineHeight: '22px' }}>
                            <div dangerouslySetInnerHTML={{ __html: interviewState.good_to_have_skill_feedback }}></div>
                          </p>
                        )
                      }
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#F7F8FB', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td style={{ display: 'flex', gap: '12px', justifyContent: 'space-between' }}>
                      <div style={{ backgroundColor: '#ffffff', padding: '16px 20px', borderRadius: '6px', flex: 1, textAlign: 'center' }}>
                        <div style={{ margin: '12px 0' }}>
                          {
                            interviewState && (
                              <span className={interviewState ? getRatingStatus(interviewState.averagePrimarySkillsRating) : ""}>
                                {
                                  interviewState ? getRatingStatus(interviewState.averagePrimarySkillsRating) : ""
                                }
                              </span>
                            )
                          }
                        </div>
                        {
                          (interviewState) ? Array.from({ length: interviewState.averagePrimarySkillsRating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={FilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        {
                          (interviewState) ? Array.from({ length: 5 - interviewState.averagePrimarySkillsRating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={UnfilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        <div style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: 'bold', margin: '8px 0' }}>
                          Mandatory Skills
                        </div>
                      </div>
                      <div style={{ backgroundColor: '#ffffff', padding: '16px 20px', borderRadius: '6px', flex: 1, textAlign: 'center' }}>
                        <div style={{ margin: '12px 0' }}>
                          {
                            interviewState && (
                              <span className={interviewState ? getRatingStatus(interviewState.averageSecondarySkillsRating) : ""}>
                                {
                                  interviewState ? getRatingStatus(interviewState.averageSecondarySkillsRating) : ""
                                }
                              </span>
                            )
                          }
                        </div>
                        {
                          (interviewState) ? Array.from({ length: interviewState.averageSecondarySkillsRating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={FilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        {
                          (interviewState) ? Array.from({ length: 5 - interviewState.averageSecondarySkillsRating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={UnfilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        <div style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: 'bold', margin: '8px 0' }}>
                          Secondary Skill
                        </div>
                      </div>


                      <div style={{ backgroundColor: '#ffffff', padding: '16px 20px', borderRadius: '6px', flex: 1, textAlign: 'center' }}>
                        <div style={{ margin: '12px 0' }}>
                          {
                            interviewState && (
                              <span className={interviewState ? getRatingStatus(interviewState.coding_skill_feedback_rating) : ""}>
                                {
                                  interviewState ? getRatingStatus(interviewState.coding_skill_feedback_rating) : ""
                                }
                              </span>
                            )
                          }
                        </div>
                        {
                          (interviewState) ? Array.from({ length: interviewState.coding_skill_feedback_rating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={FilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        {
                          (interviewState) ? Array.from({ length: 5 - interviewState.coding_skill_feedback_rating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={UnfilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        <div style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: 'bold', margin: '8px 0' }}>
                          Coding Skill
                        </div>
                      </div>

                      <div style={{ backgroundColor: '#ffffff', padding: '16px 20px', borderRadius: '6px', flex: 1, textAlign: 'center' }}>
                        <div style={{ margin: '12px 0' }}>
                          {
                            interviewState && (
                              <span className={interviewState ? getRatingStatus(interviewState.overall_rating) : ""}>
                                {
                                  interviewState ? getRatingStatus(interviewState.overall_rating) : ""
                                }
                              </span>
                            )
                          }
                        </div>
                        {
                          (interviewState) ? Array.from({ length: interviewState.overall_rating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={FilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        {
                          (interviewState) ? Array.from({ length: 5 - interviewState.overall_rating }).map((_, imageIndex) => (
                            <img
                              key={imageIndex + 'primary1'}
                              loading="lazy"
                              src={UnfilledImage}
                              alt={`Rating ${imageIndex + 1}`}
                              className="img-2"
                            />
                          )) : ""
                        }
                        <div style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: 'bold', margin: '8px 0' }}>
                          Overall Skill
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>
                        Final Recommendation
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#ECF4FF', padding: '16px 20px', borderRadius: '6px' }}>
                      <p style={{ fontSize: '13px', color: '#252F79', lineHeight: '22px' }} dangerouslySetInnerHTML={{ __html: interviewState ? interviewState.final_recommendation : "" }}>
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '8px 30px' }}>
                <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                  <tr>
                    <td>
                      <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>
                        Coding Test
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#ECF4FF', padding: '16px 20px', borderRadius: '6px' }}>
                      <p style={{ fontSize: '13px', color: '#252F79', lineHeight: '22px' }}>
                        <div>Question By Interviwer</div>
                        {
                          interviewState && interviewState.coding_skill_feedback && (
                            <div className="div-108">
                              <div dangerouslySetInnerHTML={{ __html: interviewState ? interviewState.coding_skill_feedback : "" }}></div>
                            </div>
                          )
                        }
                      </p>
                    </td>
                  
                  </tr>
                  <tr>
                  <div className="column-5">
                      <div>Code By Candidate</div>
                      {interviewState && interviewState.code_by_candidate ?
                        (interviewState && interviewState.code_by_candidate) && (
                          <SyntaxHighlighter style={solarizedlight}>
                            {interviewState ? interviewState.code_by_candidate : ""}
                          </SyntaxHighlighter>
                        ) : 'Not Done'
                      }
                    </div>
                    <div className="column-6">
                      <div>Code Output</div>
                      {
                        (interviewState && interviewState.code_output_by_candidate) && (
                          <SyntaxHighlighter style={dark}>
                            {interviewState ? interviewState.code_output_by_candidate : ""}
                          </SyntaxHighlighter>
                        )
                      }
                    </div>
                  </tr>
                </table>
              </td>
            </tr>

            {
              interviewState?.code_of_screenshot?.length > 0 ?
                (<tr>
                  <td style={{ padding: '8px 30px' }}>
                    <table style={{ backgroundColor: '#ffffff', width: '100%' }} cellSpacing="8">
                      <tr>
                        <td>
                          <span style={{ textTransform: 'uppercase', fontSize: '13px', color: '#000000', opacity: '.6', fontWeight: 'bold' }}>
                            Uploaded Screenshot
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ backgroundColor: '#ffff' }}>
                          {
                            (interviewState && interviewState.code_of_screenshot) ? interviewState.code_of_screenshot.map((item) => {
                              return <div className="div-124"><img style={{ width: '100%' }} src={item} /></div>
                            }) : ""
                          }
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>) : ''
            }


          </tbody>
        </table>
      </div>

      <div className="div">

        {/* <div className="div-121">
          <div className="div-122">INTERVIEWER INFORMATION</div>
          <div className="div-123" />
        </div> */}
        {/* <div className="div-124">
          <div className="div-125">
            Interviewer:{" "}
            <span style={{ color: 'rgba(0,0,0,1)' }}>{interviewState ? interviewState.interviewInfo.first_name : ""} </span>
            <div>
            Coordinator:{" "}
            <span style={{ color: 'rgba(0,0,0,1)' }}>----</span>
            </div>
          </div>
        </div> */}
      </div>
      <style jsx>{`
        .div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          padding: 49px 64px;
        }
        @media (max-width: 991px) {
          .div {
            padding: 0 20px;
          }
        }
        .div-2 {
          align-self: stretch;
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-3 {
          display: flex;
          margin-top: 16px;
          flex-direction: column;
        }
        .div-4 {
          color: #000;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-5 {
          justify-content: space-between;
          display: flex;
          gap: 4px;
        }
        .img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-6 {
          justify-content: space-between;
          display: flex;
          gap: 15px;
        }
        .div-7 {
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.01px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-7 {
            white-space: initial;
          }
        }
        .div-8 {
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.01px;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-9 {
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.01px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-9 {
            white-space: initial;
          }
        }
        .div-10 {
          align-items: end;
          display: flex;
          flex-direction: column;
        }
        .div-11 {
          color: var(--md-sys-color-on-primary, #fff);
          letter-spacing: 0.02px;
          white-space: nowrap;
          justify-content: center;
          border-radius: 20px;
          box-shadow: 0px 2.465px 2.465px 0px rgba(0, 0, 0, 0.25);
          background-color: #00970f;
          padding: 4px 10px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-11 {
            white-space: initial;
          }
        }
        .div-12 {
          justify-content: space-between;
          align-self: stretch;
          display: flex;
          margin-top: 8px;
          gap: 0px;
        }
        .div-13 {
          color: var(--md-ref-neutral-neutral60, #f0dc96);
          letter-spacing: 0.06px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-13 {
            white-space: initial;
          }
        }
        .div-14 {
          color: #000;
          letter-spacing: 0.06px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-14 {
            white-space: initial;
          }
        }
        .div-15 {
          justify-content: space-between;
          align-self: stretch;
          display: flex;
          margin-top: 24px;
          gap: 14px;
        }
        @media (max-width: 991px) {
          .div-15 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-16 {
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.06px;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-16 {
            white-space: initial;
          }
        }
        .div-17 {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #746d6f;
          align-self: center;
          width: 1536px;
          max-width: 100%;
          height: 2px;
          margin: auto 0;
        }
        .div-18 {
          color: #000;
          align-self: center;
          margin: 20px 0px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-18 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-19 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 0px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-19 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-20 {
          color: #000;
          letter-spacing: 0.02px;
          font: bold 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
          width: 250px;
          text-align: left;
        }
        .div-21 {
          color: #0fb600;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-22 {
          display: flex;
          justify-content: end;
          gap: 10px;
          width: 250px;
          text-align: right;
        }
        .div-23 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-4 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-24 {
          color: #000;
          letter-spacing: 0.02px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-24 {
            white-space: initial;
          }
        }
        .div-25 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-25 {
            max-width: 100%;
          }
        }
        .div-26 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-26 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-27 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-28 {
          color: #0fb600;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-29 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-30 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-10 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-11 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-31 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-31 {
            white-space: initial;
          }
        }
        .div-32 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-32 {
            max-width: 100%;
          }
        }
        .div-33 {
          color: #000;
          align-self: center;
          margin: 20px 0px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-33 {
            max-width: 100%;
          }
        }
        .div-34 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 0px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-34 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-35 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-36 {
          color: #d66700;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-37 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-38 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-12 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-13 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-14 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          max-width: 100%;
        }
        .img-15 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-16 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-39 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-39 {
            white-space: initial;
          }
        }
        .div-40 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-40 {
            max-width: 100%;
          }
        }
        .div-41 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-41 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-42 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-43 {
          color: #d66700;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-44 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-45 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-17 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-18 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-46 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-46 {
            white-space: initial;
          }
        }
        .div-47 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-47 {
            max-width: 100%;
          }
        }
        .div-48 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-48 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-49 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-50 {
          color: #f00;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-51 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-52 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-22 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-23 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-24 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-25 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-26 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-53 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-53 {
            white-space: initial;
          }
        }
        .div-54 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-54 {
            max-width: 100%;
          }
        }
        .div-55 {
          color: #000;
          align-self: center;
          margin-top: 32px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-55 {
            max-width: 100%;
          }
        }
        .div-56 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 32px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-56 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-57 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-58 {
          color: #e69c57;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-59 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-60 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-27 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-28 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-29 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          max-width: 100%;
        }
        .img-30 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-31 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-61 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-61 {
            white-space: initial;
          }
        }
        .div-62 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-62 {
            max-width: 100%;
          }
        }
        .div-63 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-63 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-64 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-65 {
          color: #e69c57;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-66 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-67 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-32 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-33 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-34 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-35 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-36 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-68 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-68 {
            white-space: initial;
          }
        }
        .div-69 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          width: 1339px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-69 {
            max-width: 100%;
          }
        }
        .div-70 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-70 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-71 {
          color: #000;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-72 {
          color: #f00;
          letter-spacing: 0.02px;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-73 {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
        .div-74 {
          display: flex;
          gap: 10px;
          padding: 0 4px;
        }
        .img-37 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-38 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-39 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-40 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .img-41 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: #fff;
          overflow: hidden;
          max-width: 100%;
        }
        .div-75 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-75 {
            white-space: initial;
          }
        }
        .div-76 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          width: 1339px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-76 {
            max-width: 100%;
          }
        }
        .div-77 {
          justify-content: space-between;
          align-self: center;
          margin-top: 32px;
          width: 100%;
          padding: 10px 0;
        }
        @media (max-width: 991px) {
          .div-77 {
            max-width: 100%;
          }
        }
        .div-78 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-78 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: normal;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-79 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-79 {
            margin-top: 40px;
          }
        }
        .div-80 {
          justify-content: center;
          border-radius: 16px;
          background-color: var(--md-ref-error-error95, #ffedea);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 26px 42px;
        }
        @media (max-width: 991px) {
          .div-80 {
            padding: 0 20px;
          }
        }
        .div-81 {
          justify-content: space-between;
          display: flex;
          gap: 10px;
        }
        .div-82 {
          border-radius: 16px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          padding: 0 7px;
        }
        .img-42 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-43 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-44 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-45 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-46 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .div-83 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-83 {
            white-space: initial;
          }
        }
        .div-84 {
          color: #0fb600;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 20px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-84 {
            white-space: initial;
          }
        }
        .div-85 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-85 {
            white-space: initial;
          }
        }
        .column-2 {
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: normal;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-86 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-86 {
            margin-top: 40px;
          }
        }
        .div-87 {
          justify-content: center;
          border-radius: 16px;
          background-color: var(--md-ref-error-error95, #ffedea);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 26px 42px;
        }
        @media (max-width: 991px) {
          .div-87 {
            padding: 0 20px;
          }
        }
        .div-88 {
          justify-content: space-between;
          display: flex;
          gap: 10px;
        }
        .div-89 {
          border-radius: 16px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          padding: 0 7px;
        }
        .img-47 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-48 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-49 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-50 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-51 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .div-90 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-90 {
            white-space: initial;
          }
        }
        .div-91 {
          color: #0fb600;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 20px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-91 {
            white-space: initial;
          }
        }
        .div-92 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-92 {
            white-space: initial;
          }
        }
        .column-3 {
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: normal;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-93 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-93 {
            margin-top: 40px;
          }
        }
        .div-94 {
          justify-content: center;
          border-radius: 16px;
          background-color: var(--md-ref-error-error95, #ffedea);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 26px 42px;
        }
        @media (max-width: 991px) {
          .div-94 {
            padding: 0 20px;
          }
        }
        .div-95 {
          justify-content: space-between;
          display: flex;
          gap: 10px;
        }
        .div-96 {
          border-radius: 16px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          padding: 0 7px;
        }
        .img-52 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-53 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-54 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-55 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-56 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .div-97 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-97 {
            white-space: initial;
          }
        }
        .div-98 {
          color: #0fb600;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 20px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-98 {
            white-space: initial;
          }
        }
        .div-99 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-99 {
            white-space: initial;
          }
        }
        .column-4 {
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: normal;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .div-100 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-100 {
            margin-top: 40px;
          }
        }
        .div-101 {
          justify-content: center;
          border-radius: 16px;
          background-color: var(--md-ref-error-error95, #ffedea);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 26px 42px;
        }
        @media (max-width: 991px) {
          .div-101 {
            padding: 0 20px;
          }
        }
        .div-102 {
          justify-content: space-between;
          display: flex;
          gap: 10px;
        }
        .div-103 {
          border-radius: 16px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          padding: 0 7px;
        }
        .img-57 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-58 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-59 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-60 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .img-61 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          fill: rgba(255, 255, 255, 0);
          overflow: hidden;
          max-width: 100%;
        }
        .div-104 {
          color: #000;
          letter-spacing: 0.02px;
          flex-grow: 1;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-104 {
            white-space: initial;
          }
        }
        .div-105 {
          color: #0fb600;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 20px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-105 {
            white-space: initial;
          }
        }
        .div-106 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 500 16px/150% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-106 {
            white-space: initial;
          }
        }
        .div-107 {
          color: #000;
          align-self: center;
          margin: 20px 0px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-107 {
            max-width: 100%;
          }
        }
        .div-108 {
          color: #000;
          letter-spacing: 0.02px;
          align-self: center;
          margin-top: 26px;
          width: 100%;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-108 {
            max-width: 100%;
          }
        }
        .div-109 {
          justify-content: center;
          align-self: center;
          display: flex;
          margin-top: 24px;
          width: 100%;
          gap: 14px;
        }
        @media (max-width: 991px) {
          .div-109 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-110 {
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.06px;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-110 {
            white-space: initial;
          }
        }
        .div-111 {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #746d6f;
          align-self: center;
          width: 1268px;
          max-width: 100%;
          height: 2px;
          margin: auto 0;
        }
        .div-112 {
          justify-content: center;
          align-self: center;
          margin-top: 0px;
          width: 100%;
          padding: 4px 0;
        }
        @media (max-width: 991px) {
          .div-112 {
            max-width: 100%;
          }
        }
        .div-113 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-113 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          flex: 1
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .img-62 {
          aspect-ratio: 0.91;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-62 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          flex: 1
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .div-114 {
          padding-bottom: 50px;
          background-color: #000;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-114 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-115 {
          color: var(--md-ref-palette-neutral-variant100, #fff);
          letter-spacing: 0.06px;
          white-space: nowrap;
          align-items: start;
          background-color: var(--md-ref-palette-neutral-variant10, #423b3d);
          margin-bottom: 497px;
          justify-content: center;
          padding: 10px 60px 10px 10px;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-115 {
            white-space: initial;
            max-width: 100%;
            padding-right: 20px;
            margin-bottom: 40px;
          }
        }
        .div-116 {
          display: flex;
          margin-top: 24px;
          gap: 14px;
        }
        @media (max-width: 991px) {
          .div-116 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-117 {
          width: 109px;
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.06px;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-117 {
            white-space: initial;
          }
        }
        .div-118 {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #746d6f;
          align-self: center;
          flex-grow: 1;
          height: 2px;
          margin: auto 0;
        }
        .div-119 {
          color: #000;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          background-color: #e3e3e3;
          align-self: center;
          margin-top: 24px;
          width: 100%;
          padding: 240px 60px 189px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-119 {
            white-space: initial;
            max-width: 100%;
            padding: 40px 20px;
          }
        }
        .div-120 {
          color: #000;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          background-color: #e3e3e3;
          align-self: center;
          margin-top: 24px;
          width: 100%;
          padding: 252px 60px 198px;
          font: 500 22px/127% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-120 {
            white-space: initial;
            max-width: 100%;
            padding: 40px 20px;
          }
        }
        .div-121 {
          display: flex;
          margin-top: 24px;
          gap: 14px;
          padding: 8px 0;
        }
        @media (max-width: 991px) {
          .div-121 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-122 {
          width: 120px;
          color: var(--md-ref-palette-neutral-variant60, #746d6f);
          letter-spacing: 0.06px;
          white-space: nowrap;
          font: 500 12px/133% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-122 {
            white-space: initial;
          }
        }
        .div-123 {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #746d6f;
          align-self: center;
          flex-grow: 1;
          height: 2px;
          margin: auto 0;
        }
        .div-124 {
          justify-content: space-between;
          align-self: center;
          display: flex;
          width: 100%;
          gap: 20px;
          margin: 24px 0 9px;
        }
        @media (max-width: 991px) {
          .div-124 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-125 {
          color: var(--md-ref-palette-neutral-variant0, #000);
          letter-spacing: 0.01px;
          flex: 1;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-126 {
          color: var(--md-ref-palette-neutral-variant0, #000);
          letter-spacing: 0.01px;
          flex: 1;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-127 {
          color: var(--md-ref-palette-neutral-variant0, #000);
          letter-spacing: 0.01px;
          flex: 1;
          font: 500 16px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
        }

        
        .poor, .below-average {
          text-transform: uppercase; font-weight: bold; color: #790202; background:#F4BBBB; font-size: 12px; line-height: 12px; padding: 4px 8px; border-radius: 4px;"
        }
        .average {
          text-transform: uppercase; font-weight: bold; color: #832F00; background: #FFAD62; font-size: 12px; line-height: 12px; padding: 4px 8px; border-radius: 4px;"
        }
        .good, .very {
          
          text-transform: uppercase; font-weight: bold; color: #00661D; background: #87FFA9; font-size: 12px; line-height: 12px; padding: 4px 8px; border-radius: 4px;"
        }
                  @media print {
body {-webkit-print-color-adjust: exact;}
}
      `}</style>
    </>
  );
}

export default FinalInterviewReport;


