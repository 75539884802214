import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import _ from 'lodash';
import { getAuthUserFromSessionStorage, removeAuthUserFromSessionStorage } from '../common/constant';

const AuthGuard = () => {
  const userDetails = useState(getAuthUserFromSessionStorage());
  let isAuthenticated = true;
  if (_.isUndefined(userDetails) || _.isNull(userDetails) || _.isEmpty(userDetails)) {
    removeAuthUserFromSessionStorage();
    isAuthenticated = false;
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default AuthGuard;
