// import 'bootstrap/dist/css/bootstrap.min.css';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginScreen from "./Components/LoginScreen/LoginScreen";
import AdministratorAccountScreen from "./Components/AdministratorAccountScreen/AdministratorAccountScreen";
import InterviewerAccountScreen from "./Components/InterviewerAccountScreen/InterviewerAccountScreen";
import EmployerAccountScreen from "./Components/EmployerAccountScreen/EmployerAccountScreen";
import ForgotPasswordScreen from "./Components/ForgotPasswordScreen/ForgotPasswordScreen";
import NewPasswordScreen from "./Components/NewPasswordScreen/NewPasswordScreen";
import DashboardScreen from "./Components/DashboardScreen/DashboardScreen";
import SuperAdminSettingScreen from "./Components/SuperAdminSettingScreen/SuperAdminSettingScreen";
import InterviewerProfileScreen from "./Components/InterviewerProfileScreen/InterviewerProfileScreen";
import HistoryScreen from "./Components/HistoryScreen/HistoryScreen";
import RequestInterviewerUsersScreen from "./Components/RequestInterviewerUsersScreen/RequestInterviewerUsersScreen";
import CalendarScreen from "./Components/CalendarScreen/CalendarScreen";
import CreateJobScreen from "./Components/CreateJobScreen/CreateJobScreen";
import LiveInterviewerScreen from "./Components/LiveInterviewerScreen/LiveInterviewerScreen";
import ProfileSettingsScreen from "./Components/ProfileSettingsScreen/ProfileSettingsScreen";
import StateScreen from './Components/StateScreen/StateScreen';
import ReportScreen from './Components/ReportScreen/ReportScreen';
import ScheduledProfileScreen from './Components/ScheduledProfileScreen/ScheduledProfileScreen';
import ProposedInterviewScreen from './Components/ProposedInterviewScreen/ProposedInterviewScreen';
import CompanyProfileScreen from './Components/CompanyProfileScreen/CompanyProfileScreen';
import InterviewerDashboardScreen from './Components/InterviewerDashboardScreen/InterviewerDashboardScreen';
import CodingScreen from './Components/CodingScreen';
import FinalInterviewReport from './Components/FinalInterviewReport';
import AuthGuard from './Components/AuthGuard';
import LoginGuard from './Components/LoginGuard';
import PaymentScreen from './Components/PaymentScreen/PaymentScreen';
import AdminProfileScreen from './Components/AdminProfileScreen/AdminProfileScreen';

Intl.DateTimeFormat.DefaultTimeZone = 'Asia/Kolkata';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginGuard />}>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/sign-in" element={<LoginScreen />} />
          <Route path="/forget-password" element={<ForgotPasswordScreen />} />
          <Route path="/new-password/:id" element={<NewPasswordScreen />} />
          <Route path="/create-administrator-account" element={<AdministratorAccountScreen />} />
          <Route path="/create-interviewer-account" element={<InterviewerAccountScreen />} />
          <Route path="/create-employer-account" element={<EmployerAccountScreen />} />
        </Route>
        <Route path="/" element={<AuthGuard />}>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/interviewer-dashboard" element={<InterviewerDashboardScreen />} />
          <Route path="/super-admin-settings" element={<SuperAdminSettingScreen />} />
          <Route path="/interviewer-profile-page/:id" element={<InterviewerProfileScreen />} />
          <Route path="/history" element={<HistoryScreen />} />
          <Route path="/requestInterviewerUsers/:id" element={<RequestInterviewerUsersScreen />} />
          <Route path="/calendar" element={<CalendarScreen />} />
          <Route path="/create-job" element={<CreateJobScreen />} />
          <Route path="/edit-job/:id" element={<CreateJobScreen />} />
          <Route path="/live-interviewer/:id" element={<LiveInterviewerScreen />} />
          <Route path="/edit-profile" element={<ProfileSettingsScreen />} />
          <Route path="/state" element={<StateScreen />} />
          <Route path="/report" element={<ReportScreen />} />
          <Route path="/job/:id/report" element={<ReportScreen />} />
          <Route path="/company-profile-page/:id" element={<CompanyProfileScreen />} />
          <Route path="/employee-profile-page/:id" element={<CompanyProfileScreen />} />
          <Route path="/finalInterviewReport/:interviewId" element={<FinalInterviewReport />} />
          <Route path="/job/:id/scheduled-profile" element={<ScheduledProfileScreen />} />
          <Route path="/job/:id/proposed-interviewers" element={<ProposedInterviewScreen />} />


          <Route path="/admin-profile-page/:id" element={<AdminProfileScreen />} />
        </Route>
        <Route path="/coding/:id" element={<CodingScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
