import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { ITEMS_PER_PAGE } from '../constant';
import './PaginationComponent.css';

const PaginationComponent = ({ data, updatePagination }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [isShowMaxError, setIsShowMaxError] = useState(false)

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updatePagination(page, itemsPerPage)
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    // Determine the starting page number to display
    let startPage = Math.max(1, currentPage - 1);
  
    // Show a maximum of 2 page numbers
    for (let i = startPage; i <= Math.min(totalPages, startPage + 1); i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === parseInt(currentPage)}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
  
    // Add an ellipsis if there are more pages after the displayed page numbers
    if (startPage + 1 < totalPages) {
      pageNumbers.push(<Pagination.Ellipsis disabled key="ellipsis" />);
    }
  
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
    updatePagination(1, parseInt(e.target.value));
  };
  
  const pageNumberChange = (e) => {
    const value = e.target.value;
    setIsShowMaxError(false)

    if (value !== '' && value != null && /^\d+$/.test(value) && value > 0) {
      if (value <= Math.ceil(data.length / itemsPerPage)) {
        handlePageChange(value);
      } else {
        setIsShowMaxError(true)
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4" style={{
        width: '100%',
       
      }}>
        {/* <tr>
          {currentItems.map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </tr> */}

        {/* Items per page dropdown */}
        
        {
          data.length > 20 && (
            <select style={{width:'80px'}} className="paginate-option form-select" onChange={handleItemsPerPageChange}>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
              <option value={80}>80</option>
            </select>
          )
        }

          {
            data.length > 20 && (
              <Pagination>
                <Pagination.Prev 
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                >
                  <FaAngleDoubleLeft />
                </Pagination.Prev>
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.ceil(data.length / itemsPerPage))}
                  disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                >
                  <FaAngleDoubleRight />
                </Pagination.Prev>
              </Pagination>
            )
          }

          {/* {
            data.length > 20 && (
              <>
                <span class="mx-3" style={{
                  color: "#4b5563",
                  userSelect: "none",
                  fontSize: "1.2rem",
                  marginBottom: "18px",
                  padding: "-9px",
                }}>
                  Go to &nbsp;<input type="number" max={Math.ceil(data.length / itemsPerPage)} placeholder={currentPage} size="2" onChange={pageNumberChange} style={{
                    fontSize: "1.2rem",
                    width: "67px",
                    height: "51px",
                    paddingLeft: "10px"
                  }} />&nbsp;
                  {
                    isShowMaxError && (
                      <span style={{color: 'red'}}>max input: {Math.ceil(data.length / itemsPerPage)}</span>
                    )
                  }
                </span>
              </>
            )
          } */}
        
      </div>

    </>
  );
};

export default PaginationComponent;
