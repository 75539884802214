import React, { Fragment, useState, useEffect, useRef } from "react";
import _ from "lodash";
import Loader from "react-js-loader";
import "./StateScreen.css";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import { Card, Form, ListGroup, Badge } from "react-bootstrap";
import DoughnutChart from "../Charts/doughnut";
import BarChart from "../Charts/bar";
import { HiChartPie } from "react-icons/hi";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import { EMPLOYER_SUPER_ADMIN, SUPER_ADMIN } from "../../common/roles";
import { API_URL, getAuthUserFromSessionStorage } from "../../common/constant";

const TOTAL_POFILE_ADDED = "TOTAL POFILE ADDED";
const SELECTED_CANDIDATE = "SELECTED CANDIDATE";
const REJECTED_CANDIDATE = "REJECTED CANDIDATE";
const SCHEDULED_INTERVIEW = "SCHEDULED INTERVIEW";
const NON_SCHEDULED_INTERVIEW = "NON SCHEDULED INTERVIEW";
const NO_SHOW = "NO SHOW";
const PROXY = "PROXY";

const StateLabel = [
  TOTAL_POFILE_ADDED,
  SELECTED_CANDIDATE,
  REJECTED_CANDIDATE,
  SCHEDULED_INTERVIEW,
  NON_SCHEDULED_INTERVIEW,
  NO_SHOW,
  PROXY,
];

const StateScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [initialRalaAdminList, setInitialRalaAdminList] = useState([]);
  const [ralaAdminList, setRalaAdminList] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedSubAdminId, setSelectedSubAdminId] = useState(null);
  const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());

  const getCurrentMonthStart = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  };

  const getCurrentMonthEnd = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return new Date(
      lastDay.getFullYear(),
      lastDay.getMonth(),
      lastDay.getDate()
    );
  };

  const [calendarValue, setCalendarValue] = useState([
    getCurrentMonthStart(),
    getCurrentMonthEnd(),
  ]);
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [monthValue, setMonthValue] = useState(new Date().getMonth());

  const [calenderYearDate, setCalenderYearDate] = useState(new Date());
  const [calenderMonthDate, setCalenderMonthDate] = useState(new Date());

  const [doughnutData, setDoughnutData] = useState({
    labels: StateLabel,
    data: [0, 0, 0, 0, 0, 0, 0],
    name: "State",
  });
  const [barData, setBarData] = useState({
    labels: StateLabel,
    data: [0, 0, 0, 0, 0, 0, 0],
    name: "State",
  });

  useEffect(() => {
    if (!_.isUndefined(calendarValue) && !_.isNull(calendarValue)) {
      getDoughnutData();
    }
  }, [calendarValue]);

  useEffect(() => {
    if (
      !_.isUndefined(yearValue) &&
      !_.isNull(yearValue) &&
      !_.isUndefined(monthValue) &&
      !_.isNull(monthValue)
    ) {
      getBarChartData();
    }
  }, [yearValue, monthValue]);

  // get compant list
  useEffect(() => {
    if (
      !_.isUndefined(companyList) &&
      !_.isNull(companyList) &&
      companyList.length === 0
    ) {
      if (userDetails.account_type == SUPER_ADMIN) {
        getCompanyList();
        getRalaAdminList();
      }
    }
  }, []);

  const getCompanyList = () => {
    fetch(`${API_URL}api/companies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          response.data.length > 0
        ) {
          setCompanyList(response.data);
        }
      })
      .catch((err) => {
        setCompanyList([]);
      });
  };

  const getRalaAdminList = () => {
    fetch(`${API_URL}api/ralaAdminUsers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          response.data.length > 0
        ) {
          setRalaAdminList(response.data);
          setInitialRalaAdminList(response.data);
        }
      })
      .catch((err) => {
        setRalaAdminList([]);
        setInitialRalaAdminList([]);
      });
  };

  const getBarChartData = (value) => {
    setIsLoading(true);
    var startDate = new Date(yearValue, monthValue, 1);
    var endDate = new Date(yearValue, monthValue + 1, 0);
    fetch(`${API_URL}api/getStateData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        companyId:
          userDetails.account_type === EMPLOYER_SUPER_ADMIN
            ? userDetails.id
            : selectedCompanyId,
        ralaSubAdminId: selectedSubAdminId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setIsLoading(false);
        setBarData({
          labels: [
            TOTAL_POFILE_ADDED,
            SELECTED_CANDIDATE,
            REJECTED_CANDIDATE,
            SCHEDULED_INTERVIEW,
            NON_SCHEDULED_INTERVIEW,
            NO_SHOW,
            PROXY,
          ],
          data: [
            response.data.profileAdded,
            response.data.selected,
            response.data.rejected,
            response.data.scheduledInterviews,
            response.data.nonScheduledInterviews,
            response.data.noShow,
            response.data.proxy,
          ],
          name: "State",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setBarData({
          labels: ["Selected", "Redjected", "No Show", "Proxy"],
          data: [],
          name: "State",
        });
      });
  };
  const getDoughnutData = (value) => {
    setIsLoading(true);
    const startDate = new Date(calendarValue[0]);
    const endDate = new Date(calendarValue[1]);
    fetch(`${API_URL}api/getStateData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        companyId:
          userDetails.account_type === EMPLOYER_SUPER_ADMIN
            ? userDetails.id
            : null,
        ralaSubAdminId: null,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setIsLoading(false);
        setDoughnutData({
          labels: [
            TOTAL_POFILE_ADDED,
            SELECTED_CANDIDATE,
            REJECTED_CANDIDATE,
            SCHEDULED_INTERVIEW,
            NON_SCHEDULED_INTERVIEW,
            NO_SHOW,
            PROXY,
          ],
          data: [
            response.data.profileAdded,
            response.data.selected,
            response.data.rejected,
            response.data.scheduledInterviews,
            response.data.nonScheduledInterviews,
            response.data.noShow,
            response.data.proxy,
          ],
          name: "State",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setDoughnutData({
          labels: ["Selected", "Redjected", "No Show", "Proxy"],
          data: [],
          name: "State",
        });
      });
  };

  const onChangeYear = (e) => {
    setCalenderYearDate(e);
    setYearValue(new Date(e).getFullYear());
  };

  const onChangeMonth = (e) => {
    setCalenderMonthDate(e);
    setMonthValue(new Date(e).getMonth());
  };

  const onCalendarChange = (e) => {
    setCalendarValue(e);
  };

  useEffect(() => {
    if (
      !_.isUndefined(yearValue) &&
      !_.isNull(yearValue) &&
      !_.isUndefined(calendarValue) &&
      !_.isNull(calendarValue)
    ) {
      getBarChartData();
      getDoughnutData();
    }
  }, [selectedCompanyId, selectedSubAdminId]);

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-loader">
          <Loader
            type="spinner-circle"
            bgColor="#2F2F74"
            color="#2F2F74"
            size={100}
          />
        </div>
      )}
      <div className="container-fluid dashboard-container fixed-padding">
        <div className="row">
          <div className="col-12 px-0">
            <HeaderScreen />
          </div>
        </div>
        <div className="row">
          <div className="col-12 dashboard-title pageTitle_fixed"><div className="d-flex align-items-center gap-2"><HiChartPie size={18}/>Stats</div></div>
        </div>
        <div className="row">
          <div className="col-12">
          <Card bg={"light"}>
          <Card.Body>
                <div className="row p-4">
                  <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                    
                        <div className="row">
                          <div className="col-md-7 col-12 my-2 my-md-0">
                            <div className="d-flex justify-content-center">
                              <div style={{ position: "relative" }}>
                                <DoughnutChart doughnutData={doughnutData} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5  col-12 my-2 my-md-0">
                            <ListGroup as="ul">
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                              >
                                <div className="ms-2 me-auto">
                                  <b>
                                    Report{" "}
                                    {moment(calendarValue[0]).format(
                                      "DD/MM/YY"
                                    ) +
                                      " - " +
                                      moment(calendarValue[1]).format(
                                        "DD/MM/YY"
                                      )}
                                  </b>
                                </div>
                              </ListGroup.Item>
                              {doughnutData?.labels.map((item, index) => {
                                return (
                                  <ListGroup.Item
                                    as="li"
                                    key={index}
                                    className="d-flex justify-content-between align-items-start"
                                  >
                                    <div className="ms-2 me-auto">{item}</div>
                                    <Badge bg="primary" pill>
                                      {doughnutData?.data[index]}
                                    </Badge>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </div>
                        </div>
                      
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-12 col-12">
                    <Card bg={"light"}>
                      <Card.Body>
                        <Calendar
                          selectRange
                          onChange={onCalendarChange}
                          value={calendarValue}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                </Card.Body>
                </Card>
                <br />

                <Card bg={"light"}>
                  <Card.Body>
                  <div className="row p-4">
                      <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="row">
                          <div className="col-md-7 col-12 my-2 my-md-0">
                            <BarChart barData={barData} />
                          </div>
                          <div className="col-md-5 col-12 my-2 my-md-0">
                            <ListGroup as="ul">
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                              >
                                <div className="ms-2 me-auto">
                                  <b>Report {yearValue}</b>
                                </div>
                              </ListGroup.Item>
                              {barData?.labels.map((item, index) => {
                                return (
                                  <ListGroup.Item
                                    as="li"
                                    key={index}
                                    className="d-flex justify-content-between align-items-start"
                                  >
                                    <div className="ms-2 me-auto">{item}</div>
                                    <Badge bg="primary" pill>
                                      {barData?.data[index]}
                                    </Badge>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-12 col-12">
                        {userDetails.account_type === SUPER_ADMIN && (
                          <Form.Select
                            className="mb-4"
                            onChange={(e) => {
                              setSelectedSubAdminId(null);
                              setSelectedCompanyId(e.target.value);
                              if (e.target.value === "") {
                                setRalaAdminList(initialRalaAdminList);
                              } else {
                                const recruiterIds = companyList
                                  .filter((item) => item.id === e.target.value)
                                  .map((item) => item.assigned_rala_sub_admin);
                                const filteredRecruiters =
                                  initialRalaAdminList.filter((item) =>
                                    recruiterIds.includes(item.id)
                                  );
                                setRalaAdminList(filteredRecruiters);
                              }
                            }}
                            aria-label="Company"
                          >
                            <option value="" key="0">
                              Company
                            </option>
                            {companyList &&
                              companyList.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.company_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        )}
                        {userDetails.account_type === SUPER_ADMIN && (
                          <Form.Select
                            className="mb-4"
                            onChange={(e) => {
                              setSelectedSubAdminId(e.target.value);
                            }}
                            value={selectedSubAdminId ?? ""}
                            aria-label="Rala Sub Admins"
                          >
                            <option value="" key="0">
                              Rala Sub Admins
                            </option>
                            {ralaAdminList &&
                              ralaAdminList.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.first_name + " " + item.last_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        )}
                        <Calendar
                          onClickDecade={(e) => onChangeMonth(e)}
                          onClickYear={(e) => onChangeMonth(e)}
                          onClickMonth={(e) => onChangeMonth(e)}
                          className="mb-4 customCalenderClass"
                          view="year"
                          value={calenderMonthDate}
                        />
                        <Calendar
                          onClickDecade={(e) => onChangeYear(e)}
                          onClickYear={(e) => onChangeYear(e)}
                          onClickMonth={(e) => onChangeYear(e)}
                          className="customCalenderClass"
                          view="decade"
                          value={calenderYearDate}
                        />
                        {/* <Form.Select className="mb-4" aria-label="Default select example" value={monthValue} onChange={(e) => onChangeMonth(e.target.value)}>
                                                                {
                                                                    monthList.map((item, index) => {
                                                                        return <option value={index} key={index}>{item}</option>
                                                                    })
                                                                }
                                                            </Form.Select>
                                                            <Form.Select className="mb-4" aria-label="Default select example" value={yearValue} onChange={(e) => onChangeYear(e.target.value)}>
                                                                {
                                                                    yearList.map((item, index) => {
                                                                        return <option value={item} key={index}>{item}</option>
                                                                    })
                                                                }
                                                            </Form.Select> */}
                      </div>
                  </div>
                  </Card.Body>
                </Card>
                
              
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default StateScreen;
