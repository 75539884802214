// Local
// export const API_URL = 'http://localhost:3301/';

import moment from "moment";
import { SUB_ADMIN, SUPER_ADMIN } from "./roles";

// production
export const API_URL = 'http://18.236.155.96:3301/';

// job status
export const JOB_STATUS_OPEN = 'Open';
export const JOB_STATUS_ON_HOLD = 'On Hold';
export const JOB_STATUS_CLOSED = 'Closed';

// this is interviewer status it will be update by employee to accept and reject interviewer
export const PROPOSED_INTERVIEWER_STATUS_PENDING = "";
export const PROPOSED_INTERVIEWER_STATUS_APPROVE = "APPROVE";
export const PROPOSED_INTERVIEWER_STATUS_REJECT = "REJECT";

// interview schedule status its schedule or now
export const SCHEDULER_STATUS_PENDING = 'SCHEDULER_STATUS_PENDING';
export const SCHEDULER_STATUS_DONE = 'SCHEDULER_STATUS_DONE';
export const SCHEDULER_STATUS_CANCELLED = 'SCHEDULER_STATUS_CANCELLED';

// candidate is selected or not
export const INTERVIEW_FINAL_STATUS_SELECTED = 'INTERVIEW_FINAL_STATUS_SELECTED';
export const INTERVIEW_FINAL_STATUS_REJECTED = 'INTERVIEW_FINAL_STATUS_REJECTED';

// qc status from superadmin or ralaadmin
export const QC_STATUS_PASS = 'QC_STATUS_PASS';
export const QC_STATUS_FAIL = 'QC_STATUS_FAIL';

// INCOMPLETE_OPTIONS
export const PROXY = "PROXY";
export const BANDWIDTH_ISSUE_CANDIDATE_END = "BANDWIDTH_ISSUE_CANDIDATE_END";
export const BANDWIDTH_ISSUE_INTERVIEWER_END = "BANDWIDTH_ISSUE_INTERVIEWER_END";
export const HARDWARE_ISSUE_CANDIDATE_END = "HARDWARE_ISSUE_CANDIDATE_END";
export const HARDWARE_ISSUE_INTERVIEWER_END = "HARDWARE_ISSUE_INTERVIEWER_END";
export const NO_SHOW_BY_INTERVIWER = "NO_SHOW_BY_INTERVIWER";
export const NO_SHOW_BY_CANDIDATE = "NO_SHOW_BY_CANDIDATE";


// user status
export const USER_STATUS_PENDING = 'PENDING';
export const USER_STATUS_APPROVE = 'APPROVE';
export const USER_STATUS_REJECT = 'REJECT';
export const USER_STATUS_ON_HOLD = 'ON_HOLD';

export const getUserStatus = (status) => {
    return status.replaceAll("_", " ").replace(/\w+/g,
      function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
}

// pagination
export const ITEMS_PER_PAGE = 20;


export const getInterviewFinalStatus = (status) => {
    return status === INTERVIEW_FINAL_STATUS_SELECTED ? "Selected" : ((status === INTERVIEW_FINAL_STATUS_REJECTED) ? 'Rejected' : "")
}

export const getQCStatuslabel = (status) => {
    return status === "" ? "Pending" : (status === QC_STATUS_PASS ? "Qc Pass" : "Qc Fail");
}

export const getincompleteInterview	= (status) => {
    return status.replaceAll("_", " ").replace(/(\w)(\w*)/g,
    function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();})
}

export const getSchedulerStatusLabel = (status) => {
    return status === SCHEDULER_STATUS_PENDING ? "Pending" : (status === SCHEDULER_STATUS_DONE ? 'Scheduled': 'Cancelled');
}

export const pascalCase = (words) => {
    return words.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}

export const updateStatusLabel = (status) => {
    return status === "APPROVE" ? "Approved" : (status === "REJECT" ? "Rejected" :  (status === "ON_HOLD" ? "On Hold" : "Pending") );
}

export const commonDateFormat = (date, format = "") => {
    if (format === "") {
        return moment(date).format("DD/MM/YYYY h:mm A");
    } else {
        return moment(date).format(format);
    }
}

export const addAuthUserToSessionStorage = (user) => {
    sessionStorage.setItem(`user`, JSON.stringify(user));
    sessionStorage.setItem(`userToken`, user.token);
}

export const removeAuthUserFromSessionStorage = () => {
    sessionStorage.clear();
}

export const getAuthUserFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
}

export const dayDifference = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata');
    const differenceInDays = currentIstDate.diff(givenIstDate, 'days');
    return differenceInDays;
}

export const isToday = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata').startOf('day');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata').startOf('day');
    return currentIstDate.isSame(givenIstDate, 'day');
}

export const isFutureDate = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata').startOf('day');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata').startOf('day');
    const differenceInDays = givenIstDate.diff(currentIstDate, 'days');
    return differenceInDays > 0;
}

export const isDateGreaterThanToday = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata');
    return givenIstDate.isAfter(currentIstDate);
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRoleLabel = (role) => {
    return role === SUPER_ADMIN ? "Rala Super Admin" : role === SUB_ADMIN ? "Rala Sub Admin" : role.replaceAll("_", " ").replace(/\w+/g,
      function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
}

export const proposedInterviewerStatuslabel = {
    "APPROVE": "Approved",
    "REJECT": "Rejected",
    "": "Pending"
}


export const allowedResumeTypes = [
    'application/pdf', 
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const resumeFileTypeErrorMessage = "Please upload only pdf, doc, docx file";

export const allowedImageTypes = [
    'image/jpeg', 
    'image/png', 
    'image/gif', 
    'image/bmp', 
    'image/webp',
    'image/svg+xml',
    'image/tiff'
];

export const imageFileTypeErrorMessage = "The selected file type is not allowed. Please select an image file.";