import React, { Fragment, useState, useEffect, useRef } from "react";
import _ from 'lodash';
import Loader from "react-js-loader";
import "./InterviewerDashboardScreen.css";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import { HiChartPie } from "react-icons/hi";
import { AiFillSchedule, AiFillFile } from "react-icons/ai";
import { BiSolidEditAlt, BiUserCheck, BiSolidVideos } from "react-icons/bi";
import { MdEditOff } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import { RiVideoAddFill } from "react-icons/ri";
import { FaCalendarAlt, FaFileAlt } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { Card, Button, NavDropdown, Table, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import moment from 'moment';
import { API_URL, QC_STATUS_FAIL, QC_STATUS_PASS, SCHEDULER_STATUS_CANCELLED, SCHEDULER_STATUS_DONE, SCHEDULER_STATUS_PENDING, commonDateFormat, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, getincompleteInterview, dayDifference, isDateGreaterThanToday, isToday, isFutureDate } from "../../common/constant";
import { Toast } from 'primereact/toast';

const InterviewerDashboardScreen = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState("Scheduled Interviews");

    const [interviewDashboardData, setInterviewDashboardData] = useState([]);
    const [scheduledInterviews, setScheduledInterviews] = useState([])
    const [conductedInterviews, setConductedInterviews] = useState([])
    const [feedbackPendingInterviews, setFeedbackPendingInterviews] = useState([])
    const [feedbackCompletedInterviews, setFeedbackCompletedInterviews] = useState([])
    const [upcomingSchedulesInterviews, setUpcomingSchedulesInterviews] = useState([])
    

    const [showRejectionModal, setShowRejectionModal] = useState(false);

    const toast = useRef(null);
    const [interviewList, setInterviewList] = useState([]);
    // 
    const [isFetching, setIsFetching] = useState(false);
    const [selectedInterview, setSelectedInterview] = useState("");
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());

    const [showJobJD, setShowJobJD] = useState(false);
    const [previewFilePath, setPreviewFilePath] = useState("");

    const openJDModal = (jobDetails) => {
        setShowJobJD(true);
    }

    useEffect(() => {
        console.log("isFetching", isFetching)
        if (!_.isUndefined(interviewList) && !_.isNull(interviewList) && interviewList.length === 0 && !isFetching) {
            getinterviewList();
        }
    }, [interviewList]);

    useEffect(() => {
        if (selectedOptions == 'Scheduled Interviews') {
            setInterviewDashboardData(scheduledInterviews);
        } else if (selectedOptions == 'Conducted Interviews') {
            setInterviewDashboardData(conductedInterviews);
        } else if (selectedOptions == 'Feedback Pending') {
            setInterviewDashboardData(feedbackPendingInterviews);
        } else if (selectedOptions == 'Feednback completed') {
            setInterviewDashboardData(feedbackCompletedInterviews);
        } else if (selectedOptions == 'Upcoming Schedules') {
            setInterviewDashboardData(upcomingSchedulesInterviews);
        }
    }, [selectedOptions]);

    const getinterviewList = () => {
        setIsLoading(true);
        fetch(`${API_URL}api/interviews/${userDetails.id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setInterviewList(response.data);

                    const scheduledData = response.data.filter(item => (item.scheduler_status === SCHEDULER_STATUS_DONE && item.code_by_candidate == "" && !item.is_feedback_given) && isToday(item.scheduled_on))
                    setScheduledInterviews(scheduledData);

                    const conductedData = response.data.filter(item => (item.scheduler_status === SCHEDULER_STATUS_DONE && item.code_by_candidate != "" && !item.is_feedback_given))
                    setConductedInterviews(conductedData)

                    const feedbackPendingData = response.data.filter(item => (item.scheduler_status === SCHEDULER_STATUS_DONE && item.code_by_candidate != "" && !item.is_feedback_given))
                    setFeedbackPendingInterviews(feedbackPendingData)

                    const feedbackcompletedData = response.data.filter(item => (item.scheduler_status === SCHEDULER_STATUS_DONE && item.is_feedback_given && isToday(item.scheduled_on)))
                    setFeedbackCompletedInterviews(feedbackcompletedData)

                    const upcomingSchedulesInterviewsData = response.data.filter(item => (item.scheduler_status === SCHEDULER_STATUS_DONE && item.code_by_candidate == "" && !item.is_feedback_given) && isFutureDate(item.scheduled_on) > 0)
                    setUpcomingSchedulesInterviews(upcomingSchedulesInterviewsData);

                    setInterviewDashboardData(scheduledData);
                }
                setIsLoading(false);
                setIsFetching(true);
            })
            .catch((err) => {
                setInterviewList([]);
                setIsLoading(false);
                setIsFetching(true);
            });
    }

    const getStartDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(0, 0, 0, 0);
        return originalDate;
    }

    const getEndDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(23, 59, 59, 999);
        return originalDate;
    }

    const updateCandidateProfile = (body) => {
        setIsLoading(true);
        fetch(`${API_URL}api/updateCandidateProfileData/${selectedInterview}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Interview cancelled successully', detail: response.message, life: 3000 });
                setShowRejectionModal(false);
                setSelectedInterview("");
                setIsLoading(false);
                getinterviewList();
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const navigateToLiveInterviewPage = (event, id) => {
        event.stopPropagation();
        navigate("/live-interviewer/" + id)
    };

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                showJobJD && previewFilePath &&
                <Modal centered show={showJobJD} onHide={() => { setShowJobJD(false) }} animation={false} size="xl">
                    <Modal.Body>
                        <div className="row mt-4">
                            <object data={previewFilePath} style={{
                                width: '100%',
                                height: '80vh'
                            }}></object>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-12 dashboard-title pageTitle_fixed">
                        <HiChartPie size={24} className="header-icon" />DASHBOARD
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 dashboard-left-menu-col interview-dashboard-left-side prim-menu">
                        <Card onClick={() => setSelectedOptions("Scheduled Interviews")} className={selectedOptions === "Scheduled Interviews" ? "dashboard-left-card-active" : "dashboard-left-card"}>
                            <Card.Body>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Scheduled Interviews</Tooltip>}
                                >
                                <div className="row cursor-pointer">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                                        <div className="dashboard-left">
                                            <AiFillSchedule size={24} className="dashboard-left-icon" />
                                        </div>
                                    </div>
                                    <div className="col-8 dashboard-right d-none d-md-none d-lg-none d-xl-block">
                                        <p className="dashboard-left-menu-title">Scheduled Interviews</p>
                                        <h3 className="dashboard-left-menu-count mb-0">
                                            {scheduledInterviews && scheduledInterviews.length}
                                        </h3>
                                    </div>
                                </div>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                        <Card onClick={() => setSelectedOptions("Feednback completed")} className={selectedOptions === "Feednback completed" ? "dashboard-left-card-active" : "dashboard-left-card"}>
                            <Card.Body>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Feedback Completed</Tooltip>}
                                >
                                    <div className="row cursor-pointer">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                                            <div className="dashboard-left">
                                                <BiSolidEditAlt size={24} className="dashboard-left-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 dashboard-right d-none d-md-none d-lg-none d-xl-block">
                                            <p className="dashboard-left-menu-title">Feedback Completed</p>
                                            <h3 className="dashboard-left-menu-count mb-0">
                                                {feedbackCompletedInterviews && feedbackCompletedInterviews.length}
                                            </h3>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                        <Card onClick={() => setSelectedOptions("Conducted Interviews")} className={selectedOptions === "Conducted Interviews" ? "dashboard-left-card-active" : "dashboard-left-card"}>
                            <Card.Body>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Conducted Interviews</Tooltip>}
                                >
                                    <div className="row cursor-pointer">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                                            <div className="dashboard-left">
                                                <BiUserCheck size={24} className="dashboard-left-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 dashboard-right d-none d-md-none d-lg-none d-xl-block">
                                            <p className="dashboard-left-menu-title">Conducted Interviews</p>
                                            <h3 className="dashboard-left-menu-count mb-0">
                                                {conductedInterviews && conductedInterviews.length}
                                            </h3>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                        <Card onClick={() => setSelectedOptions("Feedback Pending")} className={selectedOptions === "Feedback Pending" ? "dashboard-left-card-active" : "dashboard-left-card"}>
                            <Card.Body>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Feedback Pending</Tooltip>}
                                >
                                    <div className="row cursor-pointer">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                                            <div className="dashboard-left">
                                                <MdEditOff size={24} className="dashboard-left-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 dashboard-right d-none d-md-none d-lg-none d-xl-block">
                                            <p className="dashboard-left-menu-title">Feedback Pending</p>
                                            <h3 className="dashboard-left-menu-count mb-0">
                                                {feedbackPendingInterviews && feedbackPendingInterviews.length}
                                            </h3>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                        <Card onClick={() => setSelectedOptions("Upcoming Schedules")} className={selectedOptions === "Upcoming Schedules" ? "dashboard-left-card-active" : "dashboard-left-card"}>
                            <Card.Body>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Upcoming Schedules</Tooltip>}
                                >
                                    <div className="row cursor-pointer">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                                            <div className="dashboard-left">
                                                <BiSolidEditAlt size={24} className="dashboard-left-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 dashboard-right d-none d-md-none d-lg-none d-xl-block">
                                            <p className="dashboard-left-menu-title">Upcoming Schedules</p>
                                            <h3 className="dashboard-left-menu-count mb-0">
                                                {upcomingSchedulesInterviews && upcomingSchedulesInterviews.length}
                                            </h3>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                        
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Manage Calendar</Tooltip>}
                        >
                            <NavLink to={"/calendar"}>
                                <Button className="dashboard-left-button">
                                    <FaCalendarAlt size={18} className="dashboard-left-calendar" /><span>Manage Calendar</span>
                                </Button>
                            </NavLink>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>History</Tooltip>}
                        >
                            <NavLink to={"/history"}>
                                <Button variant="outline-primary" className="dashboard-left-button">History</Button>
                            </NavLink>
                        </OverlayTrigger>
                    </div>
                    <div className="col-12 prim-menu-padding">
                        {
                            !_.isUndefined(interviewDashboardData) && !_.isNull(interviewDashboardData) && interviewDashboardData.length == 0 && selectedOptions != "Feednback completed" && (<Card className="employer-dashboard-interview" >
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-center" style={{ height: '90vh' }}>
                                        <div className="text-center fs-6">
                                            <p style={{ textTransform: 'uppercase' }}>NO {selectedOptions}</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>)
                        }
                        {
                            (selectedOptions === "Feednback completed") ?
                                <Card>
                                    <Card.Body>
                                        <Table bordered responsive hover className="dashboard-feednback-completed table table-striped">
                                            <thead className='sticky-top'>
                                                <tr>
                                                    <th>Candidate ID</th>
                                                    <th>Candidate Name</th>
                                                    <th>Job Title</th>
                                                    <th>Company Name</th>
                                                    <th>Coordinator Name</th>
                                                    <th>Coordinator Number</th>
                                                    <th>Scheduled Date</th>
                                                    <th>Interview Status</th>
                                                    <th>Incomplete Interview</th>
                                                    <th>QC status</th>
                                                    <th>Video</th>
                                                    <th>Feedback Sheet</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !_.isUndefined(interviewDashboardData) && !_.isNull(interviewDashboardData) &&
                                                    _.map(interviewDashboardData, (interview, index) => {
                                                        return <tr key={"feednback-completed-" + index}>
                                                            <td>{interview._id}</td>
                                                            <td className="fw-semibold">{interview.candidate_name}</td>
                                                            <td>{interview.job_title}</td>
                                                            <td>{interview.company_name}</td>
                                                            <td>{interview.coordinator ? (interview.coordinator.first_name + " " + interview.coordinator.last_name) : ""}</td>
                                                            <td>{interview.coordinator ? interview.coordinator.mobile_number : ""}</td>
                                                            <td>{commonDateFormat(interview.scheduled_on)}</td>
                                                            <td><span className={interview.final_status}>{getInterviewFinalStatus(interview.final_status)}</span></td>
                                                            <td>{getincompleteInterview(interview.incomplete_interview)}</td>
                                                            <td className="text-center">
                                                                <span style={{color: interview.qc_status === QC_STATUS_FAIL ? 'red' : (
                                                                    interview.qc_status === QC_STATUS_PASS ? 'green' : ''
                                                                ) }} className={interview.qc_status}>
                                                                    {getQCStatuslabel(interview.qc_status)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    interview.video && (<BiSolidVideos size={24} onClick={() => window.open(interview.video, "_blank")} />)
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    interview.is_feedback_given && (
                                                                        <FaFileAlt onClick={() => {
                                                                            if (interview.feedback_sheet != "") {
                                                                                window.open(interview.feedback_sheet, '_blank');
                                                                            } else {
                                                                                window.open(`/finalInterviewReport/${interview._id}`, '_blank')
                                                                            }
                                                                        }} />
                                                                    )
                                                                }
                                                            </td>
                                                            <td><NavLink className="btn btn-outline-primary btn-sm" to={"/live-interviewer/" + interview._id}>Edit</NavLink></td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                :
                                <Fragment>
                                    {
                                        !_.isUndefined(interviewDashboardData) && !_.isNull(interviewDashboardData) && interviewDashboardData.length > 0 && selectedOptions != "Feednback completed" && (<Card className="dashboard-interview-card card-body-scroll">
                                            <Card.Body className="">
                                                {
                                                    !_.isUndefined(interviewDashboardData) && !_.isNull(interviewDashboardData) &&
                                                    _.map(interviewDashboardData, (interview, index) => {
                                                        return <Fragment key={"live-interview-fragment-" + index}>
                                                            {
                                                               
                                                                     <div className="cursor-pointer" onClick={(e) => navigateToLiveInterviewPage(e, interview._id)} key={"live-interviewer-" + index + "-" + index}>
                                                                        <div className="dashboard-interview">
                                                                            <Card.Body>
                                                                                <div className="row">
                                                                                    <div className="col-md-10 col-12">
                                                                                        <NavLink>
                                                                                            <p className="dashboard-interview-name">{interview.candidate_name}</p>
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    {
                                                                                        (selectedOptions === "Scheduled Interviews") ?
                                                                                            <div className="col-md-2 col-12 text-end">
                                                                                                <Button variant="success" className="dashboard-interview-join" onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    window.open(`${interview.google_meet_id}`, "_blank")
                                                                                                }}>
                                                                                                    <RiVideoAddFill size={20} className="dashboard-interview-join-video" /><span>Join</span>
                                                                                                </Button>
                                                                                                <NavDropdown  className="dashboard-interview-action" title={<SlOptionsVertical size={20} className="dashboard-interview-action-icon" />}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                }} >
                                                                                                    <NavDropdown.Item onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        window.open(`${interview.google_meet_id}`, "_blank")
                                                                                                    }}>Join</NavDropdown.Item>
                                                                                                    <NavDropdown.Item onClick={() => { setShowRejectionModal(true); setSelectedInterview(interview._id); }}>Cancel</NavDropdown.Item>
                                                                                                </NavDropdown>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-md-2 col-12 text-end">
                                                                                                <Button className="dashboard-interview-join">
                                                                                                    Give feedback
                                                                                                </Button>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="row">
                                                                                    <NavLink className="no-underline">
                                                                                        <div className="col-12 no-underline">
                                                                                            <p className="dashboard-interview-skill">{interview.job_title}</p>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-12 col-lg-4 col-12">
                                                                                        <NavLink className="no-underline">
                                                                                            <p className="dashboard-interview-company">
                                                                                                {interview.company_name}
                                                                                            </p>
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-8 col-12">
                                                                                        <div className="row">
                                                                                            <div className="col-md-4 col-lg-6 col-12 text-end mb-2 mb-lg-0">
                                                                                                <Button variant="light" onClick={(event) => {
                                                                                                    event.stopPropagation();
                                                                                                    window.location.href = 'tel:' + interview.coordinator.mobile_number;
                                                                                                }} className="dashboard-interview-contact">
                                                                                                    <IoCall size={12} className="dashboard-interview-call me-2" /><span>Contact Co-ordinator</span>
                                                                                                </Button>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-lg-3 col-12 text-center my-auto">
                                                                                                <NavLink className="no-underline">
                                                                                                    <p className="dashboard-interview-time">ON <b>{moment(interview.scheduled_on).format('h:mm A')}</b></p>
                                                                                                </NavLink>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-lg-3 col-12 text-center my-auto">
                                                                                                <NavLink className="no-underline">
                                                                                                    <p className="dashboard-interview-today">
                                                                                                        {
                                                                                                            moment(interview.scheduled_on).format("MM/DD/YYYY")
                                                                                                        }
                                                                                                    </p>
                                                                                                </NavLink>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </div>
                                                                
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                            </Card.Body>
                                        </Card>)
                                    }
                                    {
                                        showRejectionModal &&
                                        <Modal show={showRejectionModal} onHide={() => { setShowRejectionModal(false); setSelectedInterview(""); }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Reason for cancellation?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Form.Select aria-label="Choose" id="cancellationReason" name="cancellationReason" placeholder="Choose">
                                                            <option>Choose</option>
                                                            <option value="not available to take interview">Not available to take interview</option>
                                                            <option value="JD mis-match">JD mis-match</option>
                                                            <option value="Interviewer skills does not match the JD">Interviewer skills does not match the JD</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => { setShowRejectionModal(false); setSelectedInterview(""); }}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={() => {
                                                    updateCandidateProfile({
                                                        'interviewer_id': "",
                                                        "scheduler_status": SCHEDULER_STATUS_CANCELLED,

                                                        // "incomplete_interview": "",
                                                        // "overall_rating": 0,
                                                        // "good_to_have_skill_feedback_rating": 0,
                                                        // "coding_skill_feedback_rating": "",
                                                        // "final_status": "",
                                                        // "video": "",
                                                        // "follow_up_attempts": [],
                                                        // "google_meet_id": "",
                                                        // "calender_event_id": "",
                                                        // "primary_skill_feedback": [],
                                                        // "secondary_skill_feedback": [],
                                                        // "good_to_have_skill_feedback": "",
                                                        // "coding_skill_feedback": "",
                                                        // "final_recommendation": "",
                                                        // "is_feedback_given": false,
                                                        // "is_sent_to_employeer": false,

                                                        "scheduler_id": "",
                                                        "scheduled_on": ""
                                                    })
                                                }}>
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    }
                                </Fragment>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default InterviewerDashboardScreen;