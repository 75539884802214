import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiTwotoneSetting } from "react-icons/ai";
import { BiSolidReport } from "react-icons/bi";
import { CiMoneyBill } from "react-icons/ci";
import { HiChartPie } from "react-icons/hi";
import { IoArrowBack } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import default_profile_picture from "../../Assets/default_profile_picture.png";
import rala_portal_logo from "../../Assets/rala_infotech_color.svg";
import "./HeaderScreen.css";

import { getAuthUserFromSessionStorage, getRoleLabel, removeAuthUserFromSessionStorage } from "../../common/constant";
import {
    EMPLOYER_SUB_ADMIN,
    EMPLOYER_SUPER_ADMIN,
    INTERVIEWER,
    SUB_ADMIN,
    SUPER_ADMIN,
} from './../../common/roles';

const HeaderScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const [navLinks, setNavLinks] = useState([]);
    const [profileImage, setProfileImage] = useState([]);
    useEffect(() => {
        if (_.isUndefined(userDetails) || _.isNull(userDetails) || _.isEmpty(userDetails))
        {
            removeAuthUserFromSessionStorage()
            navigate("/sign-in", { replace: true });
        }

        if (userDetails.account_type === SUPER_ADMIN) {
            setNavLinks(
                [
                    { path: "/dashboard", clickable: true, text: "Dashboard", icon: <HiChartPie size={25} className="header-icon" /> },
                    { path: "/report", clickable: true, text: "REPORTS", icon: <BiSolidReport size={25} className="header-icon" /> },
                    { path: "/super-admin-settings", clickable: true, text: "SETTINGS", icon: <AiTwotoneSetting size={25} className="header-icon" /> },
                    { path: "/payment", clickable: true, text: "PAYMENT", icon: <CiMoneyBill size={20} className="header-icon" /> },
                ]
            );
        }
        if (userDetails.account_type === SUB_ADMIN) {
            setNavLinks(
                [
                    { path: "/dashboard", clickable: true, text: "Dashboard", icon: <HiChartPie size={25} className="header-icon" /> },
                    { path: "/report", clickable: true, text: "REPORTS", icon: <BiSolidReport size={25} className="header-icon" /> }
                ]
            );
        }

        if (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) {
            setNavLinks(
                [
                    { path: "/dashboard", clickable: true, text: "Dashboard", icon: <HiChartPie size={25} className="header-icon" /> },
                    { path: "/report", clickable: true, text: "REPORTS", icon: <BiSolidReport size={25} className="header-icon" /> }
                ]
            );
        }

        if (userDetails.account_type === INTERVIEWER) {
            setNavLinks(
                [
                    { path: "/interviewer-dashboard", clickable: true, text: "Dashboard", icon: <HiChartPie size={25} className="header-icon" /> },
                    { path: "/live-interviewer", clickable: false, text: "INTERVIEWS", icon: <BiSolidReport size={25} className="header-icon" /> }
                ]
            );
        }

        setProfileImage(getProfileImage());

        const handleProfileImageUpdate = (event) => {
            setProfileImage((!_.isUndefined(event.detail.profileImage) && event.detail.profileImage != "") ? event.detail.profileImage : getProfileImage());

            if (!_.isUndefined(event.detail.user) && event.detail.user) {
                setUserDetails(event.detail.user)
            }
        };

        window.addEventListener('profileImageUpdated', handleProfileImageUpdate);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('profileImageUpdated', handleProfileImageUpdate);
        };
        
     }, [userDetails]);

    const isActive = (path) => {
        if (path == '/super-admin-settings' && location.pathname.includes('profile-page')) {
            return true;
        }
        if (path == '/report' && location.pathname.includes('report')) {
            return true;
        }
        return location.pathname === path;
    }

    const handleLogout = () => {
        removeAuthUserFromSessionStorage();
        navigate('/sign-in');
    };

    const getProfileImage = () => {
        if (!_.isUndefined(userDetails) && !_.isNull(userDetails) && !_.isUndefined(userDetails.profile_image) && userDetails.profile_image != "") {
            return userDetails.profile_image;
        }
        return default_profile_picture;
    }

    const goToEditProfilePage = () => {
        if (!_.isUndefined(userDetails) && !_.isNull(userDetails)) {
            if (userDetails.account_type === INTERVIEWER) {
                navigate('/interviewer-profile-page/' + userDetails.id);
            } else if (userDetails.company_id == "" && userDetails.account_type === EMPLOYER_SUPER_ADMIN) {
                navigate('/company-profile-page/' + userDetails.id);
            } else if (userDetails.company_id != "" && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN)) {
                navigate('/employee-profile-page/' + userDetails.id);
            } else {
                navigate('/admin-profile-page/' + userDetails.id);
            }
        }
    }

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const goToHomePage = () => {
        if (userDetails.account_type === INTERVIEWER) {
            navigate("/interviewer-dashboard")
        } else {
            navigate("/dashboard")
        }
    }

    return (
        <Navbar fixed="top" expand="xxl" className="bg-body-tertiary justify-content-between">
            <div>
            {
                !location.pathname.includes("dashboard") && (<span onClick={handleBackClick} className="cursor-pointer"><IoArrowBack size={24}/> Back </span>)
            }
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Navbar.Brand href={!_.isUndefined(userDetails) && !_.isNull(userDetails) && !_.isUndefined(userDetails.account_type) && !_.isNull(userDetails.account_type) && (userDetails.account_type === 'Interviewer') ? "/dashboard" : (!_.isUndefined(userDetails) && !_.isNull(userDetails) && !_.isUndefined(userDetails.account_type) && !_.isNull(userDetails.account_type) && (userDetails.account_type === 'Employer Super Admin') ? "/employerDashboard" : (!_.isUndefined(userDetails) && !_.isNull(userDetails) && !_.isUndefined(userDetails.account_type) && !_.isNull(userDetails.account_type) && (userDetails.account_type === 'Super Admin') ? "/superAdminDashboard" : ""))}>
                {/* Rala Infotech India Pvt. Ltd. */}
                <img onClick={goToHomePage} src={rala_portal_logo} alt="Rala Portal" className="logo-img cursor-pointer" />


            </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse key="basic-navbar-nav" id="basic-navbar-nav" className="">
                <Nav className="me-auto mt-3 mt-md-3 mt-lg-0">
                    {navLinks.map((link, index) => (
                        link.clickable ? (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{link.text}</Tooltip>}
                            >
                                <Link key={index} to={link.path} onClick={() => {
                                    if (location.pathname.includes("report") && location.pathname.includes("job")) {
                                        setTimeout(() => {
                                            navigate('/dashboard');
                                            setTimeout(() => {
                                                navigate('/report');
                                            });
                                        }, 1000);
                                    }
                                }}  className={
                                    (
                                        (
                                            location.pathname === '/state' || 
                                            location.pathname.includes('/scheduled-profile') ||
                                            location.pathname.includes('/create-job') ||
                                            location.pathname.includes('/edit-job') ||
                                            location.pathname.includes('/interviewer-dashboard') ||
                                            location.pathname.includes('/proposed-interviewers') ||
                                            location.pathname.includes('/requestInterviewerUsers')
                                        ) && link.path === '/dashboard') ? "active-navbar interviews-nav" : 
                                        isActive(link.path) ? "active-navbar interviews-nav" : "interviews-nav"
                                    }>
                                    {link.icon} {link.text}
                                </Link>
                            </OverlayTrigger>
                        ) : (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{link.text}</Tooltip>}
                            >
                                <Link key={index} className={
                                    (
                                        (
                                            location.pathname.includes('/live-interviewer')
                                        ) && link.path === '/live-interviewer') ? "interviews-nav active-navbar" : 
                                        isActive(link.path) ? "active-navbar interviews-nav" : "interviews-nav"
                                    }>
                                    {link.icon} {link.text}
                                </Link>
                            </OverlayTrigger>
                        )                        
                    ))}
                </Nav>
                
            </Navbar.Collapse>
            <div className='profile'>
            <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{userDetails.first_name + " " + userDetails.last_name}</Tooltip>}
                >
                    <Navbar.Text className="ml-2 cursor-pointer d-none d-lg-block" style={{
                        fontWeight: "600",
                        fontSize: "13px"
                    }}>
                        {userDetails ? (getRoleLabel(userDetails.account_type) + " : " + userDetails.first_name + " " + userDetails.last_name) : ""} &nbsp;&nbsp;
                    </Navbar.Text>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Edit Profile</Tooltip>}
                >
                    <NavDropdown align="end" title={<img src={profileImage} className="userProfileImage cursor-pointer" alt="Profile"/>} drop="down-centered" id="pofile-dropdown">
                        <NavDropdown.Item onClick={goToEditProfilePage}>Edit Profile</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
                    </NavDropdown>
                </OverlayTrigger>
            </div>
        </Navbar>
        
    )
}
export default HeaderScreen;