import React, { useState, useEffect, Fragment, useRef } from "react";
import _ from 'lodash';
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import faster_hiring from "../../Assets/faster_hiring.png";
import rala_portal_logo from "../../Assets/rala_infotech_color.svg";
import { Form, Button, Alert } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import "./NewPasswordScreen.css";
import { API_URL } from "../../common/constant";

const NewPasswordScreen = () => {
  const navigate = useNavigate();
  const { id: id } = useParams();
  const [formErrors, setFormErrors] = useState({});

  const [passwordError, setIsPasswordError] = useState("");
  const [confirmPasswordError, setIsConfirmPasswordError] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    password: "",
    confirmPassword: "",
  });

  const toast = useRef(null);
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };
  const validateForm = () => {
    if (_.isUndefined(user.password) || _.isNull(user.password) || (user.password === '') || (user.password.trim() === '')) {
        setIsPasswordError("Password is required!");
    }
    else if (!_.isUndefined(user.password) && !_.isNull(user.password) && (user.password !== '') && (user.password.trim() !== '') && ((user.password.trim().length < 6) || (user.password.trim().length > 25))) {
        setIsPasswordError("Password must be between 6 to 25 characters!");
    }
    else {
        setIsPasswordError("");
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
      setIsPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character.');
        return false;
    }

    if (!lowercasePattern.test(user.password)) {
       setIsPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character.');
        return false;
    }

    if (!numericPattern.test(user.password)) {
      setIsPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character.');
        return false;
    }

    if (!specialCharPattern.test(user.password)) {
      setIsPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character.');
        return false;
    }

    if (_.isUndefined(user.confirmPassword) || _.isNull(user.confirmPassword) || (user.confirmPassword === '') || (user.confirmPassword.trim() === '')) {
        setIsConfirmPasswordError("Confirm Password is required!");
    }
    else if (!_.isUndefined(user.confirmPassword) && !_.isNull(user.confirmPassword) && (user.confirmPassword !== '') && (user.confirmPassword.trim() !== '') && ((user.confirmPassword.trim().length < 6) || (user.confirmPassword.trim().length > 25))) {
        setIsConfirmPasswordError("Confirm Password must be between 6 to 25 characters!");
    }
    else if (user.password !== user.confirmPassword) {
        setIsConfirmPasswordError("Confirm Password does not match with Password!");
    }
    else {
        setIsConfirmPasswordError("");
    }
  };

  const checkedValidateForm = () => {
    if (_.isUndefined(user.password) || _.isNull(user.password) || (user.password === '') || (user.password.trim() === '')) {
      return false;
    }
    else if (!_.isUndefined(user.password) && !_.isNull(user.password) && (user.password !== '') && (user.password.trim() !== '') && ((user.password.trim().length < 6) || (user.password.trim().length > 25))) {
        return false;
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
        return false;
    }

    if (!lowercasePattern.test(user.password)) {
        return false;
    }

    if (!numericPattern.test(user.password)) {
        return false;
    }

    if (!specialCharPattern.test(user.password)) {
        return false;
    }

    if (_.isUndefined(user.confirmPassword) || _.isNull(user.confirmPassword) || (user.confirmPassword === '') || (user.confirmPassword.trim() === '')) {
        return false;
    }
    else if (!_.isUndefined(user.confirmPassword) && !_.isNull(user.confirmPassword) && (user.confirmPassword !== '') && (user.confirmPassword.trim() !== '') && ((user.confirmPassword.trim().length < 6) || (user.confirmPassword.trim().length > 25))) {
        return false;
    }
    else if (user.password !== user.confirmPassword) {
        return false;
    }
    return true;
  }

  const loginHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(user);
    }
  }, [formErrors]);

  const resetPassword = (e) => {
    validateForm();
    if (checkedValidateForm()) {
      setIsSubmit(true);
      let params = {
        password: user.password,
        id: id
      }
      fetch(`${API_URL}api/resetPassword`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status == 200) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Your password is successfully changed Login with your new password", life: 3000 });
            setTimeout(() => {
              navigate('/sign-in')
            }, 3000)
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
          }
          setIsSubmit(false);
        })
        .catch((error) => {
          if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
          }
          setIsSubmit(false);
        });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="container-fluid">
        {
          isSubmit &&
          <div className="spinner-loader">
            <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
          </div>
        }
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center">
            <img src={faster_hiring} alt="Faster Hiring" className="w-75" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 m-auto">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <img src={rala_portal_logo} alt="Rala Portal" className="w-75" />
                <Form className="mt-4" autoComplete="off">
                  <Form.Group className="mb-3">
                    <Form.Control type="password" id="password" name="password" placeholder="New Password" onChange={changeHandler} maxLength={25} autoComplete="off" />
                    {
                      !_.isUndefined(passwordError) && !_.isNull(passwordError) && (passwordError !== '') &&
                      <Alert variant="danger" className="mt-2">{passwordError}</Alert>
                    }
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="password" id="confirmPassword" name="confirmPassword" placeholder="Re-enter Password" onChange={changeHandler} maxLength={25} autoComplete="off" />
                    {
                      !_.isUndefined(confirmPasswordError) && !_.isNull(confirmPasswordError) && (confirmPasswordError !== '') &&
                      <Alert variant="danger" className="mt-2">{confirmPasswordError}</Alert>
                    }
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Button onClick={resetPassword} className="sign-up-form-submit-button">Save</Button>
                  </Form.Group>
                </Form>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default NewPasswordScreen;